import React, { Component } from 'react'
import { Message, Grid, Icon, Button, Form, Select, Image, Step, Checkbox, Label, Loader, Dropdown, Header, Modal, Dimmer } from 'semantic-ui-react';

export default class UsersModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      users: [],
      isFetching: false,
      multiple: false,
      search: true,
      searchQuery: null,
      value: [],
      options: []
    }
  }

  componentWillReceiveProps(nextProps) {
    const { openModal, users } = nextProps
    this.setState({
      openModal,
      users,
      options: users
    })
  }

  closeModal = () => {
    this.setState({
      openModal: false
    })
    this.props.closeModalHandler()
  }

  addChatHead = () => {
    this.props.addNewChat(this.state.value)
  }

  handleChange = (e, { value }) => this.setState({ value })
  handleSearchChange = (e, { searchQuery }) => this.setState({ searchQuery })


  render() {
    const { openModal, users, multiple, options, isFetching, search, value } = this.state
    return (
      <div className="adduserchat">
        <Modal open={openModal} onClose={this.closeModal}
          centered={false}
          size="tiny"
          className="addChatHead"
        >
          <Modal.Header>Add chat</Modal.Header>
          <Modal.Content>
            <div>
              <Dropdown
                fluid
                selection
                multiple={multiple}
                search={search}
                options={users}
                value={value}
                placeholder='Add Users'
                onChange={this.handleChange}
                onSearchChange={this.handleSearchChange}
                disabled={isFetching}
                loading={isFetching}
              />
              <Button onClick={()=>this.addChatHead()} disabled={!value.length} className="primary-btn">
                Add
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}
