import * as CONSTANT from "../constant/course";
import Axios from "axios";
import baseUrl from "../config";
const CancelToken = Axios.CancelToken;
let cancel;


export const receiveNotifications = (payload) => ({ type: CONSTANT.RECEIVE_NOTIFICATIONS, payload });
export const recieveInstructors = payload => ({ type: CONSTANT.FETCH_INSTRUCTORS, payload });
export const recieveMyStudents = payload => ({ type: CONSTANT.FETCH_MY_STUDENTS, payload });

export const requestInstructor = () => ({ type: CONSTANT.GETINSTRUCTOR_REQUEST });
export const instructorSuccess = (payload) => ({ type: CONSTANT.GETINSTRUCTOR_SUCCESS, payload });
export const instructorError = () => ({ type: CONSTANT.GETINSTRUCTOR_ERROR });
export const clearInstructor = () => ({ type: CONSTANT.CLEAR_GETINSTRUCTOR });

export const requestSendContact = () => ({ type: CONSTANT.SENDCONTACT_REQUEST });
export const sendContactSuccess = (payload) => ({ type: CONSTANT.SENDCONTACT_SUCCESS, payload });
export const sendContactError = (payload) => ({ type: CONSTANT.SENDCONTACT_ERROR, payload });
export const clearSendContact = () => ({ type: CONSTANT.CLEAR_SENDCONTACT });

export const requestGetUsers = () => ({ type: CONSTANT.GETUSERS_REQUEST });
export const getUersSuccess = (payload) => ({ type: CONSTANT.GETUSERS_SUCCESS, payload });
export const getUsersError = (payload) => ({ type: CONSTANT.GETUSERS_ERROR, payload });
export const clearGetUsers = () => ({ type: CONSTANT.CLEAR_GETUSERS });

export const requestGetChats = () => ({ type: CONSTANT.GETCHATS_REQUEST });
export const getChatsSuccess = (payload) => ({ type: CONSTANT.GETCHATS_SUCCESS, payload });
export const getChatsError = (payload) => ({ type: CONSTANT.GETCHATS_ERROR, payload });
export const clearGetChats = () => ({ type: CONSTANT.CLEAR_GETCHATS });
export const getChatsUpadte = (payload) => ({ type: CONSTANT.GETCHATS_UPDATE, payload });


export const requestUploadFile = () => ({ type: CONSTANT.UPLOAD_FILE_REQUEST });
export const uploadFileSuccess = () => ({ type: CONSTANT.UPLOAD_FILE_SUCCESS });
export const uploadFileError = (payload) => ({ type: CONSTANT.UPLOAD_FILE_ERROR, payload });
export const uploadFileClear = () => ({ type: CONSTANT.CLEAR_FILE_UPLOAD });
export const updateUploadedFiles = (payload) => ({
  type: CONSTANT.UPDATE_UPLOADED_FILES,
  payload
})

export const setAppSettings = (payload) => ({ type: CONSTANT.UPDATE_APPSETTINGS, payload });


export const getUploadedFiles = () => dispatch => {
  Axios.get(baseUrl + '/users/getfiles')
    .then((response) => {
      var { files } = response.data;
      dispatch(updateUploadedFiles(files))
    }).catch((err) => {
      console.log(err)
    })
}

export const uploadFile = (data) => dispatch => {
  dispatch(requestUploadFile());
  let formData = new FormData()
  formData.append('file', data)
  Axios.post(baseUrl + '/users/uploadfile', formData, {
    // specify a cancel token
    cancelToken: new CancelToken(c => {
      // this function will receive a cancel function as a parameter
      cancel = c;
    })
  })
    .then((response) => {
      // console.log(response.data.video.videoUrl)
      dispatch(uploadFileSuccess())
      setTimeout(() => {
        dispatch(uploadFileClear())
      }, 10)
    }).catch((thrown) => {
      if (Axios.isCancel(thrown)) {
        console.log(thrown.message);
      } else {
        dispatch(uploadFileError('Something went wrong'));
      }
    })
}

export const cancelFileUpload = () => dispatch => {
  console.log('reach here!')
  cancel('Request canceled.');
}

export const clearUploadFile = () => dispatch => {
  dispatch(uploadFileClear());
}

export const turnOffStatus = () => dispatch => {
  Axios.post(baseUrl + '/users/gooffline').then((response) => {
    // 
  }).catch((err) => {
    console.log(err)
  })
}

export const fetchUsers = () => dispatch => {
  dispatch(requestGetUsers())
  Axios.get(baseUrl + '/users/getusers')
    .then((response) => {
      var { users } = response.data;
      dispatch(getUersSuccess(users))
      dispatch(clearGetUsers())
    }).catch((err) => {
      dispatch(getUsersError('Somethins went wrong'))
      dispatch(clearGetUsers())
    })
};

export const addChat = (reciever) => dispatch => {
  // console.log(reciever)
  dispatch(requestGetChats())
  Axios.post(baseUrl + '/users/createchat', {
    reciever
  }).then((response) => {
    var { chats } = response.data;
    dispatch(getChatsUpadte(chats))
    console.log(chats)
  }).catch((err) => {
    console.log(err)
    dispatch(getChatsError('Somethins went wrong'))
    dispatch(clearGetChats())
  })
}

export const getChats = () => dispatch => {
  dispatch(requestGetChats())
  Axios.get(baseUrl + '/users/getchats')
    .then((response) => {
      var { chats } = response.data;
      dispatch(getChatsSuccess(chats))
      dispatch(clearGetChats())
    }).catch((err) => {
      console.log(err)
      dispatch(getChatsError('Somethins went wrong'))
    })
}

// export const updateChat = (chats) => dispatch => {
//   console.log(chats)
//   dispatch(getChatsUpadte(chats))
// }

export const sendMessage = (details) => dispatch => {
  Axios.post(baseUrl + '/users/sendmessage', details).then((response) => {
    var { chats } = response.data;
    dispatch(getChatsSuccess(chats))
  }).catch((err) => {
    console.log(err)
  })
}


// --- FETCH COURSES
export const fetchNotifications = () => dispatch => {
  Axios.get(baseUrl + '/courses/getNotifications')
    .then((response) => {
      var { notifications } = response.data;
      dispatch(receiveNotifications(notifications))
    }).catch((err) => {
      console.log(err)
    })
};
// --- FETCH INSTRUCTORS
export const fetchInstructors = ({limit, pageNum}) => dispatch => {
  Axios.get(baseUrl + '/users/getInstructors?limit='+limit+"&pageNum="+pageNum)
    .then((response) => {
      var { users, totalResults } = response.data;
      dispatch(recieveInstructors({users, totalResults}))
    }).catch((err) => {
      console.log(err)
    })
};
// --- FETCH MY STUDENTS
export const fetchMyStudents = () => dispatch => {
  Axios.get(baseUrl + '/users/getMyStudents')
    .then((response) => {
      var { students } = response.data;
      dispatch(recieveMyStudents(students))
    }).catch((err) => {
      console.log(err)
    })
};
export const getInstructor = (slug) => dispatch => {
  dispatch(requestInstructor())
  Axios.get(baseUrl + '/users/getInstructor', {
    params: {
      slug
    }
  })
    .then((response) => {
      var { instructor, courses } = response.data;
      if (instructor === null) {
        window.location.assign('/')
        dispatch(clearInstructor())
      } else {
        var lessons = 0
        courses.map((course) => {
          lessons += course.lessons
        })
        instructor.lessons = lessons
        instructor.courses = courses
        dispatch(instructorSuccess(instructor))

      }
    }).catch((err) => {
      dispatch(instructorError())
      dispatch(clearInstructor())
    })
}

export const sendContact = (info) => dispatch => {
  dispatch(requestSendContact())
  Axios.post(baseUrl + '/users/sendcontact', info)
    .then((response) => {
      console.log(response)
      dispatch(sendContactSuccess(response.data.message))
      dispatch(clearSendContact())
    }).catch((err) => {
      console.log(err)
      dispatch(sendContactError('Something went wrong'))
      dispatch(clearSendContact())
    })
};

export const getAppSettings = () => dispatch => {
  Axios.get(baseUrl + '/appinfo/getappinfo').then((res) => {
    dispatch(setAppSettings(res.data.data))
  }).catch((err) => {
    console.log(err)
  }) 
}