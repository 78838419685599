import React, { Component, useEffect, useState } from 'react'
import { Container, Grid, Icon, Form, Select, Label, Input, Button, Dropdown, Header, Message, Search } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import Spinner from "../../components/ui/spinner";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar";
import socketIOClient from "socket.io-client";
import { fetchUsers, getChats, addChat, sendMessage, getChatsSuccess, turnOffStatus } from '../../utils/actions/user'
import UsersModal from './users'
import ChatHead from './chathead'
import ChatBody from './chatbody'
import baseUrl from '../../utils/config'
import FileModal from '../../components/messages/filemodal'

// const socket = socketIOClient(baseUrl.split('/api')[0]);

class Messages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      users: [],
      currentChat: null,
      openModal: false,
      chats: [],
      messageSentSuccess: false,
      sendError: false,
      activeChatId: '',
      search: false,
      searchText: '',
      searchResult: [],
      openModalFile: false
    }
  }

  componentWillMount() {
    if (this.props.auth.user.uid) {
      this.props.fetchUsers()
      this.props.reloadChats()
    } else {
      this.props.history.push('/')
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isLoadingUsers, getUsersSuccess, getUsersError, users, isLoadingChats, getChatsSuccess, getChatsError,
      // chats
    } = nextProps.users
    const { user } = nextProps.auth
    const { chats, currentChat, messageSentSuccess } = nextProps
    this.setState({
      loading: isLoadingUsers && isLoadingChats
    })
    if (currentChat) {
      this.setState({
        currentChat
      })
    }
    if (messageSentSuccess) {
      this.setState({
        messageSentSuccess
      })
    }
    if (!this.state.loading) {
      if (users && users.length) {
        let chatLead = []
        chats.map((chat) => {
          chat.members.map((member) => {
            if (member.userId._id !== user.uid) {
              chatLead.push(member.userId._id)
            }
          })
        })
        this.setState({
          users: users.filter((item) => item.uid !== user.uid && !chatLead.includes(item.uid))
        }, () => {
        })
      }
    }
    if (chats && chats.length) {
      let sorted = chats.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
      this.setState({
        chats: sorted
      }, () => {
        this.setState({
          currentChat: sorted.length > 0 ? sorted[0] : {},
          activeChatId: sorted.length > 0 ? sorted[0]._id : ''
        }, () => {
          let fromDashboard
          fromDashboard = this.props.history.location.state
          if (fromDashboard !== undefined) {
            this.setState({
              currentChat: fromDashboard.chat,
              activeChatId: fromDashboard.chat._id
            })
          }
        })
      })
    }
  }

  componentDidMount() {
    // const { uid } = this.props.auth.user
    // this.props.socket.on(uid, ({ chats }) => {
    //   console.log('lets wait for message')
    //   if (this.state.currentChat !== null) {
    //     chats.map((chat) => {
    //       if (chat._id === this.state.currentChat._id) {
    //         this.setState({
    //           currentChat: chat,
    //           chats,
    //           messageSentSuccess: true
    //         })
    //       }
    //     })
    //   } else {
    //     this.setState({
    //       chats
    //     })
    //   }
    // })
    // this.props.socket.emit('setstatus', { uid })
  }

  componentWillUnmount() {
    // this.props.socket.emit('disconnect')
    // this.props.socket.off()
    this.props.turnOffStatus()
  }

  recieveMessageDetails = (details) => {
    this.setState({
      sendError: false
    })
    if (this.props.socket.connected) {
      this.props.socket.emit('sendmessage', details, ({ chats }) => {
        if (this.state.currentChat !== null) {
          chats.map((chat) => {
            if (chat._id === this.state.currentChat._id) {
              this.setState({
                currentChat: chat,
                chats,
                messageSentSuccess: true
              })
            }
          })
        } else {
          this.setState({
            chats
          })
        }
      })
    } else {
      this.setState({
        sendError: true
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  resetMessageSent = () => {
    this.setState({
      messageSentSuccess: false
    })
  }

  clearSearch = () => {
    this.setState({
      searchResult: [],
      search: false,
      searchText: ''
    })
  }

  typing = (e) => {
    const { chats } = this.state
    const { uid } = this.props.auth.user
    var name = e.target.name;
    this.setState({
      [name]: e.target.value
    }, () => {
      if (name === 'searchText') {
        this.setState({
          search: true,
          searchResult: []
        })
        if (chats.length > 0 && this.state.searchText.trim().length > 0) {
          let holder = []
          chats.map((chat) => {
            chat.members.map((member) => {
              if (member.userId._id !== uid) {
                holder.push({
                  name: `${member.userId.forename} ${member.userId.surname}`,
                  chatObj: chat
                })
              }
            })
          })
          let results = holder.filter((item) => (item.name.toLowerCase().includes(this.state.searchText.trim().toLowerCase())))
          this.setState({
            searchResult: results
          })
        } else {
          this.setState({
            search: false,
            searchResult: []
          })
        }
      }
    })
  }

  closeModalHandler = () => {
    this.setState({
      openModal: false
    })
  }

  closeModalHandlerFile = () => {
    this.setState({
      openModalFile: false
    })
  }

  controlOpenModal = () => {
    this.setState({
      openModal: true
    })
  }

  addNewChat = (id) => {
    this.closeModalHandler()
    this.props.addChat(id)
  }

  selectChat = (currentChat) => {
    this.setState({
      currentChat,
      activeChatId: currentChat._id
    })
  }

  dismissError = () => {
    this.setState({
      sendError: false
    })
  }

  toggleFav = (chatmemberId, favourite) => {
    const { currentChat } = this.state
    let details = {
      chatId: currentChat._id,
      chatmemberId: chatmemberId,
      favourite: !favourite
    }
    this.props.socket.emit('favchat', details, ({ chat }) => {
      this.setState({
        currentChat: chat
      })
    })
  }

  openFileModal = () => {
    this.setState({
      openModalFile: true
    })
  }

  selectFile = (fileDetails) => {
    const { uid } = this.props.auth.user
    const { currentChat } = this.state
    this.setState({
      openModalFile: false
    })
    let recieverId
    currentChat.members.map((member) => {
      if (member.userId._id !== uid) {
        recieverId = member.userId._id
      }
    })
    var details = {
      messageType: fileDetails.fileType,
      body: fileDetails.name,
      creatorId: uid,
      dateCreated: Date.now(),
      url: fileDetails.url,
      chatId: currentChat._id,
      recieverId
    }
    if (this.props.socket.connected) {
      this.props.socket.emit('sendfile', details, ({ chats }) => {
        if (this.state.currentChat !== null) {
          chats.map((chat) => {
            if (chat._id === this.state.currentChat._id) {
              this.setState({
                currentChat: chat,
                chats,
                messageSentSuccess: true
              })
            }
          })
        } else {
          this.setState({
            chats
          })
        }
      })
    } else {
      this.setState({
        sendError: true
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  render() {
    const { history, auth } = this.props
    const { loading, currentChat, chats, openModal, sendError, activeChatId, searchText, search, searchResult, openModalFile } = this.state
    return (
      loading ? <Spinner /> :
        <div className="y-messages">
          <Navbar history={history}
            auth={auth} />
          <div className="y-main">
            <div className="topmain-holder">
              <h1 className="y-top-title">Messages</h1>
              <div className="ml-bottom">
                <Button fluid onClick={() => this.controlOpenModal()}>
                  Create New Messages
                </Button>
              </div>
            </div>
            {/* <h1 className="y-top-title">Messages</h1> */}
            <div className="messages-box">
              <Grid>
                <Grid.Row>
                  <Grid.Column computer="6" mobile="16">
                    <div className="y-message-list">
                      <div className="ml-top">
                        <Grid>
                          <Grid.Row verticalAlign="middle">
                            <Grid.Column computer="8" mobile="16">
                              <h3 className="ml-top-head">Messages
                              {/* <Label>
                                5
                              </Label> */}
                              </h3>
                            </Grid.Column>
                            <Grid.Column computer="8" mobile="16">
                              <Input
                                icon={search ? <Icon
                                  onClick={() => this.clearSearch()}
                                  name="times"></Icon> : <Icon name="search"></Icon>}
                                placeholder='Search user..'
                                className="search-input"
                                onChange={this.typing}
                                value={searchText}
                                name="searchText"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                      <div className="ml-body">
                        {
                          search ?
                            searchResult.length > 0 ?
                              searchResult.map((item) => (
                                <div onClick={() => this.selectChat(item.chatObj)} className={`y-message-card ${item.chatObj._id === activeChatId ? 'active' : ''}`} key={item.chatObj._id}>
                                  <ChatHead chatObj={item.chatObj} currentId={this.props.auth.user.uid}

                                  />
                                </div>
                              ))
                              :
                              <div className="nochathead">
                                <p>No result found.</p>
                              </div>
                            : !chats.length ?
                              <div className="nochathead">
                                <p>You don't have any message click on the botton below</p>
                              </div>
                              :
                              chats.map((item) => (
                                <div onClick={() => this.selectChat(item)} className={`y-message-card ${item._id === activeChatId ? 'active' : ''}`} key={item._id}>
                                  <ChatHead chatObj={item} currentId={this.props.auth.user.uid}

                                  />
                                </div>
                              ))
                        }
                      </div>
                      {/* <div className="ml-bottom">
                        <Button fluid onClick={() => this.controlOpenModal()}>
                          Create New Messages
                      </Button>
                      </div> */}
                    </div>
                  </Grid.Column>
                  <Grid.Column computer="10" mobile="16">
                    {
                      sendError ?
                        <Message negative
                          className="y-feedback"
                          onDismiss={this.dismissError}>
                          <p>
                            Error in sending message
                              </p>
                        </Message>
                        : null
                    }
                    {
                      !currentChat &&
                      <div className="nochat">
                        <p>Click on a chat by the right to start a conversarion</p>
                      </div>
                    }
                    {
                      currentChat &&
                      <ChatBody currentId={this.props.auth.user.uid} currentChat={currentChat} recieveMessageDetails={this.recieveMessageDetails}
                        messageSentSuccess={this.state.messageSentSuccess}
                        resetMessageSent={this.resetMessageSent}
                        toggleFav={this.toggleFav}
                        openFileModal={this.openFileModal}
                      />
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
          <Footer
            auth={auth} />
          <UsersModal openModal={openModal}
            closeModalHandler={this.closeModalHandler}
            setNewChatHead={this.setNewChatHead}
            users={this.state.users}
            addNewChat={this.addNewChat}
          />
          <FileModal openModal={openModalFile}
            closeModalHandler={this.closeModalHandlerFile}
            selectFile={this.selectFile} />
        </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth.auth,
  users: state.users
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchUsers,
  getChats,
  addChat,
  sendMessage,
  turnOffStatus
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messages);