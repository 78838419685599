import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import { Message, Grid, Icon, Button, Form, Select, Image, Popup } from 'semantic-ui-react';
import Spinner from "../../components/ui/spinner";
import moment from 'moment'
import { updateUser, updateDp, updatePassword } from "../../utils/actions/auth"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { domain } from '../../utils/config'

export class Settings extends Component {
  constructor(props) {
    super(props)
    this.uploadRef = React.createRef()
    this.state = {
      genders: [
        { key: 'm', value: 'male', text: 'Male' },
        { key: 'fm', value: 'female', text: 'Female' },
      ],
      forename: '',
      surname: '',
      email: '',
      phone: '',
      dob: '',
      gender: '',
      loading: false,
      error: null,
      photoURL: '',
      success: null,
      instagramLink: '',
      twitterLink: '',
      shortBio: '',
      description: '',
      instructor: false,
      copied: false,
      activeTab: 'account',
      password: '',
      cpassword: '',
    }
  }

  componentWillMount() {
    const { forename, surname, email, phone, dob, gender, photoURL, instagramLink, twitterLink, shortBio, description, instructor, username } = this.props.auth.auth.user
    this.setState({
      forename,
      surname,
      email,
      phone,
      dob,
      gender,
      photoURL,
      instagramLink,
      twitterLink,
      shortBio,
      description,
      instructor,
      username,
    })
  }

  componentWillReceiveProps(nextProps) {
    const { auth } = nextProps
    const { isUpdatingUser, updateSuccess, updateError, updateImageSuccess } = auth
    this.setState({
      loading: isUpdatingUser
    })
    if (updateSuccess) {
      this.setState({
        success: 'User profile update was successfull'
      })
      window.location.reload();
    }
    if (updateError) {
      this.setState({
        error: updateError
      })
    }
    if (updateImageSuccess) {
      this.setState({
        photoURL: updateImageSuccess
      })
    }
  }

  typing = (e) => {
    var name = e.target.name;
    this.setState({
      [name]: e.target.value
    })
  }

  handleChange = (e, { value }) => {
    this.setState({ gender: value })
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      error: null,
      success: null
    })

    switch (this.state.activeTab) {
      case 'account':
          this.updateAccount()
        break;
        case 'password':
          this.updatePassword()
        break;
      default:
        break;
    }
 
  }
  updateAccount = () => {
    const { user } = this.props.auth.auth
    const { forename, surname, email, phone, dob, gender, photoURL, description, shortBio, instagramLink, twitterLink, instructor } = this.state

      let info = {
        uid: user.uid,
        forename: forename || user.forename,
        surname: surname || user.surname,
        email: email || user.email,
        phone: phone || user.phone,
        dob: dob || user.dob,
        gender: gender || user.gender,
        photoURL: photoURL || user.photoURL,
        description: description || user.description,
        shortBio: shortBio || user.shortBio,
        instagramLink: instagramLink || user.instagramLink,
        twitterLink: twitterLink || user.twitterLink
      }
      this.props.updateUser(info)
  }
  updatePassword = () => {
    const { user } = this.props.auth.auth
    const { cpassword, password} = this.state
     
    if(cpassword !== password) return this.setState({error: "password do not match"})
    this.props.updatePassword({cpassword, password})
  }
  triggerUpload = () => {
    this.uploadRef.current.click()
  }

  onChangeHandler = (e) => {
    this.props.updateDp(e.target.files[0])
  }

  dismissError = () => {
    this.setState({
      error: null
    })
  }

  dismissSuccess = () => {
    this.setState({
      success: null
    })
  }
  setActive = (e, activeTab) => {
    e.preventDefault();
    this.setState({activeTab, error: "", success: ""})
  }

  render() {
    const { loading, genders, forename, surname, email, phone, dob, gender, photoURL, error, success, description, shortBio, instagramLink, twitterLink, password,cpassword, activeTab } = this.state
    const { history, auth } = this.props

    return (
      <div className="x-settings">
        <Navbar history={history}
          auth={auth.auth} />
        <div className="x-main">
          <h1 className="x-top-title">Settings</h1>
            <Grid>
            <Grid.Column computer="5" tablet="5" mobile="16">
              <div className="x-card">
              <div className="img-holder" style={{ backgroundImage: `url(${photoURL})` }}>
                  </div>
                <div className="x-content">
                <h3>{forename} {surname}</h3>
                <p>{shortBio}</p>

                <div className="x-details">
                 <p> <small>YOUR DETAILS</small></p>
                  <a href="" className={activeTab == "account" && "active"} onClick={(e)=>this.setActive(e,'account')}>Your account</a>
                  <a href=""className={activeTab == "password" && "active"}  onClick={(e)=>this.setActive(e, 'password')}>Password</a>
                </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column computer="11" tablet="11" mobile="16">
              <div className="x-right-card">
                <h3>Basic profile</h3>
                <p>This information can be edited from your profile page.</p>
              <Grid>
              <Grid.Row textAlign="center">
                <Grid.Column computer="16">
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {
              error ? <Message negative className="x-feedback"
                onDismiss={this.dismissError}
              >
                <p>{error}</p>
              </Message> : null
            }
            {
              success ? <Message positive className="x-feedback"
                onDismiss={this.dismissSuccess}
              >
                <p>{success}</p>
              </Message> : null
            }
            <Form className="x-form-main" onSubmit={this.onSubmit} loading={loading}>
              {activeTab == "account" && <Grid>
                <Grid.Row>
                  <Grid.Column computer="8" mobile="16">
                    <div className="x-input-holder">
                      <p>First Name</p>
                      <Form.Input fluid type="text"
                        id="forename" required name="forename" onChange={this.typing}
                        value={forename}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column computer="8" mobile="16">
                    <div className="x-input-holder">
                      <p>Last Name</p>
                      <Form.Input fluid type="text"
                        id="surname" required name="surname" onChange={this.typing}
                        value={surname}
                      />
                    </div>
                  </Grid.Column>
                {/* </Grid.Row> */}
                {/* <Grid.Row> */}
                  <Grid.Column computer="8" mobile="16">
                    <div className="x-input-holder">
                      <p>Your Email</p>
                      <Form.Input fluid type="text"
                        id="email" required name="email" onChange={this.typing}
                        value={email}
                        disabled
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column computer="8" mobile="16">
                    <div className="x-input-holder">
                      <p>Phone Number</p>
                      <Form.Input fluid type="text"
                        id="phone" required name="phone" onChange={this.typing}
                        value={phone}
                      />
                    </div>
                  </Grid.Column>
                {/* </Grid.Row> */}
            
                      <Grid.Column computer="8" mobile="16">
                        <div className="x-input-holder">
                          <p>Twitter handle</p>
                          <Form.Input fluid type="text"
                            placeholder="e.g __masterclass"
                            id="twitterLink" name="twitterLink" onChange={this.typing}
                            value={twitterLink}
                          />
                        </div>
                      </Grid.Column>
                      <Grid.Column computer="8" mobile="16">
                        <div className="x-input-holder">
                          <p>Instagram handle</p>
                          <Form.Input fluid type="text" placeholder="e.g __masterclass"
                            id="twitterLink" name="instagramLink" onChange={this.typing}
                            value={instagramLink}
                          />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                   

              </Grid>}
              {activeTab == "password" && <Grid>
                <Grid.Row>
                  <Grid.Column computer="8" mobile="16">
                    <div className="x-input-holder">
                      <p>New password</p>
                      <Form.Input fluid type="password"
                        id="npassword" required name="password" onChange={this.typing}
                        value={password}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column computer="8" mobile="16">
                    <div className="x-input-holder">
                      <p>Confirm password</p>
                      <Form.Input fluid type="password"
                        id="cpassword" required name="cpassword" onChange={this.typing}
                        value={cpassword}
                      />
                    </div>
                  </Grid.Column>
                    </Grid.Row>
              </Grid>}
              <div className="btn-holder">
                <Button compact type="submit">Save settings</Button>
              </div>
            </Form>
              </div>
            </Grid.Column>
            </Grid>
            
        </div>
        <Footer
          auth={auth} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  updateUser, updateDp, updatePassword
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Settings)
