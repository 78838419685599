import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Message, Grid, Icon, Button, Form, Select, Image, Step, Checkbox, Label, Loader, Dropdown, Header, Modal } from 'semantic-ui-react';
import { bindActionCreators } from "redux"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import AddFaq from '../../components/upload/faq'
import { uploadCourseImage, uploadCourseVideo, clearUploadImage, clearUploadVideo, getUploadedVideos, addCourse, addCurriculum, clearaddCurriculum, clearaddedCourse } from "../../utils/actions/course";
import CourseForm from '../../components/upload/courseform'
import CourseCurriculum from '../../components/upload/coursecurriculum'

export class UplaodCourse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      success:  null,
      courseId: '',
      steps: [
        {
          key: 'shipping',
          active: true,
          icon: 'th',
          title: 'Course Details',
          description: '',
          disabled: false
        },
        {
          key: 'billing',
          active: false,
          icon: 'cloud upload',
          title: 'Upload Lectures',
          description: '',
          disabled: true
        },
      ]
    }
  }

  componentWillReceiveProps(nextProps)  {
    const { course } = nextProps
    const { addedCourseSuccess, addedCourseId, addedCurriculumSuccess } = course
    if(addedCourseSuccess) {
      var { steps } = this.state 
      steps[0].active = false
      steps[0].disabled = true
      steps[1].active = true
      steps[1].disabled = false
      this.setState({
        courseId: addedCourseId,
        steps
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    if(addedCurriculumSuccess) {
      var { steps } = this.state 
      this.props.clearaddCurriculum()
      this.props.clearaddedCourse()
      var { steps } = this.state 
      steps[0].active = true
      steps[0].disabled = false
      steps[1].active = false
      steps[1].disabled = true
      this.setState({
        courseId: '',
        steps,
        success: 'Course uploaed successfully'
      }, () => {
        setInterval(() => {
          this.dismissSuccess()
        }, 5000);
      })
    }
  }

  dismissSuccess = () => {
    this.setState({
      success: null
    })
  }

  render() {
    const { history, auth } = this.props
    const { steps, courseId, success } = this.state
    return (
      <div className="y-upload">
        <Navbar history={history}
          auth={auth.auth} />
        <div className="y-main">
          <h1 className="y-top-title">Upload Course</h1>
          <div className="upload-holder">
            <div className="y-step-holder">
              <Step.Group size='mini'>
                {
                  steps.map((step) => (
                    <Step key={step.key} active={step.active} onClick={() => console.log(step.key)} disabled={step.disabled}>
                      <Icon name={step.icon} />
                      <Step.Content>
                        <Step.Title>
                          {step.title}
                        </Step.Title>
                        {/* <Step.Description>Choose your shipping options</Step.Description> */}
                      </Step.Content>
                    </Step>
                  ))
                }
              </Step.Group>
            </div>
            {
              success ? <Message positive className="y-feedback"
                onDismiss={this.dismissSuccess}
              >
                <p>{success}</p>
              </Message> : null
            }
            {
              steps[0].active ? <CourseForm {...this.props} /> : null
            }
            {
              steps[1].active ? <CourseCurriculum courseId={courseId} {...this.props} />
                : null
            }
          </div>
        </div>
        <Footer
         auth={auth}  />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  course: state.course
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  uploadCourseImage, uploadCourseVideo,
  clearUploadVideo, clearUploadImage, getUploadedVideos, addCourse, addCurriculum, clearaddCurriculum, clearaddedCourse
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(UplaodCourse)
