import React, { useState, useEffect } from "react";
import { Tab, Button, Image, Popup } from "semantic-ui-react";
import { useSelector } from "react-redux";
import moment from "moment";
// import $ from "jquery"
import { ZoomMtg } from "@zoomus/websdk";
import $ from "jquery";
// For Global use source.zoom.us:


const Join = ({meetingNumber, meetingPassword, creatorId, live}) => {
  const auth = useSelector(s => s.auth.auth);
  const [join, setJoin] = useState(false);

  useEffect(() => {
  
  },[])

  const init = () => {
    $(".x-zoom-video").append("<div class='x-zoomsdk'> </div>")
    $(".x-zoomsdk").append('<div id="zmmtg-root"></div>');
    ZoomMtg.setZoomJSLib("https://source.zoom.us/1.7.7/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    setJoin(true);
    $("video").css("display", "none");
      ZoomMtg.generateSignature({
        meetingNumber:  meetingNumber || 0,
        apiKey: "yEJgHr-6SGCkKbVceZJ2ow",
        apiSecret: "CEQOvV0SGOiqxbAQXGXux3EBxpH11xukAwCV",
        role: creatorId._id == auth.user.uid ? 1 : 2,
        success(res) {
          ZoomMtg.init({
            leaveUrl: window.location.href,
            isSupportAV: true,
            success: () => {
              ZoomMtg.join({
                signature: res.result,
                meetingNumber: meetingNumber || 0,
                userName: auth.user.forename,
                apiKey: "yEJgHr-6SGCkKbVceZJ2ow",
                userEmail: auth.user.email,
                passWord: meetingPassword || 0,
                success: success => {
                  setJoin(false);
                  var canvas = $("canvas")
                  $(".x-joinzoom").css("display", "none");
                  // $(".x-zoomsdk").append(canvas)
                  
                },
                error: error => {
                  setJoin(false);
                  console.log(error);
                }
              });
            },
            error: error => {
              setJoin(false);
              console.log(error);
            }
          });
        }
      });
  };

  return auth.isAuthenticated ? (
    <Button size="small" loading={join} className="x-joinzoom" disabled={!live}  onClick={init}>
      Join live class
    </Button>
  ) : (
    <Popup
      content="Login to join live stream"
      trigger={
        <Button size="small"  className="x-joinzoom">
          Join live class
        </Button>
      }
    />
  );
};

export default Join;
