import React, { Component } from "react"
import axios from "axios"
import { Grid, Button, Image, Icon, Input, Search, Segment, Select, Responsive, GridRow, GridColumn } from 'semantic-ui-react';
import baseUrl from "../../utils/config";
export default class OnlySearchbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      online: false,
      results: [],
      query: "",
      loadedContent: false,
      type: "thread"
    }
  }
  typing = (e, data) => {
    this.setState({ [data.name]: data.value })
  }
  cuttext(text, maxlength) {
    if (text.length > maxlength) {
      var newtext = text.slice(0, maxlength);
      newtext += "..."
      return newtext
    } else return text
  }
  submit = (e) => {
    e.preventDefault();
    var { query } = this.state;
    query = query.replace(/[" "]/gi, "-")
    if (this.state.type === "people")
      this.props.history.push(`/profile/${query}`)
    else
      this.props.history.push(`/search?query=${query}`)

  }
  select = (e, props) => {
    if (props.result.status) return this.props.history.push(`/profile/${props.result.username}`)
    else return this.props.history.push(`/section/${props.result.section.replace(/[" "]/gi, "-")}/${props.result.slug}`)
  }
  resultRenderer = (props) => {
    if (props.status) return <Grid columns="equal" >
      <Grid.Column width="3" textAlign="center">
        <Image src={props.dpUrl} circular avatar />
      </Grid.Column>
      <Grid.Column>
        <p >
          <b className="xtransform">  {props.username}</b>
          <br />
          <span style={{ color: "grey" }}>{props.status} </span></p>
      </Grid.Column>
    </Grid>
    else
      return <Grid columns="equal" >
        <Grid.Column width="5" className="bg-grey xtransform" style={{ color: "grey", fontWeight: "bolder" }}> <small>{props.section}</small></Grid.Column>
        <Grid.Column>
          <b className="xtransform">{props.title} </b> <br />
          <p style={{ color: "grey" }}>See more..</p>
        </Grid.Column>
      </Grid>

  }

  render() {
    var { results, isLoading } = this.state
    return (
      <div className="y-searchbox">
        <form onSubmit={this.submit} >
          <Input 
            className="inputWrapper"
            action='Search' 
            placeholder='Search...' 
            size="massive"
          />
        </form>
      </div>
    )
  }
}