import React, { Component } from "react"
import { Input, Form, Grid, Label, Button, Checkbox, Radio, Icon, Dimmer, Header } from 'semantic-ui-react'
import { Link } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { signup, requestResetAuth } from "../../utils/actions/auth"
import $ from 'jquery'
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import Slogin from "../../components/ui/sociallogin";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instructor: false,
      forename: "",
      surname: "",
      phone: "",
      dob: new Date(),
      email: '',
      password: '',
      cPassword: "",
      username: '',
      loading: false,
      error: "",
      success: ""
    }
  }
  
  componentWillMount() {
    // $("body").addClass('darkmode')
    // $("body").removeClass('lightmode')
  }

  componentWillReceiveProps(nextProps) {
    var { auth } = nextProps
    var { signupError, isSigningUp, signupSuccess } = auth
    if (signupError) {
      this.setState({ error: signupError, loading: false })
      this.props.requestResetAuth()
    }
    if (isSigningUp) {
      this.setState({ loading: isSigningUp, error: "" })
    }
    if (signupSuccess) {
      this.setState({ success: signupSuccess, loading: false })
      this.props.requestResetAuth()
    
    }
  }

  setup = () =>{
    if(this.state.instructor)  window.location.assign("/getstarted")
    else  window.location.assign("/settings")
  }
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({loading: true})
    if (this.state.password !== this.state.cPassword)
      return this.setState({ error: "Password does not match",loading: false })
    this.props.signup(this.state)
  }

  typing = (e) => {
    var name = e.target.name;
    this.setState({
      [name]: e.target.value
    })
  }
  
  render() {
    var { error, loading, success } = this.state
    return (
      <div className="x-signup">
        <Navbar {...this.props} />
        {success &&
        <Dimmer active page>
            <Header as='h2' icon inverted>
            <Icon name="check circle " color="green" />
                Thank you for registering
            <Header.Subheader><br/> Please click on the button below to setup your profile <br /> 
      <br /><br/>
      <Button  color="grey" inverted onClick={this.setup} style={{background: 'transparent'}}>
        Continue
      </Button></Header.Subheader>
            </Header>
        </Dimmer>}
        <div className="x-main">
          <div className="x-body">
            <div className="x-heading">
              <h2>Create an Account</h2>

              <p className="x-bottom">
                  Already have an Account? <Link to="/signin">Signin </Link>
                </p>
              {error && <div className="x-error">
                {error}
              </div>}
            </div>
            <div className="x-card">
              <Form onSubmit={this.onSubmit} loading={loading}>
                
                <div className="x-type">
                  <Form.Field>
                    <Radio
                      label='Student'
                      name='radioGroup'
                      value='student'
                      checked={this.state.instructor == false}
                      onChange={(e, { value }) => this.setState({ instructor: false })}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label='Teacher'
                      name='radioGroup'
                      value='mentor'
                      checked={this.state.instructor == true}
                      onChange={(e, { value }) => this.setState({ instructor: true })}
                    />
                  </Form.Field>
                </div>
                <div className="xlabel"><p>First Name</p></div>
                <Form.Input placeholder="Waleed" fluid type="text"
                  onChange={this.typing} name="forename" required
                />
                <div className="xlabel"><p>Last Name</p></div>
                <Form.Input placeholder="Shabir" fluid type="text"
                  onChange={this.typing} name="surname" required
                />
                <div className="xlabel"><p>Email address</p></div>
                <Form.Input placeholder="johndoe@gmail.com" fluid type="email"
                  onChange={this.typing} name="email"
                  id="email" required
                />
                <div className="xlabel"><p>Phone Number</p></div>
                <Form.Input placeholder="Waleed" fluid type="text"
                  onChange={this.typing} name="phone" required
                />
                <div className="xlabel"><p>Date of Birth</p></div>
                {/* <Datepicker selected={this.state.dob} onChange={date => this.setState({dob:date})} /> */}
                <Form.Input placeholder="Shabir" fluid type="date"
                  onChange={this.typing} name="dob" required
                />
                <div className="xlabel"><p>Username</p></div>
                <Form.Input placeholder="Username" fluid type="text"
                  id="username" required name="username" onChange={this.typing}
                />
                <div className="xlabel">Create Password </div>
                <Form.Input className="password" fluid type="password" placeholder="•••••••"
                  id="password" required name="password" onChange={this.typing}
                />
                <div className="x-info">
                  <small>Password must be 8-20 characters, including: at least one capital letter, at
                  least one small letter, one number and one special character - ! @ # $ % ^
                  & * ( ) _ +
                                      </small>
                </div>
                <div className="xlabel"> Confirm Password </div>
                <Form.Input className="password" fluid type="password" placeholder="•••••••"
                  id="cpassword" required name="cPassword" onChange={this.typing}
                />
                {/* <Form.Checkbox 
                                    name="tos" onChange={(e,check)=>this.setState({'tos':check.checked})}
                                    id="tos"
                                     label='I agree to the Terms and Conditions'
                                     error={error.tos?{
                                       content: error.tos,
                                       pointing: 'left',
                                     }:null}
                                    /> */}
                <div className="x-tos">
                  By clicking Sign Up, you agree to the Masterclass of
                  Beauty's Terms of Service and Privacy Policy.
                                    </div>
                <Button size="large" fluid color="blue">Sign up</Button>
                <Slogin />
              </Form>

            </div>
          </div>

        </div>
        <Footer {...this.props} />

      </div>
    )
  }
}

const mapStateToProps = ((state) => {
  return {
    auth: state.auth,
  }
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  signup,
  requestResetAuth
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Signup) 