import { combineReducers } from "redux"
import auth from "./auth"
import course from "./course"
import users from "./user"
var allReducer = combineReducers({
    auth,
    course,
    users
})

export default allReducer;