import React, { Component, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  Icon,
  Checkbox,
  Grid,
  GridRow,
  Rating,
  GridColumn,
  Progress,
  Form,
  Input,
  TextArea,
  Image,
  Button
} from "semantic-ui-react";
import classnames from "classnames";
import moment from "moment"
import { rateCourse } from "../../utils/actions/course";

const Reviews = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector(s => s.auth.auth);
  const [rating, setRating] = useState(1)
  const [description, setDescription] = useState("")
  const [loading, setLoading] = useState(false)
  
  const submit = () =>{
    var {_id, creatorId} = props
    setLoading(true)
    dispatch(rateCourse({
      courseId: _id, creatorId: creatorId._id ,
      description, rating
    }))
  }
  const filterReview = (num) => {
   var {reviews} = props
   var total = reviews.filter((r) => r.rating == num)
   return total.length
  }
    return (
      <div className="x-reviews-tab">
        <Accordion>
          <Accordion.Title active={true} icon={null} className="x-title">
            <div>Feedbacks from students</div>
            <Icon size="small" name="chevron down" />
          </Accordion.Title>
          <Accordion.Content className="x-content" active style={{padding:0}}>
            <Grid verticalAlign="middle" textAlign="center">
              <GridColumn mobile="16" computer="3" tablet="3">
                <Rating
                  maxRating={5}
                  defaultRating={props.ratings}
                  disabled
                  icon="star"
                  size="mini"
                />
              </GridColumn>
              <GridColumn mobile="16" computer="13" tablet="13">
                <Grid className="x-rows">
                  <GridRow>
                    <GridColumn mobile="8" computer="9" tablet="9">
                      <div className="x-progress">
                        <Progress active percent={Math.floor((filterReview(5) * 100) / props.reviews.length)} />
                      </div>
                    </GridColumn>
                    <GridColumn mobile="8" computer="5" tablet="5">
                      <div className="x-flex-rating">
                        <Rating
                          maxRating={5}
                          defaultRating={5}
                          disabled
                          icon="star"
                          size="medium"
                        />
                      </div>
                    </GridColumn>
                    <GridColumn mobile="16" computer="2" tablet="2">
                      <small>{filterReview(5)}</small>
                    </GridColumn>
                  </GridRow>
                  <GridRow>
                    <GridColumn mobile="8" computer="9" tablet="9">
                      <div className="x-progress">
                        <Progress active percent={Math.floor((filterReview(4) * 100) / props.reviews.length)} />
                      </div>
                    </GridColumn>
                    <GridColumn mobile="8" computer="5" tablet="5">
                      <div className="x-flex-rating">
                        <Rating
                          maxRating={5}
                          defaultRating={4}
                          icon="star"
                          disabled
                          size="medium"
                        />
                      </div>
                    </GridColumn>
                    <GridColumn mobile="16" computer="2" tablet="2">
                      <small>{filterReview(4)}</small>
                    </GridColumn>
                  </GridRow>
                  <GridRow>
                    <GridColumn mobile="8" computer="9" tablet="9">
                      <div className="x-progress">
                        <Progress active percent={Math.floor((filterReview(3) * 100) / props.reviews.length)} />
                      </div>
                    </GridColumn>
                    <GridColumn mobile="8" computer="5" tablet="5">
                      <div className="x-flex-rating">
                        <Rating
                          maxRating={5}
                          defaultRating={3}
                          icon="star"
                          disabled
                          size="medium"
                        />
                      </div>
                    </GridColumn>
                    <GridColumn mobile="16" computer="2" tablet="2">
                      <small>{filterReview(3)}</small>
                    </GridColumn>
                  </GridRow>
                  <GridRow>
                    <GridColumn mobile="8" computer="9" tablet="9">
                      <div className="x-progress">
                        <Progress active percent={Math.floor((filterReview(2) * 100) / props.reviews.length)} />
                      </div>
                    </GridColumn>
                    <GridColumn mobile="8" computer="5" tablet="5">
                      <div className="x-flex-rating">
                        <Rating
                          maxRating={5}
                          defaultRating={2}
                          icon="star"
                          size="medium"
                          disabled
                        />
                      </div>
                    </GridColumn>
                    <GridColumn mobile="16" computer="2" tablet="2">
                      <small>{filterReview(2)}</small>
                    </GridColumn>
                  </GridRow>
                  <GridRow>
                    <GridColumn mobile="8" computer="9" tablet="9">
                      <div className="x-progress">
                        <Progress active percent={Math.floor((filterReview(1) * 100) / props.reviews.length)} />
                      </div>
                    </GridColumn>
                    <GridColumn mobile="8" computer="5" tablet="5">
                      <div className="x-flex-rating">
                        <Rating
                          maxRating={5}
                          defaultRating={1}
                          icon="star"
                          size="medium"
                          disabled
                        />
                      </div>
                    </GridColumn>
                    <GridColumn mobile="16" computer="2" tablet="2">
                      <small>{filterReview(1)}</small>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </GridColumn>
            </Grid>
            <Grid>
            {auth.isAuthenticated && <p>Rate Course</p>}

            {auth.isAuthenticated && props.isMember && <GridRow>
                <GridColumn mobile="3" tablet="2" computer="2">
                <Image src={auth.user.photoURL} size="small" avatar rounded /> 
                </GridColumn>
                <GridColumn mobile="13"  tablet="14" computer="14">
                  <Form onSubmit={submit}>
                    <TextArea required onChange={(e, data)=> setDescription(data.value)}/>
                    <br />
                    <br />
                    <Rating
                          maxRating={5}
                          defaultRating={rating}
                          icon="star"
                          size="medium"
                          onRate={(e, data)=>setRating(data.rating)}
                        />
                    <br />
                    <br />
                    <Button type="submit" loading={loading} floated="right" content="submit" />
                  </Form>
                </GridColumn>
              </GridRow>}
            </Grid>
          </Accordion.Content>
          <Accordion.Title active={true} icon={null} className="x-title">
            <div>Reviews ({props.reviews.length})</div>
            <Icon size="small" name="chevron down" />
          </Accordion.Title>
          <Accordion.Content className="x-content" active>
            <Grid>
            {props.reviews.map((review) => 
              <GridRow>
                <GridColumn mobile="3" tablet="2" computer="2">
                <Image src={review.creatorId.photoURL} avatar rounded /> 
                </GridColumn>
                <GridColumn mobile="9" tablet="12" computer="12">
                  {review.creatorId.forename}  {review.creatorId.surname} <br />
                  <small style={{color:"#aaa"}}>Student | {moment(review.dateCreated).fromNow()}</small>
                </GridColumn>
                <GridColumn mobile="4" style={{padding: 0}} tablet="2" computer="2">
                <Rating
                    maxRating={5}
                    defaultRating={review.rating}
                    icon="star"
                    size="medium"
                    disabled
                    className="x-small"
                  />
                </GridColumn>
                <GridColumn mobile="3" tablet="2" computer="2">
                </GridColumn>
                <GridColumn mobile="14" tablet="14" computer="14">
                  <p>{review.description}</p>
                </GridColumn>
              </GridRow>
            )}
            </Grid>
          </Accordion.Content>
        </Accordion>
      </div>
    );
}

export default Reviews