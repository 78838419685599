import React, { Component } from "react"
import { Input, Form, Grid, Label, Button, Checkbox, Radio } from 'semantic-ui-react'
import { Link } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import $ from 'jquery'
import { signin, requestResetAuth } from "../../utils/actions/auth"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import Slogin from "../../components/ui/sociallogin";

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instructor: false,
      email: '',
      password: '',
      success: '',
      error: "",
      loading: false
    }
  }
  componentWillMount() {
    // $("body").addClass('lightmode')
    // $("body").removeClass('darkmode')
  }

  componentWillReceiveProps(nextProps) {
    var { auth } = nextProps
    var { loginError, isLoggingIn, loginSuccess } = auth
    if (loginError) {
      this.setState({ error: loginError, loading: false })
      this.props.requestResetAuth()
    }
    if (isLoggingIn) {
      this.setState({ loading: isLoggingIn, error: "" })
    }
    if (loginSuccess) {
      this.setState({ success: loginSuccess, loading: false })
      this.props.requestResetAuth()
      window.location.assign("/courses")
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.signin(this.state)
  }

  typing = (e) => {
    var name = e.target.name;
    this.setState({
      [name]: e.target.value
    })
  }
  render() {
    var { error, loading, instructor} = this.state
    return (
      <div className="x-signin">
        <Navbar {...this.props} />
        <div className="x-main">
          <div className="x-body">
            <div className="x-heading">
              <h2>Login into your account</h2>
            </div>

            <p className="x-bottom">
                  Dont have an account? <Link to="/signup">Create Account</Link>
                </p>
            {error && <div className="x-error">
              {error}
            </div>}
            <div className="x-card">
              <Form onSubmit={this.onSubmit} loading={loading}>
             
                <div className="x-type">
                  <Form.Field>
                    <Radio
                      label='Student'
                      name='radioGroup'
                      value='student'
                      checked={!instructor}
                      onChange={(e, { value }) => this.setState({ instructor: false })}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label='Teacher'
                      name='radioGroup'
                      value='mentor'
                      checked={instructor}
                      // onChange={this.handleChange}
                      onChange={(e, { value }) => this.setState({ instructor: true })}
                    />
                  </Form.Field>
                </div>
                <div className="xlabel"><p>Email address</p></div>
                <Form.Input placeholder="johndoe@gmail.com" fluid type="email"
                  onChange={this.typing} name="email"
                  id="email" required
                />
                <div className="xlabel">Your Password </div>
                <Form.Input className="password" fluid type="password" placeholder="•••••••"
                  id="password" required name="password" onChange={this.typing}
                />

                <div className="x-forgot">
                  <Link to="/forgot-password"> I forgot my password </Link>
                  <Form.Checkbox
                    name="remember"
                    label="Remember password"
                  />
                </div>
                <Button type="submit" size="large" fluid color="blue">Sign in</Button>
                <Slogin />
              </Form>
            </div>
          </div>

        </div>
        <Footer {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = ((state) => {
  return {
    auth: state.auth
  }
})
const matchDispatchToProps = (dispatch) => bindActionCreators({
  signin,
  requestResetAuth
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Signin) 