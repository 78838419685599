import React from 'react';
import ReactDOM from 'react-dom';
import 'react-notifications/lib/notifications.css';
import './style/bundle.scss'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

import allReducer from "./utils/reducers/index"
import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import jwt from "jsonwebtoken";
import { setAuthorizationToken } from "./utils/helpers"
import { refreshUserToken, persistUserData } from "./utils/actions/auth"

var store = createStore(allReducer, applyMiddleware(thunk));

if (window.localStorage.token) {
    setAuthorizationToken(window.localStorage.token);
    var data = jwt.decode(window.localStorage.token);
    store.dispatch(refreshUserToken(data))
    store.dispatch(persistUserData(data))
}

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider >
    </BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
