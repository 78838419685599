import { setAuthorizationToken, setToken } from "../helpers";
import Axios from "axios";
import jwt from 'jsonwebtoken'
import * as CONSTANT from "../constant/auth";
import baseUrl from "../config";

// --- CONSTANT VARIABLES
const errMsg = "An error has occured. Please try again later"

// --- TOGGLE LOADING SPINNER
const requestLogin = () => ({ type: CONSTANT.LOGIN_REQUEST });

// --- SIGNUP  & LOGIN
const receiveSignUp = payload => ({ type: CONSTANT.SIGNUP_SUCCESS, payload });
const receiveLogin = payload => ({ type: CONSTANT.LOGIN_SUCCESS, payload });
const loginError = payload => ({ type: CONSTANT.LOGIN_FAILURE, payload });
const signupError = payload => ({ type: CONSTANT.SIGNUP_FAILURE, payload });

// -- UPDATE USER
const requestUpdate = () => ({ type: CONSTANT.UPDATE_REQUEST });
const updateSuccess = () => ({ type: CONSTANT.UPDATE_SUCCESS });
const updateError = (payload) => ({ type: CONSTANT.UPDATE_FAILURE, payload });
const updateImageSuccess = (payload) => ({ type: CONSTANT.UPDATE_IMAGE_SUCCESS, payload });



// --- RESET AUTH REDUCER  
export const requestResetAuth = () => ({ type: CONSTANT.RESET_PRE_AUTH_STATE });

// --- PERSIST USER DATA FROM LOCALSTORAGE
export const persistUserData = payload => ({ type: CONSTANT.PERSIST_USER_DATA, payload });

// --- REFRESH USER TOKEN FROM BACKEND
export const refreshUserToken = (user) => dispatch => {
  Axios.get(baseUrl + "/auth/refreshToken")
    .then((response) => {
      var { token } = response.data
      var data = jwt.decode(token)
      setAuthorizationToken(token);
      setToken(data)
    }).catch((err) => {
      console.log(err)
    })
}

// --- USER LOGOUT
export const requestLogout = () => dispatch => {
  window.localStorage.clear();
  dispatch({ type: CONSTANT.LOGOUT_REQUEST })
};

// --- SIGNIN USER
export const signin = (data) => async dispatch => {
  dispatch(requestLogin());

  var url = '/auth/signin'
  if(data.provider) url = '/auth/sociallogin'
  Axios.post(baseUrl + url, data)
    .then((response) => {
      var { token } = response.data;
      var user = jwt.decode(token)
      setAuthorizationToken(token)
      dispatch(receiveLogin(user))
      setToken(user);
    }).catch((error) => {
      var message
      if (error.response) {
        message = error.response.data.message;
      }
      dispatch(loginError(message || errMsg));
    })
};

// --- SIGNUP USER
export const signup = (user) => dispatch => {
  dispatch(requestLogin());

  Axios.post(baseUrl + '/auth/signup', user)
    .then((response) => {
      var { token } = response.data;
      var user = jwt.decode(token)
      setAuthorizationToken(token)
      dispatch(receiveSignUp(user))
      setToken(user);
    }).catch((error) => {
      var message
      if (error.response) {
        message = error.response.data.message;
      }
      dispatch(signupError(message || errMsg));
    })
};


export const updateUser = (user) => dispatch => {
  dispatch(requestUpdate());
  Axios.post(baseUrl + '/users/updateProfile', user)
    .then((response) => {
      var { token } = response.data;
      var user = jwt.decode(token)
      setAuthorizationToken(token)
      dispatch(receiveLogin(user))
      setToken(user);
      dispatch(updateSuccess())
    }).catch((error) => {
      var message
      if (error.response) {
        message = error.response.data.message;
      }
      dispatch(updateError(message || errMsg));
    })
}
export const updatePassword = (user) => dispatch => {
  dispatch(requestUpdate());
  Axios.post(baseUrl + '/auth/updatePassword', user)
    .then((response) => {
      var { token } = response.data;
      var user = jwt.decode(token)
      setAuthorizationToken(token)
      dispatch(receiveLogin(user))
      setToken(user);
      dispatch(updateSuccess())
    }).catch((error) => {
      var message
      if (error.response) {
        message = error.response.data.message;
      }
      dispatch(updateError(message || errMsg));
    })
}

export const updateDp = (data) => dispatch => {
  dispatch(requestUpdate());
  let formData = new FormData()
  formData.append('image', data )
  Axios.post(baseUrl + '/users/uploadDp', formData)
  .then((response) => {
    dispatch(updateImageSuccess(response.data.photoURL))
  }).catch((error) => {
    // var message
    // if (error.response) {
    //   message = error.response.data.message;
    // }
    dispatch(updateError('Something went wrong'));
  })
}

export const connectStripe = (payload) => dispatch => {
  Axios.post(baseUrl + '/users/connectStripe', payload)
    .then((response) => {
    }).catch((error) => {
      console.log(error)
    })
}