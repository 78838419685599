import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import { Message, Grid, Icon, Button, Form, Select, Image, GridColumn, Pagination } from 'semantic-ui-react';

class PrivacyPolicy extends Component {
  render() {
    const { history, auth } = this.props
    return (
      <div >
        <div className="y-terms">
          <Navbar history={history}
            auth={auth} />
          <div className="y-main">
            <h1 className="top-heading">Privacy & Policy</h1>
            <p>
              Your privacy is critically important to us.
            <br />
Masterclass of Beauty is located at:
</p>
            <p>
              Masterclass of Beauty
<br />
45 Yorkland blvd
<br />
ON, Canada


</p>
            <p>
              It is Masterclass of Beauty's policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to https://www.masterclassofbeauty.com/ (hereinafter, "us", "we", or "https://www.masterclassofbeauty.com/"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.

</p>
<p>
This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.

</p>
<h2>Website Visitors: </h2>
<p>
Like most website operators, Masterclass of Beauty collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Masterclass of Beauty's purpose in collecting non-personally identifying information is to better understand how Masterclass of Beauty's visitors use its website. From time to time, Masterclass of Beauty may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.
</p>
<p>
Masterclass of Beauty also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on https://www.masterclassofbeauty.com/ blog posts. Masterclass of Beauty only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.

</p>
<h2>Gathering of Personally-Identifying Information
: </h2>
<p>
Certain visitors to Masterclass of Beauty's websites choose to interact with Masterclass of Beauty in ways that require Masterclass of Beauty to gather personally-identifying information. The amount and type of information that Masterclass of Beauty gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at https://www.masterclassofbeauty.com/ to provide a username and email address.

</p>
<h2>
Security
</h2>
<p>
The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.

</p>
<h2>
Advertisements: 
</h2>
<p>
Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Masterclass of Beauty and does not cover the use of cookies by any advertisers.

</p>
<h2>
  Links To External Sites:

</h2>
<p>
Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.
We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.

</p>
<h2>
Protection of Certain Personally-Identifying Information
:
</h2>
<p>
Masterclass of Beauty discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Masterclass of Beauty's behalf or to provide services available at Masterclass of Beauty's website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Masterclass of Beauty's website, you consent to the transfer of such information to them. Masterclass of Beauty will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Masterclass of Beauty discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Masterclass of Beauty believes in good faith that disclosure is reasonably necessary to protect the property or rights of Masterclass of Beauty, third parties or the public at large.
If you are a registered user of https://www.masterclassofbeauty.com/ and have supplied your email address, Masterclass of Beauty may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Masterclass of Beauty and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Masterclass of Beauty takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.

</p>
<h2>
Aggregated Statistics
</h2>
<p>
Masterclass of Beauty may collect statistics about the behavior of visitors to its website. Masterclass of Beauty may display this information publicly or provide it to others. However, Masterclass of Beauty does not disclose your personally-identifying information.
</p>
<h2>
Affiliate Disclosure:
</h2>
<p>
This site uses affiliate links and does earn a commission from certain links. This does not affect your purchases or the price you may pay.

</p>
<h2>
Cookies
</h2>
<p>
To enrich and perfect your online experience, Masterclass of Beauty uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.
</p>
<p>
A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Masterclass of Beauty uses cookies to help Masterclass of Beauty identify and track visitors, their usage of https://www.masterclassofbeauty.com/, and their website access preferences. Masterclass of Beauty visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Masterclass of Beauty's websites, with the drawback that certain features of Masterclass of Beauty's websites may not function properly without the aid of cookies.

</p>
<p>
By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Masterclass of Beauty's use of cookies.
</p>
<h2>
E-commerce
</h2>
<p>
Those who engage in transactions with Masterclass of Beauty – by purchasing Masterclass of Beauty's services or products, are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, Masterclass of Beauty collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction with Masterclass of Beauty. Masterclass of Beauty does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.

</p>
<h2>
Business Transfers
</h2>
<p>
If Masterclass of Beauty, or substantially all of its assets, were acquired, or in the unlikely event that Masterclass of Beauty goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Masterclass of Beauty may continue to use your personal information as set forth in this policy.

</p>
<h2>
Privacy Policy Changes

</h2>
<p>
Although most changes are likely to be minor, Masterclass of Beauty may change its Privacy Policy from time to time, and in Masterclass of Beauty's sole discretion. Masterclass of Beauty encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.

</p>
          </div>

        </div>
        <Footer
          auth={auth} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth.auth,
  users: state.users
});

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicy);