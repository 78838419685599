import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import { Message, Grid, Icon, Button, Form, Select, Image, GridColumn, Pagination } from 'semantic-ui-react';

class TermsAndCondition extends Component {
  render() {
    const { history, auth } = this.props
    return (
      <div >
        <div className="y-terms">
          <Navbar history={history}
            auth={auth} />
          <div className="y-main">
            <h1 className="top-heading">Terms & Condition</h1>
            <p>
              These Website Standard Terms and Conditions (these “Terms” or these “Website
              Standard Terms and Conditions”) contained herein on this webpage, shall govern your use of this
              website, including all pages within this website (collectively referred to herein as this
              “Website”). These Terms apply in full force and effect to your use of this Website and by using
              this Website, you expressly accept all terms and conditions contained herein in full. You must
              not use this Website, if you have any objection to any of these Terms.
              The Services on this Website are not available to persons under the age of 13, or persons
              between the ages of 13 and 18 without the supervision of a parent or legal guardian. The Services
              on this Website are not available to any users previously removed from the Website Services by
              Masterclass of Beauty, and the Services are not available to any persons barred from receiving
              them under the laws of Canada or the applicable laws in any other jurisdiction.
         </p>

            <h2>Intellectual Property Rights:</h2>
            <p>Other than content you own, which you may have opted to include on this Website, under these Terms, Masterclass of Beauty and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.</p>


            <h2>Restrictions:</h2>
            <p>You are expressly restricted from all of the following:
</p>
            <ul>
              <li>Publishing any Website material in any media;
</li>
              <li>
                Selling, sub-licensing, and/or otherwise commercializing any Website material;

            </li>
              <li>
                Publicly performing and/or showing any Website material;

            </li>
              <li>
                Using this Website in any way that is, or may be, damaging to this Website;

            </li>
              <li>
                Using this Website in any way that impacts user access to this Website;

            </li>
              <li>
                Using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;

            </li>
              <li>Engaging in any data mining, data harvesting, data extracting or any similar activity in relation to this Website, or while using this Website; or
</li>
              <li>
                Using this Website to engage in any advertising or marketing.

            </li>
            </ul>
            <p>Certain areas of this Website are restricted from access by you and Masterclass of Beauty may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain the confidentiality of such information.
</p>

            <p>Your use of this Website must comply with all state, local, and federal laws. Any content featuring, encouraging, offering, or soliciting illegal activities is prohibited, and Masterclass of Beauty reserves the right to terminate any user account found to be engaging in such activity without warning. </p>

            <p>
              All suspensions of user accounts are binding until expiration/removal upon appeal. Any attempt by a user to circumvent the suspensions of their account by using other accounts or presence on another user’s account will also result in suspension and may lead to an indefinite suspension or termination.
</p>
            <p>
              Any activity that may endanger your life or the lives of others or lead to physical harm of either you or another person is strictly prohibited. These activities include, but are not limited to: threats of suicide, intentional physical harm, illegal drug, or alcohol usage, and dangerous or distracted driving. We do not recognize exceptions for self-destructive behavior that is performed as a stunt or a gag, when such behavior could reasonably be expected to cause physical injury.
</p>
            <p>
              Acts and threats of violence will be taken seriously, and all accounts associated with such activities will be terminated and may be reported to the relevant law enforcement authorities. This includes but is not limited to: threats or attempts to physically harm or kill others, attempts/threats to hack, DDOS, or SWAT others, and the use of weapons to physically threaten, intimidate, harm, or kill others.
</p>
            <p>
              Content or activity that promotes discrimination, denigration, harassment, violence, etc. based on race, ethnicity, national origin, religion, gender, age, etc. is prohibited and all accounts associated with such content/activity will be terminated. We strictly prohibit the use of this Website to facilitate discriminatory conduct or harassment, and individuals, communities or organizations that do so may not use this website. We reserve the right to take appropriate action against users for hateful conduct/harassment that is directed at our users whether that conduct/harassment occurs on or off of our website.
</p>
            <p>
              We expect users of this website to respect the privacy of others. The sharing of content that reveals private personal information about individuals or their private property without permission is strictly prohibited. This includes but is not limited to: Sharing personally identifiable information, sharing restricted or protected social profiles or any information from them, and sharing content that violates another person’s reasonable expectation of privacy.
</p>
            <p>
              Any content meant to impersonate an individual or organization is strictly prohibited. Any attempt to misrepresent yourself as a user of this website will result in an indefinite suspension or termination
</p>
            <p>
              Any spamming, scams, or malicious content is expressly prohibited. These activities include: unauthorized advertisements, posting repetitive, unwanted messages, phishing, defrauding others, spreading malware or viruses, spreading misinformation, tampering with follow or live viewer stats, selling or sharing user accounts, reselling website services or features, and defacing website pages or other user accounts.
</p>
            <p>
              Nudity and sexually explicit content or services, such as pornography, are strictly prohibited. Content/activities that threaten or promote sexual violence/exploitation may be reported to law enforcement, and content featuring child exploitation will be reported to the authorities via the National Center for Missing & Exploited Children. Sexually suggestive content or activities are also prohibited, with the exception of education or licensed content subject to restrictions.
</p>
            <p>
              Content featuring extreme or gratuitous violence/gore is strictly prohibited.
</p>
            <h2>Termination:</h2>
            <p>To the fullest extent allowed by applicable law, Masterclass of Beauty reserves the right, without notice and in our sole discretion, to terminate your license to use our Website and to block or prevent your access to and use of our Services if we reasonably believe that you have violated these Terms and Conditions or applicable law, fraudulently use or misuse Website Services, or we are unable to continue to provide Services due to legitimate technical or financial reasons. </p>



            <h2>Your Content:
</h2>
            <p>In these Terms, “Your Content” shall mean any audio, video, text, images, or other material you choose to display on this Website. With respect to Your Content, by displaying it, you grant Masterclass of Beauty a non-exclusive, worldwide, irrevocable, royalty-free, sub-licensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.</p>
            <p>
              Your Content must be your own and must not be infringing on any third party’s rights. Masterclass of Beauty reserves the right to remove any of your Content from this Website at any time, and for any reason, without notice. Users are only allowed to share content on this Website that they own or are authorized to share. Any third-party content included in user content, derivative creations, or performances of others’ copyrighted content may be considered infringing on another person’s intellectual property rights. Users are strongly advised to secure the rights to share third-party content before sharing it on this website.

            </p>
            <p>
              Any infringing content may be subject to a takedown by the rights holder(s) and summarily removed from this website. Multiple violations of our policies concerning intellectual property may lead to the termination of your account.

            </p>
            <p>
              Examples of content that cannot be share on this website without the permission of the copyright holders include:

            </p>
            <ul>
              <li>
                Sharing others’ content

              </li>
              <li>
                Playing pirated games or using unauthorized private servers

              </li>
              <li>
                Sharing content from other sites

              </li>
              <li>
                Showing movies, television shows, or sports matches

              </li>
              <li>
                Playing music that you do not own or have the rights to share.

              </li>
            </ul>
            <p>
              Users are expected to label their content as accurately as possible. When choosing a category or tag, we advise you to choose whichever best describes your content, as deliberate or extensive misuse of titles, tags, etc. is prohibited.

            </p>

            <h2>Third-Party Content:
</h2>
            <p>
              We may provide third-party content on this Website (collectively the “Third Party Content”). We do not control or endorse any Third-Party Content and make no representation or warranties of any kind regarding the Third-Party Content. We do not create, monitor, or update any Third-Party Content, and disclaim any responsibility for the Third-Party Content.

</p>
            <h2>
              Account:

</h2>
            <p>
              In order to open an account, you will be asked to provide us with information such as an account name and a password. You are solely responsible for maintaining the confidentiality of your account and password, for restricting access to your computer, and for all activities that occur under your account or password. Please make sure all information you provide to us upon registration and at all other times is accurate to the best of your knowledge.

</p>
            <p>
              We may permit you to register for and log onto the Website via certain third-party services. The third-party’s collection, use, and disclosure of your information will be subject to that third-party service’s privacy policy.
</p>
            <h2>
              Fees and Payment:

</h2>
            <p>
              We use Stripe for all payments for our courses. You may be instructed to agree to the terms of service offered by Stripe. We disclaim any and all responsibility for the contents of their terms of service.

</p>
            <p>
              There is also a 8 percent service fee for students who purchase a course through this website, which covers transaction and service fees. As well as a 5 percent  transaction fee taken from  a listed instructor that covers the platform’s service fees, whenever a transaction is placed.

</p>
            <h2>
              Use of Devices and Services:

</h2>
            <p>
              Access to the Website Services may require the use of your personal computer/mobile device. You are solely responsible for the internet connection or mobile fees/charges that you incur when accessing the Services.

</p>
            <h2>
              No Warranties:

</h2>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THIS WEBSITE IS PROVIDED “AS IS” WITH ALL FAULTS, AND MASTERCLASS OF BEAUTY MAKES NO EXPRESS OR IMPLIED REPRESENATIONS OR WARRANTIES OF ANY KIND RELATED TO THIS WEBSITE OR THE MATERIALS CONTAINED ON THIS WEBSITE. ADDITIONALLY, NOTHING CONTAINED ON THIS WEBSITE SHALL BE CONSTRUED AS PROVIDING ADVICE TO YOU.

</p>
            <h2>
              Limitation of Liability:

</h2>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL MASTERCLASS OF BEAUTY OR ANY OF ITS OFFICERS, DIRECTORS, AND EMPLOYEES BE LIABLE TO YOU FOR ANYTHING ARISING OUT OF OR IN ANY WAY CONNECTED TO YOUR USE OF THIS WEBSITE, WHETHER SUCH LIABILITY IS UNDER CONTRACT, TORT, OR OTHERWISE, AND MASTERCLASS OF BEAUTY, INCLUDING ITS OFFICERS, DIRECTORS AND EMPLOYEES SHALL NOT BE LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, OR SPECIAL LIABILITY ARISING OUT OF OR IN ANY WAY RELATED TO YOUR USE OF THIS WEBSITE.

</p>
            <h2>
              Indemnification:

</h2>
            <p>
              You hereby indemnify to the fullest extent Masterclass of Beauty from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms.

</p>
            <h2>
              Severability:

</h2>
            <p>
              If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.

</p>
            <h2>
              Variation of Terms:

</h2>
            <p>
              We reserve the right to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure that you understand all terms and conditions governing use of this Website.

</p>
            <h2>
              Assignment:

</h2>
            <p>
              Masterclass of Beauty shall be permitted to assign, transfer, and sub-contract its rights and/or obligations under these Terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or sub-contract any of your rights and/or obligations under these Terms.

</p>
            <h2>
              Entire Agreement:

</h2>
            <p>
              These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between Masterclass of Beauty and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same.

</p>
            <h2>
              Governing Law:

</h2>
            <p>
              These Terms will be governed by and construed in accordance with the laws of the Province of Ontario, and you submit to the non-exclusive jurisdiction of the provincial and federal courts located in Ontario for the resolution of any disputes.

</p>
          </div>

        </div>
        <Footer
          auth={auth} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth.auth,
  users: state.users
});

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndCondition);