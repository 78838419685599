import React, { Component } from "react"
import { Input, Form, Grid, Label, Button, Checkbox, Radio } from 'semantic-ui-react'
import { Link } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import $ from 'jquery'
import { signin, requestResetAuth } from "../../utils/actions/auth"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import Slogin from "../../components/ui/sociallogin";
import baseUrl, { secretKey } from "../../utils/config";
import Axios from "axios";
import jwt from "jsonwebtoken"
class Changepassword extends Component {
  constructor(props){
    super(props)
    this.state={
      newPassword:"",
      confirmPassword:"",
      success:"",
      isLoading:false,
      error:"",
    }
    this.typing = this.typing.bind(this)
    this.submit = this.submit.bind(this)
  }
  submit(e) {
    e.preventDefault();
    var tok = this.props.match.params.token;
    var data = jwt.decode(tok,secretKey)
    var {newPassword, confirmPassword} = this.state;
    if(newPassword !== confirmPassword) return  this.setState({error:"Password does not match",success:""})
    var token = jwt.sign({email:data.email,newPassword},secretKey)
    this.setState({error:"",success:"",isLoading:true})
    Axios.post(`${baseUrl}/auth/changePassword`, {token}).then((res) => {
        if (res.data.success) {
            this.setState({ success: res.data.success ,isLoading:false,email:"", newPassword: "", confirmPassword: ""})
        } else {
            this.setState({ error: res.data.error ,isLoading:false})
        }
    });
  }
  typing(e) {
    this.setState({
        [e.target.name]: e.target.value
    })
  }
  render() {
    var { error, loading, instructor} = this.state
    return (
      <div className="x-signin">
        <Navbar {...this.props} />
        <div className="x-main">
          <div className="x-body">
            <div className="x-heading">
              <h2>Change password</h2>
            </div>

            <p className="x-bottom">
            Please choose a new password
                </p>
            {error && <div className="x-error">
              {error}
            </div>}
            <div className="x-card">
              <Form  onSubmit={this.submit} loading={this.state.isLoading} error success>
             
                <div className="xlabel"><p>New password</p></div>
                <Form.Input 
                 type="password" name="newPassword" required onChange={this.typing} error={this.state.error?true:false}
                value={this.state.newPassword}
                />
                <div className="xlabel"><p>Confirm password</p></div>
                <Form.Input 
                 type="password" name="confirmPassword" required onChange={this.typing} error={this.state.error?true:false}
                 value={this.state.confirmPassword}

                />
                <div className="x-forgot">
                {this.state.success && <div >{this.state.success}</div>}
                {this.state.error && <div >{this.state.error}</div>}
                </div>
                <Button type="submit" size="large" fluid color="blue">Submit</Button>
              </Form>
            </div>
          </div>

        </div>
        <Footer {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = ((state) => {
  return {
    auth: state.auth
  }
})
const matchDispatchToProps = (dispatch) => bindActionCreators({
  signin,
  requestResetAuth
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Changepassword) 