import React, { useEffect, useState } from 'react'
import Navbar from "../../components/navbar";
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Icon, Button, Form, Dimmer, Header } from 'semantic-ui-react';
import Footer from "../../components/footer/footer";
import { payForCourse, resetPayment } from '../../utils/actions/course';
import { CardElement, injectStripe } from "react-stripe-elements";
import Spinner from '../../components/ui/spinner';

const Checkout = ({
  history, stripe, location
}) => {
  const dispatch =  useDispatch()
  const auth = useSelector(s => s.auth.auth);
  const chargedSuccessfully = useSelector(s => s.users.chargedSuccessfully);
  const chargeFailed = useSelector(s => s.users.chargeFailed);
  const [fetch, setFetching] = useState(true);
  const [loading, setLoader] = useState(false)
  const [charge, setCharge] = useState({
      name: "",
      amount: 0,
      currency: "usd",
      description: "",
      address_line1: "",
  })

  useEffect(()=>{
      setTimeout(() => {
        setFetching(false)
      }, 2000);
  })
  useEffect(() => {
    if(chargedSuccessfully){
        setLoader(false)
        setTimeout(() => {
            window.location.assign('/my_courses')
        }, 3000);
    }
    if(chargeFailed){
        setLoader(false)
    }
  }, [chargedSuccessfully, chargeFailed])
  const typing = (e) => {
    var name = e.target.name;
    setCharge({
      ...charge,
      [name]: e.target.value,
      amount: getMainPrice(location.state.course)
    })
  }
  const onChange = (e) => {
    setCharge({ ...charge, [e.elementType]: e.complete })
  }

  const getMainPrice = (course) => {
    var { runningPromo, discountType , promoprice, price, promopercent } = course
    if(runningPromo) {
      if(discountType === 'useprice') {
        return promoprice
      } else {
        return price - (promopercent / 100 * price)
      }
    } else {
      return price
    }
  }
  const makePayment = () =>{
    dispatch(resetPayment())
    setLoader(true)
    var {_id , creatorId, title} = location.state.course
    var {address_line1, name, address_zip} = charge
    stripe.createToken({
        name, email: auth.user && auth.user.email,
        address_line1, 
        address_zip
      })
    .then(({ token }) => {
       dispatch(payForCourse({token, courseId: _id , creatorId , title, ...charge}))
    }).catch((err)=>console.log(err))
}
  return (
    <div className="y-checkout">
      <Navbar history={history}
        auth={auth} />
        {fetch && <Spinner />}
        {chargedSuccessfully &&
        <Dimmer active page>
            <Header as='h2' icon inverted>
            <Icon name="check circle " color="green" />
                Congratulations!
            <Header.Subheader><br/> Your have successfully purchased <br /> {location.state.course.title}</Header.Subheader>
            </Header>
        </Dimmer>}
      <div className="y-main">
        <h1 className="x-top-title">Checkout</h1>
        <div className="y-checkout-main">
          <Grid>
            <Grid.Row>
              <Grid.Column computer="10" mobile="16">
                <div className="y-item-holder">
                  <h3 className="y-top-heading">Order Items</h3>
                      <div className="y-item">
                        <Grid>
                          <Grid.Row>
                            <Grid.Column computer="1" mobile="2">
                              <div className="img-holder">
                                <img src={location.state.course.coverPhoto} alt="" />
                              </div>
                            </Grid.Column>
                            <Grid.Column computer="11" mobile="14">
                              <div className="course-det">
                                <h4>
                                  {location.state.course.title}
                                </h4>
                                <p>{location.state.course.creatorId.forename} {location.state.course.creatorId.surname}</p>
                              </div>
                            </Grid.Column>
                            <Grid.Column computer="2" textAlign="right" className="y-pricing-holder" mobile="16">
                            {
                              location.state.course.runningPromo ?
                                <p className="y-pricing discounting"> ${location.state.course.discountType === 'useprice' ? location.state.course.promoprice : location.state.course.price - (location.state.course.promopercent / 100 * location.state.course.price) }</p> : <p className="y-pricing">${location.state.course.price}</p>
                            }
                            {
                              location.state.course.runningPromo ? <p className="y-pricing"><s> ${location.state.course.price}</s></p> : null
                            }
                              {/* <p className="y-pricing">${location.state.course.price}</p> */}
                            </Grid.Column>
                            {/* <Grid.Column computer="1" textAlign="right">
                              <p className="y-remove">X</p>
                            </Grid.Column> */}
                          </Grid.Row>
                        </Grid>
                      </div>
                  <p className="y-discount">Have discount code? Click Here</p>
                </div>
                <div className="y-payment">
                  <h3 className="y-top-heading">Payment Method</h3>
                  <div className="y-method">
                      <div className="method">
                        <p>Credit Card</p>
                      </div>
                      <div className="imgs-holder">
                        <img src="/images/Visa_Inc._logo.svg.png" alt=""/>
                        <img src="/images/1280px-Mastercard-logo.svg.png" alt=""/>
                      </div>
                  </div>
                  <Form className="y-form-main">
                    <Grid>
                      <Grid.Row columns="equal">
                        <Grid.Column computer="8" mobile="16">
                          <div className="y-input-holder name-on-card">
                            <p>NAME ON CARD</p>
                            <Form.Input fluid type="text"
                              id="name" required name="name" onChange={typing}
                              placeholder="Kinanthi Ayani"
                            />
                          </div>
                        </Grid.Column>
                        <Grid.Column computer="8" mobile="16">
                          <div className="y-input-holder">
                            <p>BILLING ADDRESS</p>
                            <Form.Input fluid type="text"
                              id="address_line1" required name="address_line1" onChange={typing}
                              placeholder="Enter your billing address"
                            />
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                          <Grid.Column>
                            <p>CARD DETAILS</p>

                            <CardElement onChange={onChange} />
                          </Grid.Column>
                      </Grid.Row>

                    </Grid>
                  </Form>
                </div>
              </Grid.Column>
              <Grid.Column computer="6" mobile="16">
                <Form loading={loading}>
                <div className="y-summary">
                  <h3 className="y-top-heading">Order Summary</h3>
                  <div className="y-breakdowns">
                    <div className="y-breakdown">
                      <p>Subtotal</p>
                      <p>$ {getMainPrice(location.state.course)}</p>
                    </div>
                    <div className="y-breakdown">
                      <p>Discount</p>
                      <p>$ 0</p>
                    </div>
                    <div className="y-breakdown">
                      <p>Service fee</p>
                      <p> ${0.07 * getMainPrice(location.state.course)} </p>
                    </div>
                    {/* <div className="y-breakdown">
                      <p>Platform fee</p>
                      <p> 5% </p>
                    </div> */}
                  </div>
                  <div className="y-total">
                    <div className="total-info">
                      <p>Total</p>
                      <p>$ {getMainPrice(location.state.course) + (getMainPrice(location.state.course)* 0.07) }</p>
                    </div>
                    {chargeFailed && <div className="x-error">
                        {chargeFailed}
                    </div>}
                    <Button onClick={()=>makePayment()} size="large">Checkout</Button>
                    <span>
                      By clicking checkout you accept the Saasdunk terms
                      and conditions and privacy policy.
                    </span>
                  </div>
                </div>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
      <Footer  auth={auth} />
    </div>
  )
}
export default injectStripe(Checkout);