import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from "react-router-dom"
import Signin from './containers/signin';
import Signup from './containers/signup';
import Dashboard from './containers/dashboard';
import Courses from './containers/courses';
import Course from './containers/course';
import Homepage from './containers/homepage'
import Profile from './containers/profile'
import Messages from './containers/messages'
import Upload from './containers/upload'
import Checkout from './containers/checkout'
import CheckForAuth from './containers/CheckForAuth';
import CheckInstructorAuth from './containers/CheckInstructorAuth';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Mycourses from './containers/mycourses';
import Getstarted from './containers/signup/getstarted';
import CreateCourse from './containers/createcourse/index'
import Search from './containers/search';
import EditCourse from './containers/editcourse/index'
import InstructorProfile from './containers/profile/instructor'
import Instructors from './containers/instructors/index'
import TermsAndCondition from './containers/terms/index'
import PrivacyPolicy from './containers/policy/index'
import Contact from './containers/contactus/index'
import { connectStripe } from './utils/actions/auth';
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import $ from "jquery"
import Connectstripsuc from './components/ui/connectstripesuc';
import Settings from './containers/profile/settings';
import socketIOClient from "socket.io-client";
import baseUrl from "./utils/config"
import { getChats } from './utils/actions/user'
// import { NotificationContainer, NotificationManager } from 'react-notifications';
import { offtriggerNewMessage, triggerNewMessage } from './utils/actions/course'
import { Portal, Segment, Header, TransitionablePortal, Icon } from 'semantic-ui-react';
import forgotpass from './containers/signin/forgotpass';
import changepass from './containers/signin/changepass';

const socket = socketIOClient(baseUrl.split('/api')[0]);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripeConnectionSuccess: false,
      chats: [],
      messageSentSuccess: false,
      currentChat: null,
      alert: true
    }
  }

  componentWillMount() {
    this.props.getChats()
    var searchParams = new URLSearchParams(window.location.search)
    var code = searchParams.get("code");
    var scope = searchParams.get("scope");
    var state = searchParams.get("state");
    if (code) {
      this.setState({ stripeConnectionSuccess: true })
      this.props.connectStripe({ code, state, scope })
    }
    // var state = searchParams.get("state");

  }

  componentWillReceiveProps(nextProps) {
    const { chats } = nextProps.users
    this.setState({
      chats
    })
    // if (nextProps.newMessage) {
    //   console.log('new message')
    //   NotificationManager.info('New message recieved')
    //   process.nextTick(() => {
    //     this.props.offtriggerNewMessage()
    //   })
    // }
  }

  componentDidMount() {

    const { uid } = this.props.auth.user
    socket.on(uid, ({ chats }) => {
      if (this.state.chats !== chats) {
        this.props.triggerNewMessage()
      }
      if (this.state.currentChat !== null) {
        chats.map((chat) => {
          if (chat._id === this.state.currentChat._id) {
            this.setState({
              currentChat: chat,
              chats,
              messageSentSuccess: true
            })
          }
        })
      } else {
        this.setState({
          chats
        })
      }
    })
    socket.emit('setstatus', { uid })

    this.setState({
      alert: this.props.auth.user.stripe_user_id ? true : false
    })
    $("body > #zmmtg-root").remove();
    $("body > #aria-notify-area").remove();
    true ? document.body.classList.add('dark-mode') :
      document.body.classList.remove('dark-mode')
  }

  componentWillUnmount() {
    socket.emit('disconnect')
    socket.off()
    // this.props.turnOffStatus()

  }

  reloadChats = () => {
    this.props.getChats()
  }

  render() {
    return (
      <div >
        {/* <NotificationContainer /> */}
        {this.state.stripeConnectionSuccess && <Connectstripsuc />}
        {this.props.auth.isAuthenticated && this.props.auth.user.instructor && !this.props.auth.user.stripe_user_id ? <TransitionablePortal
          closeOnTriggerClick
          onOpen={()=>this.setState({alert:true})}
          onClose={()=>this.setState({alert:false})}
          open={this.state.alert}
        >
          <Segment
            style={{ right: '2%', position: 'fixed', bottom: '2%', zIndex: 1000 }}
          >
            <Header style={{marginBottom: 0}}><Icon name="stripe" /> </Header>
            <div>Upload your Video Courses and Set up your Payouts with Stripe in your Dashboard! <br /> Stripe is what we use to pay Instructors out</div>
          </Segment>
        </TransitionablePortal>: null}
        <BrowserRouter>
          <Switch>
            <Route path="/contact-us" component={Contact} />
            <Route path="/privacy-and-policy" component={PrivacyPolicy} />
            <Route path="/terms-and-conditions" component={TermsAndCondition} />
            <Route exact path="/instructors/:slug" component={InstructorProfile} />
            <Route exact path="/instructors" component={Instructors} />
            <Route exact path="/edit-course/:slug" component={EditCourse} />
            <Route path="/upload-course" component={CreateCourse} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/getstarted" component={Getstarted} />

            <Route path="/messages"
              // component={Messages}
              render={(property) =>
                <Messages
                  history={property.history}
                  socket={socket}
                  chats={this.state.chats}
                  currentChat={this.state.currentChat}
                  messageSentSuccess={this.state.messageSentSuccess}
                  reloadChats={this.reloadChats}
                />
              }
            />
            <Route path="/profile" component={Profile} />
            <Route path="/settings" component={Settings} />
            <CheckInstructorAuth path="/dashboard" component={Dashboard} />
            <Route exact path="/my_courses" component={Mycourses} />
            <Route exact path="/courses" component={Courses} />
            <Route exact path="/search" component={Search} />
            <Route exact path="/courses/:slug" component={Course} />
            <CheckForAuth exact path="/signin" component={Signin} />
            <CheckForAuth path="/signup" component={Signup} />
            <CheckForAuth path="/forgot-password" component={forgotpass} />
            <Route exact path="/reset-password/:token"  component={changepass}/>
            <Route path="/" component={Homepage} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth.auth,
  users: state.users
});

const matchDispatchToProps = (dispatch) => bindActionCreators({
  connectStripe,
  getChats,
  offtriggerNewMessage,
  triggerNewMessage
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(App);
