import React, { Component, useEffect, useState, useRef } from "react";
import Navbar from "../../components/navbar";
import {
  Button,
  Image,
  Icon,
  Container,
  Grid,
  GridColumn,
  GridRow,
  Rating,
  Label,
  Modal,
  Form,
  Input
} from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import {
  fetchCourse,
  resetCourseAction,
  clearCourse,
  saveMyCourse,
  watchedCourses
} from "../../utils/actions/course";
import Footer from "../../components/footer/footer";
import { Player, ControlBar } from "video-react";
import Spinner from "../../components/ui/spinner";
import Infotab from "../../components/course/infotab";
import Lecturesaccordion from "../../components/course/accordion";
import PaidInfotab from "../../components/course/paidInfotab";
import Enroll from "../../components/footer/enroll";
import moment from "moment";
import $ from "jquery";
import Liveclassmodal from "./liveclassmodal";
class Course extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: {
        creatorId: {
          _id: ""
        }
      },
      activeLecture: {},
      isCourseLoaded: false,
      player: {},
      currentTime: "",
      trainingTime: 0,
      sections: []
    };
  }

  componentWillMount() {
    this.props.fetchCourse(this.props.match.params.slug);
  }

  componentDidMount() {
 
    // var video = document.getElementById("vid")
    // var {lectures} = this.state.course
    // var {_id} = this.state.activeLecture
    // video.onended = (e) =>{
    //   this.props.watchedCourses({
    //     lectureId: _id,
    //     courseId: this.state.course._id,
    //     watched: true
    //   })
    //   var index = lectures.findIndex(i => i._id == _id)
    //   console.log(_id,index)
    // }
   
  }

  componentWillReceiveProps(nextProps) {
    const { course, isCourseLoaded } = nextProps;
    if (isCourseLoaded) {
      this.setState(
        {
          course,
          isCourseLoaded
          // activeLecture: course.lectures[0] || {}
        },
        () => {
        }
      );
      this.props.resetCourseAction();
    }
    if (course.sections) {
      var reworksection = [];
      course.sections.map((section, index) => {
        reworksection.push({ ...section, lectures: [] });
        course.lectures.map(lecture => {
          if (section._id === lecture.sectionId) {
            reworksection[index].lectures.push({ ...lecture });
          }
        });
      });
      this.setState({
        sections: reworksection,
        activeLecture:
          reworksection.sort((a, b) => a.position - b.position)[0]
            .lectures[0] || {}
      });
    }
  }

  componentWillUnmount() {
    var {sections, course} = this.state
    var total = course.lectures.length
    var watched = 0
    this.props.clearCourse();
    var video = document.getElementById("vid");
    if(this.state.course &&
      !this.state.course.live &&
       ((this.state.course.paidCourse &&
      this.state.course.isMember ) || !this.state.course.paidCourse) &&
      this.props.auth.isAuthenticated )

      {
        sections.map((sect) => {
          sect.lectures.map((lect) =>{
            if(lect.watched) watched += 1
          })
        })

        
        this.props.saveMyCourse({
          courseId: this.state.course._id,
          creatorId: this.state.course.creatorId._id,
          trainingTime: this.state.trainingTime,
          lectureId: this.state.activeLecture._id,
          duration: video.currentTime,
          percentWatched: (watched * 100) / total
        });
      }
  }

  onTimeUpdate = e => {
    var { trainingTime } = this.state;
    setTimeout(() => {
      this.setState({ trainingTime: trainingTime + 1 });
    }, 1000);
  };

  checkHours = num => {
    let hours = Math.floor(num / 3600);
    return hours;
  };

  upateActiveLecture = lecture => {
    this.setState({
      activeLecture: lecture
    });
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  };
  updateChecked = (lectureId, checked) => {
    var {sections} = this.state
    sections.map(s => {
     s.lectures.map(l => {
        if(l._id == lectureId){
          if(checked)
          l.watched = true
          else  l.watched = false
        }
      })
    })
    this.setState({sections})
  }
  render() {
  const { history, auth } = this.props;
    const { course, isCourseLoaded, activeLecture } = this.state;
    this.player && this.player.subscribeToStateChange(this.handleStateChange);
    return isCourseLoaded ? (
      <div className="x-course">
        <Navbar history={history} auth={auth} />
        <div className="x-main">
          <Container fluid className="top-container">
            <h1>
              {" "}
              {course.title}{" "}
              {course.live && (
                <div>
                  <Label as="a" color="red" >
                    Live
                  </Label>
                </div>
              )}
            </h1>

            <div className="x-breadcrumb">
              <span> Home </span>
              <Icon name="caret right" />
              <span> Courses </span>
              <Icon name="caret right" />
              <span> {course.title} </span>
            </div>
            <Grid>
              <GridRow>
                <GridColumn mobile={16} tablet={course.live? 16 : 11} computer={course.live? 16 : 11}>
                 <div className="x-zoom-video">
                 {/* <div className="x-zoomsdk">
                  </div> */}
                 </div>
                  {!course.live && (
                    <video
                      onTimeUpdate={this.onTimeUpdate}
                      id="vid"
                      className="x-player"
                      src={activeLecture.videoUrl}
                      controls
                      key={activeLecture._id}
                      controlsList="nodownload"
                    >
                      Your browser does not support the video tag.
                      <Icon type="caret-left" />
                    </video>
                  )}
                  {!course.paidCourse || course.isMember ? (
                    <Infotab history={this.props.history} {...course} />
                  ) : (
                    <PaidInfotab {...course} history={this.props.history} />
                  )}
                </GridColumn>
                {(!course.paidCourse && auth.isAuthenticated) || course.isMember? (
                  <GridColumn mobile="16" computer="5">
                    <div className="x-rightcard">
                      <div className="x-header">
                        <h3>{course.title} </h3>
                        <div className="x-avatar">
                          <Image src={course.creatorId.photoURL} avatar />
                          <div>
                            {course.creatorId.forename}{" "}
                            {course.creatorId.surname} <br />
                            <Rating
                              maxRating={5}
                              defaultRating={course.creatorId.rating}
                              icon="star"
                              size="mini"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      {!course.live && <div className="x-segment">
                        <h4> Course Content </h4>
                      </div>}
                      {/* <div className="x-details">
                          <div><small>Section 0 | Intro</small></div>
                          <div><Icon name="play circle outline" size="small" /> <small>{course.lectures.length} section 0 | 20min</small></div>
                        </div> */}
                      {!course.live && <div className="x-content">
                        <Lecturesaccordion
                          upateActiveLecture={this.upateActiveLecture}
                          updateChecked={this.updateChecked}
                          lectures={course.lectures}
                          sections={this.state.sections}
                          courseId={course._id}
                          activeLectureId={this.state.activeLecture._id}
                          watchedCourses={this.props.watchedCourses}
                        />
                      </div>}
                    </div>

                    {course.creatorId._id === auth.user.uid ? (
                      <center
                        style={{
                          display: "flex",
                          justifyContent: "space-around"
                        }}
                      >
                        <Button
                          className="editbtn"
                          size="small"
                          onClick={() =>
                            this.props.history.push(
                              `/edit-course/${course.slug}`
                            )
                          }
                        >
                          Edit Course
                        </Button>
                        <Liveclassmodal
                          live={course.live}
                          courseId={course._id}
                        />
                      </center>
                    ) : null}
                  </GridColumn>
                ) : (
                  <GridColumn mobile="16" computer="5">
                    <div className="x-rightcard package">
                      <div className="x-header">
                        <h3>Course Info</h3>
                        <div className="x-avatar">
                          <Image src={course.creatorId.photoURL} avatar />
                          <div>
                            <h4>
                              {" "}
                              {course.creatorId.forename}{" "}
                              {course.creatorId.surname}
                            </h4>
                            <span>{course.creatorId.shortBio}</span>
                          </div>
                        </div>
                      </div>
                      <div className="x-package">
                        <p>
                          {" "}
                          {this.checkHours(course.duration)
                            ? moment
                                .utc(course.duration * 1000)
                                .format("hh:mm:ss") + " Hours"
                            : moment
                                .utc(course.duration * 1000)
                                .format("mm:ss") + " Mins"}{" "}
                          On-Demand Video{" "}
                        </p>
                        <p> {course.lessons} Lesson(s) </p>
                        {/* <p> 65 Downloadable Resources </p> */}
                        <p> Full Lifetime Access </p>
                        <p> Certificate Of Completion </p>
                        {course.paidCourse && <div className="x-card-pricing">
                          {course.runningPromo ? (
                            <h4>
                              {" "}
                              $
                              {course.discountType === "useprice"
                                ? course.promoprice
                                : course.price -
                                  (course.promopercent / 100) * course.price}
                            </h4>
                          ) : (
                            <h4>${course.price}</h4>
                          )}
                          {course.runningPromo ? (
                            <span>
                              <s> ${course.price}</s>
                            </span>
                          ) : null}
                        </div>}
                        {auth.isAuthenticated ? <Button
                          fluid
                          size="big"
                          animated="fade"
                          onClick={() => history.push("/checkout", { course })}
                        >
                          <Button.Content visible>
                            <Icon name="cart" /> Buy Now
                          </Button.Content>
                          <Button.Content hidden>
                            Goto Checkout <Icon name="arrow right" />
                          </Button.Content>
                        </Button>
                        : 
                        <Button 
                        fluid
                        size="big"
                        animated="fade"
                        onClick={()=>history.push("/signin")} > Signin to continue</Button>

                        }
                      </div>
                    </div>
                    {course.creatorId._id === auth.user.uid ? (
                      <center
                      style={{
                        display: "flex",
                        justifyContent: "space-around"
                      }}
                    > 
                      <Button
                        className="editbtn"
                        onClick={() =>
                          this.props.history.push(`/edit-course/${course.slug}`)
                        }
                      >
                        Edit Course
                      </Button>
                    
                     <Liveclassmodal
                          live={course.live}
                          courseId={course._id}
                        />
                  </center>
                  ) : null}
                  </GridColumn>
                )}
              </GridRow>
            </Grid>
          </Container>
        </div>
        <Enroll history={history} auth={auth} />
        <Footer auth={auth} />
        <style>
          {`
        .x-zoomsdk{
          position: relative;
          #zmmtg-root{
              position: relative !important;
                  min-height: 300px
              }
          }
        
      `}
        </style>
      </div>
    ) : (
      <Spinner />
    );
  }
}

const mapStateToProps = state => ({
  course: state.course.course,
  isCourseLoaded: state.course.isCourseLoaded,
  auth: state.auth.auth
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCourse,
      saveMyCourse,
      resetCourseAction,
      clearCourse,
      watchedCourses
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Course);
