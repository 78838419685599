import * as CONSTANT from "../constant/course";

const initialState = {
  appsettings: null,
  courses: [],
  myCourses: [],
  uploadedCourses: [],
  searchCourses: [],
  searchInstructors: [],
  totalSearchResults: 0,
  totalResults: 0,
  course: {},
  isSearchLoaded: false,
  isCourseLoaded: false,
  isCoursesLoaded: false,
  isLoading: false,
  isUploadCourseImageLoading: false,
  uploadCourseImage: null,
  uploadCourseImageSuccess: null,
  uploadCourseImageError: null,
  isUploadCourseVideoLoading: false,
  uploadCourseVideo: null,
  uploadCourseVideoSuccess: null,
  uploadCourseVideoError: null,
  uploadedVideos: [],
  addedCourseId: null,
  addedCourseSuccess: false,
  addedCourseError: null,
  isAddingCourse: false,
  addedCurriculumSuccess: false,
  addedCurriculumError: null,
  isAddingCurriculum: false,
  isEditingCourse: false,
  editCourseSuccess: false,
  editCourseError: null,
  isDeleteCourse: false,
  deleteCourseSuccess: null,
  deleteCourseError: null,
  newMessage: null
}

export default function course(state = initialState, action) {
  switch (action.type) {
    case CONSTANT.FETCH_COURSES:
      return {
        ...state,
        courses: action.payload.courses,
        totalResults: action.payload.totalResults,
        isCoursesLoaded: true
      }
    case CONSTANT.FETCH_SEARCH_COURSES:
      return {
        ...state,
        searchCourses: action.payload.courses,
        totalSearchResults: action.payload.totalSearchResults,
        isSearchLoaded: true
      }
    case CONSTANT.FETCH_SEARCH_INSTRUCTORS:
      return {
        ...state,
        searchInstructors: action.payload.instructors,
        totalSearchResults: action.payload.totalSearchResults,
        isSearchLoaded: true
      }
    case CONSTANT.FETCH_MY_COURSES:
      return {
        ...state,
        myCourses: action.payload.courses,
        totalResults: action.payload.totalResults,
        isCoursesLoaded: true
      }
    case CONSTANT.FETCH_UPLOADED_COURSES:
      return {
        ...state,
        uploadedCourses: action.payload.courses,
        totalCourses: action.payload.totalCourses,
        isCoursesLoaded: true
      }
    case CONSTANT.FETCH_COURSE:
      return {
        ...state,
        course: action.payload,
        isCourseLoaded: true
      }
    case CONSTANT.COURSE_REQUESTING:
      return {
        ...state,
        isLoading: true,
      };
    case CONSTANT.RESET_COURSE_ACTION:
      return {
        ...state,
        isLoading: false
      }
    case CONSTANT.CLEAR_COURSE_DATA:
      return {
        ...state,
        course: {},
        courses: [],
        myCourses: [],
        searchCourses: [],
        uploadedCourses: [],
        isCoursesLoaded: false,
        isCourseLoaded: false,
        isSearchLoaded: false
      }
    case CONSTANT.UPLOAD_COURSEIMAGE_REQUEST:
      return {
        ...state,
        isUploadCourseImageLoading: true,
        uploadCourseImageSuccess: null,
        uploadCourseImageError: null
      };
    case CONSTANT.UPLOAD_COURSEIMAGE_SUCCESS:
      return {
        ...state,
        isUploadCourseImageLoading: false,
        uploadCourseImageSuccess: true,
        uploadCourseImage: action.payload
      };
    case CONSTANT.UPLOAD_COURSEIMAGE_ERROR:
      return {
        ...state,
        isUploadCourseImageLoading: false,
        uploadCourseImageError: action.payload,
      };
    case CONSTANT.UPLOAD_COURSEVIDEO_REQUEST:
      return {
        ...state,
        isUploadCourseVideoLoading: true,
        uploadCourseVideoSuccess: null,
        uploadCourseVideoError: null
      };
    case CONSTANT.UPLOAD_COURSEVIDEO_SUCCESS:
      return {
        ...state,
        isUploadCourseVideoLoading: false,
        uploadCourseVideoSuccess: true,
        uploadCourseVideo: action.payload
      };
    case CONSTANT.UPLOAD_COURSEVIDEO_ERROR:
      return {
        ...state,
        isUploadCourseVideoLoading: false,
        uploadCourseVideoError: action.payload,
      };
    case CONSTANT.CLEAR_UPLOAD_COURSEIMAGE:
      return {
        ...state,
        uploadCourseImage: null,
        uploadCourseImageSuccess: null,
        uploadCourseImageError: null
      };
    case CONSTANT.CLEAR_UPLOAD_COURSEVIDEO:
      return {
        ...state,
        uploadCourseVideo: null,
        uploadCourseVideoSuccess: null,
        uploadCourseVideoError: null,
        isUploadCourseVideoLoading: false
      };
      case CONSTANT.RESETAFTERUPLOAD_COURSEVIDEO:
        return {
          ...state,
          uploadCourseVideo: null,
          uploadCourseVideoSuccess: null,
          uploadCourseVideoError: null,
          isUploadCourseVideoLoading: false,
          uploadedVideos: [],
        };
    case CONSTANT.UPDATE_UPLOADED_VIDEOS:
      return {
        ...state,
        uploadedVideos: action.payload,
      };
    case CONSTANT.REQUEST_ADDCOURSE:
      return {
        ...state,
        isAddingCourse: true,
        addedCourseSuccess: null,
        addedCourseError: null
      };
    // case CONSTANT.REQUEST_ADDCOURSE:
    //   return {
    //     ...state,
    //     isAddingCourse: true,
    //     addedCourseSuccess: null,
    //     addedCourseError: null
    //   };
    case CONSTANT.ADDCOURSE_SUCCESS:
      return {
        ...state,
        isAddingCourse: false,
        addedCourseSuccess: true,
        // addedCourseId: action.payload
      };
    case CONSTANT.ADDCOURSE_ERROR:
      return {
        ...state,
        isAddingCourse: false,
        addedCourseError: action.payload,
      };
    case CONSTANT.CLEAR_ADDEDCOURSE:
      return {
        ...state,
        // addedCourseId: null,
        addedCourseSuccess: false,
        addedCurriculumError: null
      };
    case CONSTANT.REQUEST_ADDCURRICULUM:
      return {
        ...state,
        isAddingCurriculum: true,
        addedCurriculumSuccess: null,
        addedCurriculumError: null
      };
    case CONSTANT.ADDCURRICULUM_SUCCESS:
      return {
        ...state,
        isAddingCurriculum: false,
        addedCurriculumSuccess: true,
      };
    case CONSTANT.ADDCURRICULUM_ERROR:
      return {
        ...state,
        isAddingCurriculum: false,
        addedCurriculumError: action.payload,
      };
    case CONSTANT.CLEAR_ADDCURRICULUM:
      return {
        ...state,
        addedCurriculumSuccess: false,
        addedCurriculumError: false
      };
    case CONSTANT.REQUEST_EDITCOURSE:
      return {
        ...state,
        isEditingCourse: true,
        editCourseSuccess: false,
        editCourseError: null
      };
    case CONSTANT.EDITCOURSE_SUCCESS:
      return {
        ...state,
        isEditingCourse: false,
        editCourseSuccess: true,
      };
    case CONSTANT.EDITCOURSE_ERROR:
      return {
        ...state,
        isEditingCourse: false,
        editCourseError: action.payload,
      };
    case CONSTANT.CLEAR_EDITEDCOURSE:
      return {
        ...state,
        editCourseSuccess: false,
        editCourseError: null
      };
    case CONSTANT.UPDATE_APPSETTINGS:
      return {
        ...state,
        appsettings: action.payload,
      };
    case CONSTANT.REQUEST_DELETE_COURSE:
      return {
        ...state,
        isDeleteCourse: true,
        deleteCourseSuccess: null,
        deleteCourseError: null
      };
    case CONSTANT.DELETE_COURSE_SUCCESS:
      return {
        ...state,
        isDeleteCourse: false,
        deleteCourseSuccess: action.payload,
        deleteCourseError: null
      };
    case CONSTANT.DELETE_COURSE_ERROR:
      return {
        ...state,
        isEditingCourse: false,
        deleteCourseSuccess: null,
        deleteCourseError: action.payload,
      };
    case CONSTANT.CLEAR_DELETE_COURSE:
      return {
        ...state,
        isDeleteCourse: false,
        deleteCourseSuccess: null,
        deleteCourseError: null
      };
    case CONSTANT.TRIGGER_NEWMESSAGE:
      return {
        ...state,
        newMessage: true
      };
    case CONSTANT.TRIGGER_NEWMESSAGE_OFF:
      return {
        ...state,
        newMessage: null
      };
    default:
      return state;
  }
}