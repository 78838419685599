import React, { useEffect, useState } from 'react'
import Navbar from "../../components/navbar";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses, resetCourseAction } from "../../utils/actions/course";
import { fetchInstructors, getAppSettings } from "../../utils/actions/user";
import Spinner from "../../components/ui/spinner";
import Footer from "../../components/footer/footer";
import { Container, Grid, Icon, Button, Modal } from 'semantic-ui-react';
import Searchbox from "../../components/courses/searchbox";
import OnlySearchbox from "../../components/homepage/searchbox";
import Coursecard from "../../components/courses/coursecard";
import Slider from "react-slick";
import Enroll from '../../components/footer/enroll';

const Homepage = ({
  history
}) => {
  const [openModal, setModalOption] = useState(false)
  const instrustorSlider = React.createRef()
  const dispatch = useDispatch()
  const coursesObj = useSelector(s => s.course);
  const instructors = useSelector(s => s.users.instructors)
  const auth = useSelector(s => s.auth.auth);
  const appsettings = useSelector(s => s.course.appsettings)
  const [testimony, setTestimony] = useState([
    { name: "Priscilla Kusi Asare", avatar: "https://res.cloudinary.com/afrikal/image/upload/v1595523572/0_oxkdmb.jpg", description: "I finally learned how to do my own makeup!" },
    { name: "Rachel Offeh", avatar: "https://res.cloudinary.com/afrikal/image/upload/v1595523573/4_j7r5hc.jpg", description: "I’ve improved soo much from these courses!" },
    { name: "Shanika Bowes", avatar: "https://res.cloudinary.com/afrikal/image/upload/v1595523572/3_m0bxiu.jpg", description: "The courses on this platform taught me soo much!" },
    { name: "Dorcas Agyekum", avatar: "https://res.cloudinary.com/afrikal/image/upload/v1595523572/5_hy1mhr.jpg", description: "These step by step videos make learning how to do hair so much easier than youtube!" },
    { name: "osephine", title: "student Aspiring MUA", avatar: "https://res.cloudinary.com/afrikal/image/upload/v1595523573/2_g8wu2n.jpg", description: "I’ve learned way more from these instructors then I ever did in cosmetology school" },
    { name: "Jessica Yeboah", avatar: "https://res.cloudinary.com/afrikal/image/upload/v1595523574/1_qn3iqy.jpg", description: "My makeup has gotten so much better!" },
    // {name: "", avatar: "", description: "Never thought I would learn so much from an online lash class"},
    // {name: "", avatar: "", description: "Finally learned how to shape my eyebrows"},
  ])
  const [sliderSettings, setSliderSettings] = useState({
    infinite: true,
    speed: 500,
    slidesToShow: window.innerWidth > 760 ? 4 : 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    autoplay: true,
  })
  useEffect(() => {
    dispatch(getAppSettings())
    dispatch(fetchCourses({ limit: 10, pageNum: 1 }))
    dispatch(fetchInstructors({ limit: 10, pageNum: 1 }))
  }, [])
  useEffect(() => {
    dispatch(resetCourseAction())
  }, [coursesObj.isCoursesLoaded])

  const gotoNext = () => {
    instrustorSlider.current.slickNext()
  }

  const gotoPrev = () => {
    instrustorSlider.current.slickPrev()
  }

  const closeModaltrigger = () => {
    setModalOption(false)
  }

  const openModaltrigger = () => {
    setModalOption(true)
  }

  return (
    coursesObj.isCoursesLoaded ?
      <>
        <div className="y-home">
          <Navbar history={history}
            auth={auth} appsettings={appsettings} />
          <div className={`y-main ${
            appsettings ?
              appsettings.showTopPromo ?
                !appsettings.showTopPromo.display ?
                  'noflash'
                  :
                  ''
                :
                ''
              :
              ''
            }`}>
            <div className="y-banner">
              <Grid>
                <Grid.Row>
                  <Grid.Column computer={10} mobile={16}>
                    <h1 className="y-top-heading">
                      Learn From the World’s
                     <br /> Top Beauticians
                </h1>
                    <p className="y-footnote">
                      Get access to high quality, Online Beauty Courses Led by the World’s Leading Beauty gurus!  Search or Explore Courses and Teachers Below.

                </p>
                    <Searchbox
                      history={history}
                      home={true}
                      type="courses"
                    />
                    {
                      appsettings ?
                        appsettings.videolink ?
                          appsettings.videolink.url.length > 0 ?
                            <p className="y-watch" onClick={openModaltrigger}><Icon name="play" />Watch the intro video</p>
                            :
                            null
                          :
                          null
                        :
                        null
                    }

                  </Grid.Column>
                  <Grid.Column computer={6} mobile={16}>
                    <div className="img-holder"></div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <div className="y-education">
              <h3 className="y-topmost-heading">EXPAND YOUR SKILLS</h3>
              <h1 className="y-top-heading">The Best In Online Beauty Learning</h1>
              <p className="y-footnote ">Helping individuals learn the skills and knowledge they need to succeed in the beauty industry and change their life for the better!
</p>
              <div className="y-features">
                <Grid>
                  <Grid.Row>
                    <Grid.Column mobile="16" tablet="8" computer="4" >
                      <div className="y-feature-card">
                        <div className="img-holder">
                          <img src="/images/home/peole.svg" alt="" />
                        </div>
                        <h2 className="top-title">
                          Beauty Gurus   <br />   as your tutor
                        </h2>
                        <p className="footnote">The top beauty gurus are now your
                        tutors</p>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile="16" tablet="8" computer="4">
                      <div className="y-feature-card">
                        <div className="img-holder">
                          <img src="/images/home/education.svg" alt="" />
                        </div>
                        <h2 className="top-title">
                          Professional    <br />  Courses
                        </h2>
                        <p className="footnote">Professional courses that help you
                        learn easily from home </p>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile="16" tablet="8" computer="4">
                      <div className="y-feature-card">
                        <div className="img-holder">
                          <img src="/images/home/map.svg" alt="" />
                        </div>
                        <h2 className="top-title">
                          Online   <br />   Learning
                        </h2>
                        <p className="footnote">Conveniently learn from the comfort of your home
 </p>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile="16" tablet="8" computer="4">
                      <div className="y-feature-card">
                        <div className="img-holder">
                          <img src="/images/home/commerce-and-shopping.svg" alt="" />
                        </div>
                        <h2 className="top-title">
                          Masterclass  <br />   Certificates
                        </h2>
                        <p className="footnote">Receive your Masterclass
                        certificate from the world's
leading Beauty Gurus. </p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
            <div className="y-aboutus">
              <Button size="large" className="y-float-btn" onClick={() => history.push("/courses")}>
                <div className="btn-info">
                  <Icon name="graduation cap" />
                  <span>29
                    <br />
                   COURSES TO DO</span>
                </div>
              </Button>
              <Grid>
                <Grid.Row columns="equal">
                  <Grid.Column mobile="16" tablet="8" computer="8" >
                    <h3 className="y-topmost-heading">ABOUT US</h3>
                    <h1 className="y-top-heading">
                      Masterclass
              <br />
              of Beauty
              </h1>
                    <p className="y-footnote">
                      Connecting students all over the world to the world’s leading
                      beauty gurus and instructors . The masterclass of beauty is helping
                      individuals learn the skills and knowledge they need to succeed in
                      the beauty industry and change their life for the better!
              </p>
                    <ul>
                      <li>
                        <Grid>
                          <Grid.Column computer="2">
                            <div className="icon-holder">
                              <div className="inner">
                                <Icon name="checkmark" />
                              </div>
                            </div>
                          </Grid.Column>
                          <Grid.Column computer="14">
                            <p className="list-info">
                              Learn from Step by step Beauty Courses and lessons that ensure you learn every detail!

                            </p>
                          </Grid.Column>
                        </Grid>
                      </li>
                      <li>
                        <Grid>
                          <Grid.Column computer="2">
                            <div className="icon-holder">
                              <div className="inner">
                                <Icon name="checkmark" />
                              </div>
                            </div>
                          </Grid.Column>
                          <Grid.Column computer="14">
                            <p className="list-info">
                              Learn from weekly live classes from celebrity stylists and beauty gurus!
                            </p>
                          </Grid.Column>
                        </Grid>
                      </li>
                    </ul>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column mobile="16" computer="6">
                          <Button size="large" onClick={() => window.open("https://www.masterclassofbeauty.info")}>Info Hub</Button>
                        </Grid.Column>
                        <Grid.Column mobile="16" computer="6">
                          {
                            appsettings ?
                              appsettings.videolink ?
                                appsettings.videolink.url.length > 0 ?
                                  <p className="y-watch" onClick={openModaltrigger}><Icon name="play" />Watch the intro video</p>
                                  :
                                  null
                                :
                                null
                              :
                              null
                          }
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column mobile="16" tablet="8" computer="8" verticalAlign="middle">
                    <div className="img-holder">
                      <img src="https://res.cloudinary.com/dzvhmiutm/image/upload/v1588337549/shutterstock_520577995_jmyfkw.jpg" alt="" />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <div className="y-achievements">
              <h1 className="y-top-heading">Our Achievements</h1>
              <p className="y-footnote ">Passionate, dedicated  beauty Instructors,
</p>
              <div className="y-details">
                <Grid>
                  <Grid.Row columns="equal">
                    <Grid.Column computer="4" tablet="8" mobile="16">
                      <h2 className="top-title">
                        5000
                        </h2>
                      <p className="footnote">Enrolled Students </p>
                    </Grid.Column>
                    <Grid.Column computer="4" tablet="8" mobile="16">
                      <h2 className="top-title">
                        250
                        </h2>
                      <p className="footnote">Online Courses </p>
                    </Grid.Column>
                    <Grid.Column computer="4" tablet="8" mobile="16">
                      <h2 className="top-title">
                        50
                        </h2>
                      <p className="footnote">Years of Services </p>
                    </Grid.Column>
                    <Grid.Column computer="4" tablet="8" mobile="16">
                      <h2 className="top-title">
                        120
                        </h2>
                      <p className="footnote">Expert Instructors </p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <div className="btn-holder">
                <Button style={{ borderRadius: '10px' }} size="large" onClick={() => history.push("/signup")}>Get started</Button>
              </div>
            </div>
            <div className="y-courses">
              <h3 className="y-topmost-heading">OUR BIG LIBRARY</h3>
              <h1 className="y-top-heading">Popular Courses </h1>
              <Grid>
                <Grid.Row columns="two">
                  <Grid.Column computer="10" tablet="10" mobile="16" >
                    <p className="y-footnote">
                      Explore new skills, dive deep into your beauty passion.

                  </p>
                  </Grid.Column>
                  <Grid.Column computer="6" tablet="6" mobile="16" textAlign="right">
                    <OnlySearchbox history={history} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={window.innerWidth > 760 ? "3" : "1"}>
                  {coursesObj.courses.slice(0, 9).map((course, key) => (
                    <Grid.Column key={course._id}>
                      <Coursecard
                        history={history}
                        course={course}
                      />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </Grid>
              <div className="btn-holder">
                <Button size="large" onClick={() => history.push("/courses")}>See All</Button>
              </div>
            </div>
            <div className="y-instrustors">
              <div className="y-controller">
                <Grid>
                  <Grid.Row columns="two">
                    <Grid.Column computer="10">
                    </Grid.Column>
                    <Grid.Column computer="6" textAlign="right">
                      <div className="controller-holder">
                        <img src="/images/home/return.svg" alt="" className="first" onClick={() => gotoPrev()} />
                        <img src="/images/home/interface.svg" alt="" onClick={() => gotoNext()} />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
              <div className="y-main-holder">
                <Slider {...sliderSettings} ref={instrustorSlider}>
                  {
                    instructors.map((instructor, key) => (
                      <div key={key} className="y-instr-card" key={instructor} onClick={() => history.push(`/instructors/${instructor.username}`)}>
                        <div className="img-holder">
                          <img src={instructor.photoURL} alt="" />
                        </div>
                        <Grid>
                          <Grid.Row columns="two">
                            <Grid.Column computer="13">
                              <h2 className="y-name">
                                {instructor.forename}  {instructor.surname}
                              </h2>
                              <p className="y-desc">
                                {instructor.shortBio || "New member"}
                              </p>
                            </Grid.Column>
                            <Grid.Column computer="3">
                              <h2 className="y-rate">
                                {instructor.rating}
                          </h2>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                    ))
                  }
                </Slider>
              </div>
              <div className="btn-holder" style={{ textAlign: "center" }}>
                <Button size="large" onClick={() => history.push("/instructors")}>See All</Button>
              </div>
            </div>
            <div className="y-love-us">
              <h1 className="y-top-heading">Students around the world <br /> have changed their life learning here!
</h1>
              <p className="y-footnote ">Helping individuals learn the skills and knowledge they need to succeed in the beauty industry and change their life
</p>
              <div className="y-testimonies">
                <Grid >
                  <Grid.Row columns="equal" centered>
                    {
                      testimony.map((testimony, key) => (
                        <Grid.Column computer="5" tablet="5" mobile="16" key={key}>
                          <div className="testimony">
                            <div className="img-holder">
                              <img src={testimony.avatar} alt="" />
                            </div>
                            <p className="testi-note">
                              “{testimony.description}."
                      </p>
                            <div className="testi-info">
                              <h1 className="tesit-name">{testimony.name}</h1>
                              <p className="tesit-desc">{testimony.title || "student"}</p>
                            </div>
                          </div>
                        </Grid.Column>
                      ))
                    }
                  </Grid.Row>
                </Grid>
              </div>
            </div>
            <div className="y-watchall">
              <Grid>
                <Grid.Row>
                  <Grid.Column computer="8" mobile="16" tablet="8" verticalAlign="middle">
                    <h1 className="y-top-heading">Watch Masterclass of Beauty Courses on our upcoming Mobile App!
</h1>
                    <p className="y-footnote ">Get access to high quality , online beauty
                    courses led by the World’s leading beauty gurus!
                    - helping individuals learn the skills and
                    knowledge they need to succeed in the beauty
industry and change their life.</p>
                    <div className="y-download">
                      <img src="/images/home/images.png" alt="" />
                      <img src="/images/home/get-app-google-play.png" alt="" />
                    </div>
                  </Grid.Column>
                  <Grid.Column computer="8" mobile="16" tablet="8" textAlign="right">
                    <center>
                      <img src={process.env.PUBLIC_URL + "/images/app.svg"} className="svgimg" style={{ width: "50%" }} />
                    </center>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <Enroll
              history={history}
              auth={auth} />
          </div>
          <Footer
            auth={auth} />
        </div>
        {
          appsettings ?
            <Modal open={openModal} onClose={closeModaltrigger} className="homepage-modal" centered={true} size="tiny">
              <Modal.Content>
                <video controls src={appsettings.videolink.url} >

                  Your browser does not support the video tag.
                            </video>
              </Modal.Content>
            </Modal>
            :
            null
        }

      </>
      :
      <Spinner />
  )
}


export default Homepage