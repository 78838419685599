import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import { Message, Grid, Icon, Button, Form, Select, Image, GridColumn, Pagination, Input } from 'semantic-ui-react';
import Spinner from "../../components/ui/spinner";
import moment from 'moment'
import { fetchInstructors } from "../../utils/actions/user";
import Searchbox from '../../components/courses/searchbox';
import { fetchSearchCourses, clearCourse } from '../../utils/actions/course';

class Instructors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      query: "",
      instructors: [],
      searchInstructors: [],
      limit: 12,
      pageNum: 1,
      totalResults: 0
    }
  }

  componentWillMount() {
    var {limit, pageNum} = this.state
    this.props.fetchInstructors({limit, pageNum})
  }

  componentWillUnmount(){
    this.props.clearCourse()
  }
  componentWillReceiveProps(nextProps) {
    var { instructors, totalResults } = nextProps.users
    var { searchInstructors } = nextProps
    if(searchInstructors){
      this.setState({searchInstructors, totalResults, loading: false})
    }
    if(instructors.length) {
      this.setState({
        instructors,
        totalResults,
        loading: false
      })
    }
  }

  typing = (e, data) => {
    this.setState({ [data.name]: data.value })
  }

  submit = (e) => {
    e.preventDefault()
    this.setState({loading: true})
    this.props.fetchSearchCourses({query: this.state.query, type: "instructors"})
  }
   handlePagination = (page) => {
    var {limit, pageNum} = this.state
    this.setState({pageNum: page, loading: true})
    this.props.fetchInstructors({limit, pageNum: page})
}
  render() {
    const { history, auth, isSearchLoaded} = this.props
    const { instructors, loading, searchInstructors, totalResults, limit, pageNum } = this.state
    var results = isSearchLoaded ? searchInstructors : instructors
    return (
      loading ? 
      <Spinner/>
      :
      <div className="y-teachers">
        <Navbar history={history}
          auth={auth} />
        <div className="y-main">
          <div className="x-flexhead">
          <h1 className="top-heading"> Teachers </h1>
           <form onSubmit={this.submit} >
            <Input 
              className="inputWrapper"
              placeholder='Search...' 
              icon="search"
              size="large"
              name="query"
              onChange={this.typing}
            />
          </form>
          </div>
          <div className="teacher-holder">
            <Grid>
              <Grid.Row>
                {
                  results.map((instructor) => (
                    <Grid.Column key={instructor._id} computer="4" mobile="16" >
                      <div className="y-instr-card" key={instructor} onClick={() => this.props.history.push(`/instructors/${instructor.username}`)}>
                        <div className="img-holder">
                          <img src={instructor.photoURL} alt="" />
                        </div>
                        <Grid>
                          <Grid.Row columns="two">
                            <Grid.Column computer="13">
                              <h2 className="y-name">
                                {instructor.forename}  {instructor.surname}
                              </h2>
                              {/* <p className="y-desc">
                                {instructor.shortBio || "New member"}
                              </p> */}
                              </Grid.Column>
                              <Grid.Column computer="3">
                                <h2 className="y-rate">
                                  {instructor.rating}.0
                          </h2>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </div>
                      </Grid.Column>
                    ))
                  }

                </Grid.Row>
                <Grid.Row textAlign="center">
                  <Grid.Column>
                  <Pagination
                    boundaryRange={0}
                    defaultActivePage={pageNum}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={Math.ceil(totalResults/limit)}
                    onPageChange={(e, data) => this.handlePagination(data.activePage)}
                  />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
          <Footer
            auth={auth} />
        </div >
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth.auth,
  users: state.users,
  isSearchLoaded: state.course.isSearchLoaded,
  searchInstructors: state.course.searchInstructors
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchInstructors,
  fetchSearchCourses,
  clearCourse
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Instructors);
