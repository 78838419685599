import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Message, Grid, Icon, Button, Form, Select, Image, Step, Checkbox, Label, Loader, Dropdown, Header, Modal, List } from 'semantic-ui-react';
import { bindActionCreators } from "redux"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import AddFaq from '../../components/upload/faq'
import { uploadCourseImage, uploadCourseVideo, clearUploadImage, clearUploadVideo, addCourse, clearaddedCourse } from "../../utils/actions/course";
import Slider from "react-slick";
import VideoModal from '../../components/upload/videomodal'
import { categories, languages, levels, currencies } from '../../assets/course'
import FileModal from '../../components/messages/filemodal'

class CreateCourse extends Component {
  constructor(props) {
    super(props)
    this.createCourseSlider = React.createRef()
    this.uploadRefImg = React.createRef()
    this.state = {
      subcategories: [],
      courseFiles: [],
      openModalFile: false,
      saveorpublish: 'publish',
      loading: false,
      imgurlLoader: false,
      currentLectureId: '',
      currentSectionid: '',
      openModal: false,
      sliderSettings: {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplaySpeed: 2000,
        cssEase: "linear",
        autoplay: false,
        draggable: false,
        infinite: false
      },
      tabs: [
        { name: 'Course Details', active: true, completed: false },
        { name: 'Target Students', active: false, completed: false },
        { name: 'Curriculum', active: false, completed: false },
        { name: 'Price & Image', active: false, completed: false }
      ],
      language: '',
      category: '',
      subcategory: '',
      level: '',
      currency: '',
      paidCourse: true,
      live: false,
      runningPromo: false,
      title: '',
      subtitle: '',
      description: '',
      studentsWillLearn: [
        {
          id: Math.round(Math.random() * 1000),
          text: ''
        }
      ],
      courseReq: [
        {
          id: Math.round(Math.random() * 1000),
          text: ''
        }
      ],
      targetStudents: [
        {
          id: Math.round(Math.random() * 1000),
          text: ''
        }
      ],
      sections: [{
        id: Math.round(Math.random() * 1000),
        title: '',
        willlearn: '',
        position: 0,
        duration: 0,
        lessons: 0,
        lectures: [
          {
            id: Math.round(Math.random() * 1000),
            position: 0,
            title: '',
            videoUrl: '',
            duration: 0
          }
        ]
      }],
      price: '',
      promoprice: '',
      imgurl: '',
      errors: [],
      faqs: [{
        id: Math.round(Math.random() * 1000),
        question: '',
        answer: ''
      }],
      // showAddFaq: false,
      success: null,
      discountType: '',
      promopercent: ''
    }
  }

  componentWillReceiveProps(nextProps) {
    const { course } = nextProps
    const { isUploadCourseImageLoading, uploadCourseImageSuccess, uploadCourseImage, uploadCourseImageError, isAddingCourse, addedCourseError, addedCourseSuccess } = course
    this.setState({
      imgurlLoader: isUploadCourseImageLoading,
      loading: isAddingCourse
    })
    if (uploadCourseImageSuccess) {
      this.setState({
        imgurl: uploadCourseImage
      })
      this.uploadRefImg.current.value = ''
      this.props.clearUploadImage()
    }
    if (uploadCourseImageError) {
      let errors = []
      errors.push('Course cover photo not uploaded')
      this.setState({
        errors
      })
      this.uploadRefImg.current.value = ''
    }
    if (addedCourseError) {
      let errors = []
      errors.push(addedCourseError)
      this.setState({
        errors
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
    if (addedCourseSuccess) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.setState({
        success: 'Course uploaed successfully'
      })
    }
  }

  closeModalHandlerFile = () => {
    this.setState({
      openModalFile: false
    })
  }

  openFileModal = () => {
    this.setState({
      openModalFile: true
    })
  }

  selectFile = (fileDetails) => {
    let { courseFiles } = this.state
    courseFiles.push({
      name: fileDetails.name,
      url: fileDetails.url,
      id: Math.round(Math.random() * 1000),
    })
    this.setState({
      openModalFile: false,
      courseFiles
    }, () => {
    })
  }

  removeFile = (id) => {
    var { courseFiles } = this.state
    const courseFileIndex = courseFiles.findIndex(file => file.id === id)
    courseFiles.splice(courseFileIndex, 1)
    this.setState({
      courseFiles
    })
  }

  next = (_, num) => {
    this.createCourseSlider.current.slickNext()
    // console.log(num)
    var { tabs } = this.state
    let i = 0
    while (i < num + 1) {
      tabs[i].active = true
      tabs[i].completed = true
      i++
    }
    this.setState({
      tabs
    })
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    })
  }

  prev = (_, num) => {
    this.createCourseSlider.current.slickPrev()
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    })
    console.log(num)
    var { tabs } = this.state
    let i = 0
    while (i < tabs.length) {
      tabs[i].active = false
      tabs[i].completed = false
      i++
    }
    for (let index = 0; index < num; index++) {
      tabs[index].active = true
      tabs[index].completed = true
    }
    this.setState({
      tabs
    })
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth"
    })
  }

  handleChangeDiscountType = (e, { value }) => {
    this.setState({
      discountType: value,
      promoprice: '',
      promopercent: ''
    })
  }

  triggerUploadImg = () => {
    this.uploadRefImg.current.click()
  }

  onChangeHandlerImg = (e) => {
    this.setState({
      imgurl: ''
    })
    this.props.uploadCourseImage(e.target.files[0])
  }

  removeImage = () => {
    this.setState({
      imgurl: ''
    })
    this.props.clearUploadImage()
  }

  typing = (e) => {
    var name = e.target.name;
    this.setState({
      [name]: e.target.value
    })
  }

  willLearnTyping = (text, id) => {
    const { studentsWillLearn } = this.state
    const willlearnIndex = studentsWillLearn.findIndex(item => item.id == id)
    studentsWillLearn[willlearnIndex].text = text
    this.setState({
      studentsWillLearn
    })
  }

  courseReqTyping = (text, id) => {
    const { courseReq } = this.state
    const reqIndex = courseReq.findIndex(item => item.id == id)
    courseReq[reqIndex].text = text
    this.setState({
      courseReq
    })
  }

  targetStudentTyping = (text, id) => {
    const { targetStudents } = this.state
    const targetIndex = targetStudents.findIndex(item => item.id == id)
    targetStudents[targetIndex].text = text
    this.setState({
      targetStudents
    })
  }

  faqTyping = (text, type, { id }) => {
    var { faqs } = this.state
    let faqIndex = faqs.findIndex(item => item.id == id)
    if (type === 'question') {
      faqs[faqIndex].question = text
    }
    if (type === 'answer') {
      faqs[faqIndex].answer = text
    }
    this.setState({
      faqs
    })
  }

  sectionTyping = (text, id) => {
    const { sections } = this.state
    const sectionIndex = sections.findIndex(item => item.id == id)
    sections[sectionIndex].title = text
    this.setState({
      sections
    })
  }

  sectionDescTyping = (text, id) => {
    const { sections } = this.state
    const sectionIndex = sections.findIndex(item => item.id == id)
    sections[sectionIndex].willlearn = text
    this.setState({
      sections
    })
  }

  lectureTyping = (text, id, lecid) => {
    const { sections } = this.state
    const sectionIndex = sections.findIndex(section => section.id == id)
    const lectureIndex = sections[sectionIndex].lectures.findIndex(lecture => lecture.id == lecid)
    sections[sectionIndex].lectures[lectureIndex].title = text
    this.setState({
      sections
    })
  }


  handleChangeCategory = (e, { value }) => {
    const { category } = this.state
    this.setState({ category: value })
    let subcategories = []
    if (value !== category) {
      let index = categories.findIndex(category => category.value === value)
      subcategories = categories[index].subcategories
      this.setState({
        subcategories,
        subcategory: ''
      })
    }
  }

  handleChangeSubCategory = (e, { value }) => {
    this.setState({ subcategory: value })
    console.log(value)
  }

  handleChangeLanguage = (e, { value }) => {
    this.setState({ language: value })
  }

  handleChangeLevel = (e, { value }) => {
    this.setState({ level: value })
  }

  handleChangeCurrency = (e, { value }) => {
    this.setState({ currency: value })
  }

  onChangeCheckbox = (evt, data) => {
    this.setState({
      runningPromo: data.checked,
      discountType: 'useprice'
    })
    if (!data.checked) {
      this.setState({
        promoprice: '',
        discountType: '',
        promopercent: ''
      })
    }
  }

  onChangeCheckboxPaid = (evt, data) => {
    this.setState({
      paidCourse: data.checked,
    })
    if (!data.checked) {
      this.setState({
        runningPromo: false,
        price: '',
        promoprice: '',
        currency: ''
      })
    }
  }
  onChangeCheckboxLive = (evt, data) => {
    this.setState({
      live: data.checked,
    })
  }
  addstudentsWillLearn = () => {
    var { studentsWillLearn } = this.state
    studentsWillLearn.push({
      id: Math.round(Math.random() * 1000),
      text: ''
    })
    this.setState({
      studentsWillLearn
    })
  }

  removestudentsWillLearn = (id) => {
    var { studentsWillLearn } = this.state
    const willlearnIndex = studentsWillLearn.findIndex(item => item.id == id)
    studentsWillLearn.splice(willlearnIndex, 1)
    this.setState({
      studentsWillLearn
    })
  }

  addcourseRef = () => {
    var { courseReq } = this.state
    courseReq.push({
      id: Math.round(Math.random() * 1000),
      text: ''
    })
    this.setState({
      courseReq
    })
  }

  removecourseRef = (id) => {
    var { courseReq } = this.state
    const reqIndex = courseReq.findIndex(item => item.id == id)
    courseReq.splice(reqIndex, 1)
    this.setState({
      courseReq
    })
  }


  addtargetStudent = () => {
    var { targetStudents } = this.state
    targetStudents.push({
      id: Math.round(Math.random() * 1000),
      text: ''
    })
    this.setState({
      targetStudents
    })
  }

  removetargetStudent = (id) => {
    var { targetStudents } = this.state
    const targetIndex = targetStudents.findIndex(item => item.id == id)
    targetStudents.splice(targetIndex, 1)
    this.setState({
      targetStudents
    })
  }

  addSection = () => {
    var { sections } = this.state
    sections.push({
      id: Math.round(Math.random() * 1000),
      title: '',
      willlearn: '',
      position: 0,
      duration: 0,
      lectures: [
        {
          id: Math.round(Math.random() * 1000),
          position: 0,
          title: '',
          videoUrl: '',
          duration: 0
        }
      ]
    })
    this.setState({
      sections
    })
    window.scrollTo(0, document.body.scrollHeight);
  }

  removeSection = (id) => {
    var { sections } = this.state
    const sectionIndex = sections.findIndex(section => section.id == id)
    sections.splice(sectionIndex, 1)
    this.setState({
      sections
    })
  }

  addLecture = (id) => {
    var { sections } = this.state
    const sectionIndex = sections.findIndex(section => section.id == id)
    sections[sectionIndex].lectures.push({
      id: Math.round(Math.random() * 1000),
      position: 0,
      title: '',
      videoUrl: '',
      duration: 0
    })
    this.setState({
      sections
    })
    window.scrollTo(0, document.body.scrollHeight);
  }

  setVideo = (videoUrl, duration) => {
    var { sections, currentSectionid, currentLectureId } = this.state
    const sectionIndex = sections.findIndex(section => section.id == currentSectionid)
    let newDuration = sections[sectionIndex].duration + duration
    sections[sectionIndex].duration = newDuration
    const lectureIndex = sections[sectionIndex].lectures.findIndex(lecture => lecture.id == currentLectureId)
    sections[sectionIndex].lectures[lectureIndex].videoUrl = videoUrl
    sections[sectionIndex].lectures[lectureIndex].duration = duration
    this.setState({
      openModal: false,
      sections
    })
  }

  selectVideo = (currentSectionid, currentLectureId) => {
    this.setState({
      openModal: true,
      currentSectionid,
      currentLectureId
    })
  }

  removeLecture = (id, lecid, duration) => {
    var { sections } = this.state
    const sectionIndex = sections.findIndex(section => section.id == id)
    let newDuration = sections[sectionIndex].duration === 0 ? 0 : sections[sectionIndex].duration - duration
    sections[sectionIndex].duration = newDuration
    const lectureIndex = sections[sectionIndex].lectures.findIndex(lecture => lecture.id == lecid)
    sections[sectionIndex].lectures.splice(lectureIndex, 1)
    this.setState({
      sections
    })
  }

  removeVideo = (id, lecid, duration) => {
    var { sections } = this.state
    const sectionIndex = sections.findIndex(section => section.id == id)
    let newDuration = sections[sectionIndex].duration === 0 ? 0 : sections[sectionIndex].duration - duration
    sections[sectionIndex].duration = newDuration
    const lectureIndex = sections[sectionIndex].lectures.findIndex(lecture => lecture.id == lecid)
    sections[sectionIndex].lectures[lectureIndex].videoUrl = ''
    sections[sectionIndex].lectures[lectureIndex].duration = 0
    this.setState({
      sections
    })
  }

  closeModalHandler = () => {
    this.setState({
      openModal: false
    })
  }

  dismissError = () => {
    this.setState({
      errors: []
    })
  }

  // showAddFaq = () => {
  //   this.setState({
  //     showAddFaq: true
  //   })
  // }

  // closeAddFaq = () => {
  //   this.setState({
  //     showAddFaq: false
  //   })
  // }

  addFaq = () => {
    var { faqs } = this.state
    faqs.push({
      id: Math.round(Math.random() * 1000),
      question: '',
      answer: ''
    })
    this.setState({
      faqs
    })
    window.scrollTo(0, document.body.scrollHeight);
  }

  removeFaq = (index) => {
    const { faqs } = this.state
    let newFaqs = faqs
    newFaqs.splice(index, 1)
    this.setState({
      faqs: newFaqs
    })
  }

  saveCourse = () => {
    this.setState({
      errors: []
    })
    let errors = []
    var { language, category, subcategory, level, currency, paidCourse, runningPromo, title, subtitle, description, studentsWillLearn, courseReq, targetStudents, sections, price, promoprice, imgurl,
      faqs, promopercent, discountType, live, courseFiles } = this.state
    if (!language || !category || !subcategory || !level || !title || !subtitle || !description) {
      errors.push('Fill in all required fields under Course Details')
    }
    var checkstudentsWillLearn = studentsWillLearn.some((item) => item.text === '')
    var checkcourseReq = courseReq.some((item) => item.text === '')
    var checktargetStudents = targetStudents.some((item) => item.text === '')
    var checkfaq = faqs.some((item) => item.answer === '' || item.question === '')
    if (!studentsWillLearn.length || checkstudentsWillLearn || checkcourseReq || !courseReq.length || checktargetStudents || !targetStudents.length || !faqs.length || checkfaq) {
      errors.push('Fill in all required fields under Target Students')
    }
    var checkAnyLecture
    var checkAnySection = sections.some((section) => section.title === '')
    sections.map((section) => {
      checkAnyLecture = section.lectures.some((lecture) => lecture.title === '' || lecture.videoUrl === '')
    })
    if (checkAnySection && !live) {
      errors.push('No section should have an empty title');
    }
    if (checkAnyLecture && !live) {
      errors.push('No lecture should have an empty title or an empty video');
    }
    if (!imgurl.length) {
      errors.push('Upload Course Image')
    }
    if (paidCourse) {
      if (!price || !currency) {
        errors.push('Fill in Course price and currency')
      }
      if (runningPromo) {
        if (discountType === 'useprice' && !promoprice) {
          errors.push('Fill in Course discounted price')
        }
        if (discountType === 'usepercent' && !promopercent) {
          errors.push('Fill in Course discounted percentage')
        }
      }
    }
    if (errors.length) {
      this.setState({
        errors
      })
      window.scroll({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
    } else {
      sections.map((section, index) => {
        section.position = index
        section.lessons = section.lectures.length
        section.lectures.map((lecture, lecindex) => (
          lecture.position = lecindex
        ))
      })
      var detail = {
        title,
        subtitle,
        description,
        category,
        subcategory,
        language,
        level,
        currency,
        studentsWillLearn,
        courseReq,
        targetStudents,
        sections,
        paidCourse,
        runningPromo,
        promoprice,
        price,
        live,
        coverPhoto: imgurl,
        faqs,
        promopercent,
        discountType,
        published: false,
        courseFiles
      }
      this.props.addCourse(detail)
      this.setState({
        saveorpublish: 'save'
      })
    }
  }

  publishCourse = () => {
    this.setState({
      errors: []
    })
    let errors = []
    var { language, category, subcategory, level, currency, paidCourse, runningPromo, title, subtitle, description, studentsWillLearn, courseReq, targetStudents, sections, price, promoprice, imgurl,
      faqs, promopercent, discountType, live, courseFiles } = this.state
    if (!language || !category || !subcategory || !level || !title || !subtitle || !description) {
      errors.push('Fill in all required fields under Course Details')
    }
    var checkstudentsWillLearn = studentsWillLearn.some((item) => item.text === '')
    var checkcourseReq = courseReq.some((item) => item.text === '')
    var checktargetStudents = targetStudents.some((item) => item.text === '')
    var checkfaq = faqs.some((item) => item.answer === '' || item.question === '')
    if (!studentsWillLearn.length || checkstudentsWillLearn || checkcourseReq || !courseReq.length || checktargetStudents || !targetStudents.length || !faqs.length || checkfaq) {
      errors.push('Fill in all required fields under Target Students')
    }
    var checkAnyLecture
    var checkAnySection = sections.some((section) => section.title === '')
    sections.map((section) => {
      checkAnyLecture = section.lectures.some((lecture) => lecture.title === '' || lecture.videoUrl === '')
    })
    if (checkAnySection && !live) {
      errors.push('No section should have an empty title');
    }
    if (checkAnyLecture && !live) {
      errors.push('No lecture should have an empty title or an empty video');
    }
    if (!imgurl.length) {
      errors.push('Upload Course Image')
    }
    if (paidCourse) {
      if (!price || !currency) {
        errors.push('Fill in Course price and currency')
      }
      if (runningPromo) {
        if (discountType === 'useprice' && !promoprice) {
          errors.push('Fill in Course discounted price')
        }
        if (discountType === 'usepercent' && !promopercent) {
          errors.push('Fill in Course discounted percentage')
        }
      }
    }
    if (errors.length) {
      this.setState({
        errors
      })
      window.scroll({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
    } else {
      sections.map((section, index) => {
        section.position = index
        section.lessons = section.lectures.length
        section.lectures.map((lecture, lecindex) => (
          lecture.position = lecindex
        ))
      })
      var detail = {
        title,
        subtitle,
        description,
        category,
        subcategory,
        language,
        level,
        currency,
        studentsWillLearn,
        courseReq,
        targetStudents,
        sections,
        paidCourse,
        runningPromo,
        promoprice,
        price,
        live,
        coverPhoto: imgurl,
        faqs,
        promopercent,
        discountType,
        published: true,
        courseFiles
      }
      this.props.addCourse(detail)
      this.setState({
        saveorpublish: 'publish'
      })
    }
  }

  dismissSuccess = () => {
    this.setState({
      success: null
    })
  }

  gotoDashboard = () => {
    this.props.clearaddedCourse()
    this.props.history.push('/dashboard')
  }

  progressClick = (indexX) => {
    this.createCourseSlider.current.slickGoTo(indexX)
    var { tabs } = this.state
    let i = 0
    while (i < tabs.length) {
      tabs[i].active = false
      tabs[i].completed = false
      i++
    }
    for (let index = 0; index < indexX + 1; index++) {
      tabs[index].active = true
      tabs[index].completed = true
    }
    this.setState({
      tabs
    })
  }

  render() {
    const { history, auth } = this.props
    const { sliderSettings, tabs, category, subcategories, subcategory, language, level, vid, currency, runningPromo, paidCourse, studentsWillLearn, courseReq, targetStudents, sections, openModal, imgurl, imgurlLoader, loading, errors, showAddFaq, faqs, success, discountType, live, saveorpublish, openModalFile } = this.state
    let imageHoldeStyle = {}
    if (imgurl) {
      imageHoldeStyle.style = { backgroundImage: `url(${imgurl})` }
    } else {
      imageHoldeStyle = {}
    }
    return (
      <div className="y-createcourse">
        <Navbar history={history}
          auth={auth.auth} />
        {
          success ?
            <div className="y-course-success">
              <Icon name="check circle outline" />
              <h1>Congratulations</h1>
              {
                saveorpublish === 'publish' ?
                  <>
                    <h1>You Course is successfully published</h1>
                    <span>
                      You can now  access your course in your dashboard to edit at any time. It is now published on your profile for students to see and purchase from you!
          </span>
                  </>
                  :
                  <>
                    <h1>You Course is successfully saved</h1>
                    <span>
                      Your course has been saved and has not been published on the site, you can access it in your dashboard and complete publishing your course anytime
          </span>
                  </>
              }
              <Button type="button" onClick={this.gotoDashboard}>
                Go to Dashboard
          </Button>
            </div>
            :
            <div className="y-main">
              <div className="top-title">
                <h2>Create a course</h2>
              </div>
              <ul className="progressbar">
                {
                  tabs.map((tab, index) => (
                    <li disabled key={index} onClick={() => this.progressClick(index)} className={`${tab.active ? 'active' : ''} ${tab.completed ? 'completed' : ''}`}>{tab.name}</li>
                  ))
                }
              </ul>
              <div className="y-slick-holder">
                {
                  success ? <Message positive className="y-feedback"
                    onDismiss={this.dismissSuccess}
                  >
                    <p>{success}</p>
                  </Message> : null
                }
                {
                  errors.length ? <Message negative
                    className="y-feedback"
                    onDismiss={this.dismissError}>
                    {
                      errors.map((error, index) => (
                        <p key={index}>{error}
                        </p>
                      ))
                    }
                  </Message> : null
                }
                <Form className="y-form" loading={loading}>
                  <Slider {...sliderSettings} ref={this.createCourseSlider}>
                    <div className="y-slide">
                      <div className="y-slider-head">
                        <h2>Course Details</h2>
                      </div>
                      <div className="y-input-holder">
                        <p>Course Title*</p>
                        <Form.Input fluid type="text"
                          id="title" required name="title" onChange={this.typing}
                        />
                      </div>
                      <div className="y-input-holder">
                        <p>Course Subtitle*</p>
                        <Form.Input fluid type="text"
                          id="subtitle" required name="subtitle" onChange={this.typing}
                        />
                      </div>
                      <div className="y-input-holder">
                        <p>Course Description*</p>
                        <Form.TextArea required style={{ minHeight: 70 }} name="description" required onChange={this.typing} />
                      </div>
                      <div className="y-input-holder">
                        <p>Category*</p>
                        <Select name="category" options={categories} onChange={this.handleChangeCategory} value={category}
                        />
                      </div>

                      <div className="y-input-holder">
                        <p>SubCategory*</p>
                        <code>Choose a category before choosing a subcategory</code>
                        <Select name="subcategory" options={subcategories} onChange={this.handleChangeSubCategory} value={subcategory}
                        />
                      </div>
                      <div className="y-input-holder">
                        <p>Languages*</p>
                        <Select name="language" options={languages} onChange={this.handleChangeLanguage} value={language}
                        />
                      </div>
                      <div className="y-input-holder">
                        <p>Select Level*</p>
                        <Select name="level" options={levels} onChange={this.handleChangeLevel} value={level}
                        />
                      </div>
                      <div className="y-input-holder">
                        <Checkbox toggle checked={live} label='Is this course a live course?'
                          onClick={(evt, data) => this.onChangeCheckboxLive(evt, data)}
                        />
                      </div>
                      <div className="slide-nav">
                        {/* <Button size="large"  className="y-navbtn" type="button" onClick={() => this.prev('_', 1)} >
                          Previous
                        </Button> */}
                        <Button size="large" className="y-navbtn next" type="button" onClick={() => this.next('_', 1)} >
                          Next
                        </Button>
                      </div>
                    </div>
                    <div className="y-slide">
                      <div className="y-slider-head">
                        <h2>Target your students</h2>
                      </div>
                      <p>The descriptions you write here will help students
decide if your course is the one for them.</p>
                      <div className="y-input-holder">
                        <p>What will students learn in your course?*</p>
                        {
                          studentsWillLearn.map((willlearn, index) => (
                            <div className="inputwithx" key={index}>
                              <Form.Input fluid type="text"
                                placeholder="Required"
                                id="willlearn" required name="willlearn"
                                onChange={(e) => this.willLearnTyping(e.target.value, willlearn.id)}
                              />
                              <Icon name="times" onClick={() => this.removestudentsWillLearn(willlearn.id)} />
                            </div>
                          ))
                        }
                        <span className="addanswer" onClick={this.addstudentsWillLearn}><Icon name="plus" /> Add Answer</span>
                      </div>
                      <div className="y-input-holder">
                        <p>Are there any course requirements or prerequisites?*</p>
                        {
                          courseReq.map((req, index) => (
                            <div className="inputwithx" key={index}>
                              <Form.Input fluid type="text"
                                placeholder="Required"
                                id="courseref" required name="courseref"
                                onChange={(e) => this.courseReqTyping(e.target.value, req.id)}
                              />
                              <Icon name="times" onClick={() => this.removecourseRef(req.id)} />
                            </div>
                          ))
                        }
                        <span className="addanswer" onClick={this.addcourseRef}><Icon name="plus" /> Add Answer</span>
                      </div>
                      <div className="y-input-holder">
                        <p>Who are your target students?*</p>
                        {
                          targetStudents.map((target, index) => (
                            <div className="inputwithx" key={index}>
                              <Form.Input fluid type="text"
                                placeholder="Required"
                                id="targetStudent" required name="targetStudent"
                                onChange={(e) => this.targetStudentTyping(e.target.value, target.id)}
                              />
                              <Icon name="times" onClick={() => this.removetargetStudent(target.id)} />
                            </div>
                          ))
                        }
                        <span className="addanswer" onClick={this.addtargetStudent}><Icon name="plus" /> Add Answer</span>
                      </div>
                      <div className="y-input-holder">
                        <div className="custom-label">
                          <p>Course Questions and Answers* </p>
                          <Button size="mini" type="button" onClick={this.addFaq}>
                            Add
                  </Button>
                        </div>
                        {
                          faqs.map((faq, index) => (
                            <AddFaq faq={faq} key={index}
                              index={index}
                              faqTyping={this.faqTyping}
                              removeFaq={this.removeFaq}
                            />
                          ))
                        }
                        {/* {
                          showAddFaq ? <AddFaq closeAddFaq={this.closeAddFaq} addFaq={this.addFaq} /> : null
                        } */}
                      </div>
                      <div className="slide-nav">
                        <Button size="large" className="y-navbtn" type="button" onClick={() => this.prev('_', 1)} >
                          Previous
                        </Button>
                        <Button size="large" className="y-navbtn next" type="button" onClick={() => this.next('_', 2)} >
                          Next
                        </Button>
                      </div>
                    </div>
                    <div className="y-slide">
                      <div className="y-slider-head withbtn">
                        <h2>Curriculum</h2>
                        <Button disabled={live} size="small" type="button" onClick={this.addSection}>Add Section</Button>
                      </div>
                      <p>Start putting together your course by creating sections, lessons
and practice (quizzes, coding exercises and assignments).</p>
                      {
                        sections.map((section, index) => (
                          <div className="y-section-holder" key={section.id}>
                            <div className="top">
                              <p>Section {index + 1}</p>
                              {!live && <p className="likebtn" onClick={() => this.addLecture(section.id)}><Icon name="plus" />Add Lesson</p>}
                            </div>
                            <p>Section Title*</p>
                            <Form.Input fluid type="text"
                              disabled={live}
                              id="sectiontitle" required name="sectiontitle"
                              onChange={(e) => this.sectionTyping(e.target.value, section.id)}
                            />
                            <p>What will students be able to do at the end of this section?</p>
                            <Form.TextArea
                              disabled={live}
                              required style={{ minHeight: 70 }} name="sectiondesc" required onChange={(e) => this.sectionDescTyping(e.target.value, section.id)} />
                            <p>Lesson</p>
                            {
                              section.lectures.map((lecture, index) => (
                                <div className="y-lecture-holder" key={lecture.id}>
                                  <div className="top">
                                    <p>Lesson Title*</p>
                                    {
                                      index === 0 ? null :
                                        <Icon name="times"
                                          onClick={() =>
                                            this.removeLecture(section.id, lecture.id, lecture.duration)}
                                          className="likebtn" />
                                    }
                                  </div>
                                  <Form.Input fluid type="text"
                                    disabled={live}
                                    id="lecturetitle" required name="lecturetitle"
                                    onChange={(e) => this.lectureTyping(e.target.value, section.id, lecture.id)}
                                  />
                                  {
                                    lecture.videoUrl ?
                                      <div className="lecture-vid">
                                        <video controls src={lecture.videoUrl} >
                                          {/* <source src={lecture.videoUrl} type={`video/${lecture.videoUrl.split('.')[lecture.videoUrl.split('.').length - 1]}`} /> */}
                              Your browser does not support the video tag.
                            </video>
                                        <Icon name="times"
                                          onClick={() =>
                                            this.removeVideo(section.id, lecture.id, lecture.duration)
                                          }
                                          className="likebtn" />
                                      </div>
                                      :
                                      null
                                  }
                                  <Button fluid
                                    disabled={lecture.videoUrl || live ? true : false}
                                    type="button" onClick={() => this.selectVideo(section.id, lecture.id)}>
                                    Add Media
                          </Button>
                                </div>
                              ))
                            }
                            {
                              index === 0 ? null :
                                <Button type="button" onClick={() => this.removeSection(section.id)}>
                                  Remove Section
                     </Button>
                            }
                          </div>
                        ))
                      }
                      <div className="y-files-holder">
                        <h3> Courses Files</h3>
                        {
                          this.state.courseFiles.length > 0 ?
                            <div className="fileslist-holder">
                              <List bulleted>
                                {
                                  this.state.courseFiles.map((item) => (
                                    <List.Item key={item.id}>
                                      <div className="list-content">
                                        <p>{item.name}</p>
                                        <Icon name="times"
                                          onClick={() => this.removeFile(item.id)}
                                        />
                                      </div>
                                    </List.Item>
                                  ))
                                }
                              </List>
                            </div>
                            :
                            null
                        }

                        <Button type="button" onClick={() => this.openFileModal()}>
                          Upload File
                       </Button>
                      </div>
                      <div className="slide-nav">
                        <Button size="large" className="y-navbtn" type="button" onClick={() => this.prev('_', 2)} >
                          Previous
                        </Button>
                        <Button size="large" className="y-navbtn next" type="button" onClick={() => this.next('_', 3)} >
                          Next
                        </Button>
                      </div>
                    </div>
                    <div className="y-slide">
                      <h2>Pricing</h2>
                      <p>Please select the price tier for your course below and click 'Save'.
                      The list price that students will see in other currencies is
                      calculated using the price tier matrix, based on the tier that it
corresponds to.</p>
                      <div className="y-input-holder">
                        <Checkbox toggle checked={paidCourse} label='Is this course a paid course?'
                          onClick={(evt, data) => this.onChangeCheckboxPaid(evt, data)}
                        />
                      </div>
                      {
                        paidCourse ?
                          <>
                            <div className="y-input-holder">
                              <Checkbox toggle label='Running discounted price?'
                                checked={runningPromo}
                                onClick={(evt, data) => this.onChangeCheckbox(evt, data)}
                              />
                            </div>
                          </>
                          : null
                      }
                      {
                        runningPromo ?
                          <div>
                            <Checkbox
                              radio
                              label='Use price for discount'
                              name='usepriceorpercent'
                              value='useprice'
                              checked={discountType === 'useprice'}
                              onChange={this.handleChangeDiscountType}
                            />
                            <br />
                            <Checkbox
                              radio
                              label='Use percentage for discount'
                              name='usepercent'
                              value='usepercent'
                              checked={discountType === 'usepercent'}
                              onChange={this.handleChangeDiscountType}
                            />
                          </div> : null
                      }
                      {
                        paidCourse ? <div className="y-input-holder">
                          <p>Enter Course Price</p>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column mobile="16" computer="6">
                                <p>Currency</p>
                                <Select name="gender" options={currencies} onChange={this.handleChangeCurrency} value={currency}
                                />
                              </Grid.Column>
                              <Grid.Column mobile="16" computer="10">
                                <p>Price</p>
                                <Form.Input fluid type="number"
                                  id="price" required name="price"
                                  onChange={this.typing}
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </div>
                          : null
                      }

                      {
                        runningPromo ?
                          <div className="y-input-holder">
                            <p>Discount {discountType === 'useprice' ? 'Price' : 'Percentage'} </p>
                            {
                              discountType === 'useprice' &&
                              <Form.Input fluid type="number"
                                id="promoprice" required name="promoprice"
                                onChange={this.typing}
                              />
                            }
                            {
                              discountType === 'usepercent' && <Form.Input fluid type="number"
                                id="promopercent" required name="promopercent"
                                onChange={this.typing}
                              />
                            }
                          </div> : null
                      }
                      <div className="y-input-holder">
                        <p>Upload Course Image</p>
                        {
                          imgurlLoader ? <Loader active /> : null
                        }
                        <input type="file" name="file" className="courseimage-uploadfile" accept="image/*" ref={this.uploadRefImg} onChange={this.onChangeHandlerImg} />
                        <div className={`courseimage-holder ${imgurl ? 'withimg' : ''}`} {...imageHoldeStyle}>
                          {
                            !imgurl ? <div className="click">
                              <Icon name="image" />
                              <p>Upload an image</p>
                              <span>Max Resolution 1080*368</span>
                              <Button type="button" onClick={this.triggerUploadImg} disabled={imgurlLoader}>
                                Browse Image
                      </Button>
                            </div> : null
                          }
                        </div>
                        <Button type="button" onClick={
                          this.removeImage
                        } >Remove Image</Button>
                      </div>
                      <div className="slide-nav">
                        <Button size="large" className="y-navbtn" type="button" onClick={() => this.prev('_', 3)} >
                          Previous
                        </Button>
                        {/* <Button size="large" className="y-nextbtn" type="button" onClick={this.publishCourse} disabled={imgurlLoader}>
                          Publish
                  </Button> */}
                      </div>
                      <div className="publishorsave">
                        <Button size="large" className="y-savebtn" type="button" onClick={this.saveCourse} disabled={imgurlLoader}>
                          Save
                  </Button>
                        <Button size="large" className="y-nextbtn" type="button" onClick={this.publishCourse} disabled={imgurlLoader}>
                          Publish
                  </Button>
                      </div>
                    </div>
                  </Slider>
                </Form>
              </div>
            </div>
        }
        <Footer
          auth={auth} />
        <VideoModal openModal={openModal}
          closeModalHandler={this.closeModalHandler}
          setVideo={this.setVideo} />
        <FileModal openModal={openModalFile}
          closeModalHandler={this.closeModalHandlerFile}
          selectFile={this.selectFile} />
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  auth: state.auth,
  course: state.course
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  uploadCourseImage, uploadCourseVideo,
  clearUploadVideo, clearUploadImage, addCourse,
  clearaddedCourse
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(CreateCourse)