import React, { Component } from "react"
import { Modal, Form, Button, Divider, Grid, Icon, Image, Dropdown, Rating, Progress, Label } from 'semantic-ui-react';
import moment from "moment"
const Coursecard = ({
    course, history, duration, percentWatched, completed, myCourses, showDeleteButton, deleteCourse
}) => {
    const cuttext = (text, maxlength) => {
        if (text && text.length > maxlength) {
            var newtext = text.slice(0, maxlength);
            newtext += "..."
            return newtext
        } else return text
    }

    return (
        <>
            <div className="x-coursecard" onClick={() => history.push('/courses/' + course.slug)}>
                <div className="bg" style={{ background: `url('${course.coverPhoto}')  no-repeat`, backgroundSize: "cover" }} ></div>
                <div className="x-content">
                    <div className="x-sub">{course.creatorId.forename} {course.creatorId.surname}</div>
                    <div className="x-title">{course.title}  {course.live && <Label color="red" as='a'>Live</Label>}</div>
                    <div className="x-desc">{cuttext(course.description, 35)}</div>
                </div>
                {myCourses && <div className="x-progress">
                    <Progress active percent={Math.floor(percentWatched)} />
                    <span>{Math.floor(percentWatched)} %</span>
                </div>}
                <div className="x-footnote">

                    <div className="x-rating">
                        <Rating maxRating={5} defaultRating={course.ratings} icon='star' size='small' /> <span>({course.totalReview})</span>
                    </div>
                    {!course.isMember && <div className="x-price">
                        {
                            !course.paidCourse ? <span>
                                Free
                        </span> : course.runningPromo ?
                                    <span className="discount"> ${course.discountType === 'useprice' ? course.promoprice : course.price - (course.promopercent / 100 * course.price)}</span> : <span>${course.price}</span>
                        }
                        {
                            course.runningPromo ? <span><s> ${course.price}</s></span> : null
                        }
                    </div>}
                </div>
            </div>
            {
                showDeleteButton &&
                <div className="delte-holder" onClick={()=>deleteCourse(course._id)}>
                    <Button type="button">Delete Course</Button>
                </div>
            }
        </>
    )
}

export default Coursecard