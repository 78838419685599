import React from 'react'
import { Tab, Button, Image, List } from 'semantic-ui-react'
import moment from "moment"
import Faq from './faq';
import Join from './join';
import Reviews from './reviews';

const panes = (props) => [
  {
    menuItem: 'Overview',
    render: () => <Tab.Pane attached={false}>
      <div className="x-alert">
        <div>
          <div className="x-title"> Teach the world online</div>
          <p> Create an online video course, reach students accross the globe</p>
        </div>
        <div>
          {props.live && <Join {...props} />}
          {/* <Button size="small"> Become a Mentor </Button> */}
        </div>
      </div>
      <div className="x-header">
        <br />
        <div className='x-about'>
          {/* <h4>{props.title}</h4> */}
          <h3>Course Description</h3>
          <h4>{props.description}</h4>
        </div>
      </div>
      <div className="x-course-targets">
        <h3>Target Students</h3>
        <List bulleted>
          {
            props.targetStudents.map((item) => (
              <List.Item key={item._id}>{item.text}</List.Item>
            ))
          }
        </List>
      </div>
      <div className="x-course-targets">
        <h3>Students Will Learn</h3>
        <List bulleted>
          {
            props.studentsWillLearn.map((item) => (
              <List.Item key={item._id}>{item.text}</List.Item>
            ))
          }
        </List>
      </div>
      <div className="coursefiles-holder">
        {
          props.courseFiles.length > 0 ?
            <>
              <h3>Course Files</h3>
              <div className="mainfile-holder">
                <List bulleted>
                  {
                    props.courseFiles.map((item) => (
                      <List.Item key={item._id}>
                        <div className="list-content">
                          <p>{item.name}</p>
                          <a href={item.url} download target="_blank">
                            <Button type="button">
                              Download
                      </Button>
                          </a>
                        </div>
                      </List.Item>
                    ))
                  }
                </List>
              </div>
            </>
            : null
        }

      </div>
      <div className="x-mentor">
        <br />
        <h3>Mentor</h3>
        <div className="x-avatar">
          <Image src={props.creatorId.photoURL} style={{ width: '40px', height: '40px' }} avatar />
          <div>
            {props.creatorId.description}
            <br />
            <h4 onClick={() => props.history.push(`/instructors/${props.creatorId.username}`)}>{props.creatorId.forename}  {props.creatorId.surname} </h4>
            <span>{props.creatorId.shortBio}</span>
            <div>
              <Button size="big" circular icon="twitter" onClick={() => window.open("https://twitter.com/" + props.creatorId.twitterLink || "masterclass", '_blank')} />
              <Button size="big" circular icon="instagram" onClick={() => window.open("https://instagram.com/" + props.creatorId.instagramLink || "masterclass", '_blank')} />
            </div>
          </div>
        </div>
      </div>
    </Tab.Pane>,
  },
  {
    menuItem: 'Q & A',
    render: () => <Tab.Pane attached={false}>
      <Faq {...props} />
    </Tab.Pane>,
  },

  {
    menuItem: 'Reviews',
    render: () => <Tab.Pane attached={false}>
      <div className="x-alert">
        <div>
          <div className="x-title"> Teach the world online</div>
          <p> Create an online video course, reach students accross the globe</p>
        </div>
        {/* <div>
          <Button size="small"> Become a Teacher </Button>
        </div> */}
      </div>
      <Reviews {...props} />

    </Tab.Pane>,
  }
]

const Infotab = (props) => (
  <div className="x-infotab">
    <Tab menu={{ secondary: true, pointing: true }} panes={panes(props)} />
  </div>
)

export default Infotab