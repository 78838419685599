export const categories = [
  {
    key: 'face-treatments', value: 'face-treatments', text: 'Face treatments',
    subcategories: [
      {
        key: 11,
        text: "Acne",
        value: "acne"
      },
      {
        key: 12,
        value: "botox",
        text: "Botox"
      },
      {
        key: 13,
        value: "facial",
        text: "Facial"
      },
      {
        key: 14,
        value: "microdermabrasion",
        text: "Microdermabrasion"
      },
      {
        key: 15,
        value: "general",
        text: "General"
      },
      {
        key: 16,
        text: "Peels",
        value: "peels"
      }
    ]
  },
  {
    key: 'hair-removal', value: 'hair-removal', text: 'Hair removal',
    subcategories: [
      {
        key: 15,
        value: "general",
        text: "General"
      },
      // {
      //   key: 22,
      //   text: "Sugaring",
      //   value: "sugaring"
      // },
      // {
      //   key: 23,
      //   text: "Threading",
      //   value: "threading"
      // },
      // {
      //   key: 24,
      //   text: "Shaving",
      //   value: "shaving"
      // },
      {
        key: 25,
        text: "Sugaring",
        value: "sugaring"
      },
      {
        key: 26,
        text: "Threading",
        value: "threading"
      },
      {
        key: 27,
        text: "Waxing",
        value: "waxing"
      }
    ]

  },
  {
    key: 'hair-salon', value: 'hair-salon', text: 'Hair salon',
    subcategories: [
      {
        key: 3123456,
        value: "natural-and-permed-hairstyles",
        text: "Natural and Permed hairstyles "
      },
      {
        key: 31234,
        value: "general",
        text: "General"
      },
      {
        key: 31,
        text: "Blowouts",
        value: "blowouts"
      },
      {
        key: 32,
        text: "Braids, Twists, Locs, Concrows",
        value: "braids-twists-locs-concrows"
      },
      {
        key: 3299999,
        text: "Hair extensions /Weave / Wigs/ Frontals",
        value: "hair-extensions-Weave-wigs-frontals"
      },
      {
        key: 33,
        text: "Color / Highlights / Lowlights etc.",
        value: "color-highlights-lowlights-etc."
      },
      {
        key: 34,
        text: "Haircut",
        value: "haircut"
      },
      {
        key: 36,
        text: "Kids hairstyles",
        value: "kids-hairstyles"
      },
      {
        key: 37,
        text: "Wedding Hairstyles",
        value: "wedding-hairstyles"
      },
      {
        key: 38,
        text: "Curl Sets",
        value: "curl-sets"
      },
      {
        key: 39,
        text: "Hair treatments",
        text: "hair-treatments"
      },
      {
        key: 311,
        text: "Women’s cuts",
        value: "womens-cuts"
      },
      {
        key: 312,
        text: "Updo",
        value: "updo"
      },
    ]
  },
  {
    key: 'makeup-lashes-brows', value: 'makeup-lashes-brows', text: 'Makeup / lashes / brows',
    subcategories: [
      {
        key: 41,
        text: "Everyday Makeup application",
        value: "everyday-makeup-application"
      },
      {
        key: 42,
        text: "Bridal makeup",
        value: "bridal-makeup"
      },
      {
        key: 46999,
        text: "Special occasions",
        value: "special-occasions"
      },
      {
        key: 43,
        text: "Eyelash extensions",
        value: "eyelash-extensions"
      },
      {
        key: 44,
        text: "Shoots",
        value: "shoots"
      },
      {
        key: 45,
        text: "Microblading",
        value: "microblading"
      },
      {
        key: 46,
        text: "Permanent / Semi-permanent",
        value: "permanent-semi-permanent"
      },
      {
        key: 47,
        text: "Soft glam",
        value: "soft-glam "
      },
      {
        key: 48,
        text: "Theatre makeup and Film and television ",
        value: "theatre-makeup-and-film-and-television "
      },
      // {
      //   key: 49,
      //   text: "Trial",
      //   value: "trial"
      // },
    ]
  },
  {
    key: 'nails', value: 'nails', text: 'Nails',
    subcategories: [
      {
        key: 61,
        text: "Acrylic",
        value: "acrylic"
      },
      {
        key: 62,
        text: "Gel",
        value: "gel"
      },
      {
        key: 63,
        text: "Manicure",
        value: "manicure"
      },
      {
        key: 64,
        text: "Pedicure",
        value: "pedicure"
      },
      {
        key: 64000999,
        text: "General",
        value: "general"
      }
    ]
  },
  // {
  //   key: 'tanning', value: 'tanning', text: 'Tanning',
  //   subcategories: [
  //     {
  //       key: 71,
  //       text: "Organic",
  //       value: "organic"
  //     },
  //     {
  //       key: 72,
  //       text: "Spray tan",
  //       value: "spray-tan"
  //     },
  //     {
  //       key: 73,
  //       text: "Sunless bed",
  //       value: "sunless-bed"
  //     }
  //   ]
  // },
  {
    key: 'beauty-and-business', value: 'beauty-and-business', text: 'Beauty and Business',
    subcategories: [
      {
        key: 7998,
        text: "Beauty and Business",
        value: "beauty-and-business"
      }
    ]
  },
  {
    key: 'barber-services', value: 'barber-services', text: 'Barber Services',
    subcategories: [
      {
        key: 7998007,
        text: "Barber Services",
        value: "barber-services"
      }
    ]
  },
  {
    key: 'other', value: 'other', text: 'other',
    subcategories: [
      {
        key: 997998007,
        text: "Other",
        value: "other"
      }
    ]
  }
]

export const languages = [
  { key: 'english', value: 'english', text: 'English' },
  { key: 'french', value: 'french', text: 'French' },
]

export const levels = [
  { key: 'beginner', value: 'beginner', text: 'Beginner' },
  { key: 'Intermediate', value: 'intermediate', text: 'Intermediate' },
  { key: 'expert', value: 'expert', text: 'Expert' },
]

export const currencies = [
  { key: 'usd', value: 'usd', text: 'USD' },
  // { key: 'gbp', value: 'gbp', text: 'GBP' },
]