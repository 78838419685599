import React, { Component } from "react"
import { Button, Icon } from "semantic-ui-react";

const Enroll = ({ history, auth }) =>
  auth.isAuthenticated ? null
    : (
      <div className="y-enroll">
        <h1 className="y-top-heading">Enroll and Start journey now</h1>
        {/* <p className="y-footnote ">Lorem ipsum gravida nibh vel velit auctor aliquetnean</p> */}
        <div className="btn-holder">
          <Button size="big" onClick={() => history.push("/signup")}>Enroll Now</Button>
        </div>
      </div>
    )


export default Enroll