import React, { Component, useEffect, useState } from "react"
import Navbar from "../../components/navbar";
import { Button, Icon, Container, Grid, GridColumn, GridRow, Pagination } from "semantic-ui-react";
import Searchbox from "../../components/courses/searchbox";
import Coursecard from "../../components/courses/coursecard";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses, fetchSearchCourses, resetCourseAction, clearCourse } from "../../utils/actions/course";
import Footer from "../../components/footer/footer";
import Spinner from "../../components/ui/spinner";
import Enroll from "../../components/footer/enroll";

const Search = ({
  history, match, location
}) => {
  const dispatch = useDispatch()
  const isSearchLoaded = useSelector(s => s.course.isSearchLoaded);
  const courses = useSelector(s => s.course.searchCourses);
  const totalResults = useSelector(s => s.course.totalSearchResults);
  const searchInstructors = useSelector(s => s.course.searchInstructors);
  const auth = useSelector(s => s.auth.auth);
  const [query, setQuery] = useState('')
  const [searchtype, setSearchtypeType] = useState('courses')
  const [category, setCategory] = useState('courses')
  const [limit, setLimit] = useState(10)
  const [pageNum, setPageNum] = useState(1)

  useEffect(() => {
      var searchParams = new URLSearchParams(window.location.search)
      var query = searchParams.get("query");
      var type = searchParams.get("type");
      var category = searchParams.get("category");
      setQuery(query.replace(/-/gi, ' '))
      setSearchtypeType(type || "courses")
      setCategory(category)
      dispatch(fetchSearchCourses({query, type, category, limit, pageNum}))

      return () =>{
        dispatch(clearCourse())
      }
  }, [])
  useEffect(() => {
    dispatch(resetCourseAction())
  }, [isSearchLoaded])
  useEffect(() => {
    dispatch(clearCourse())
    var searchParams = new URLSearchParams(window.location.search)
      var query = searchParams.get("query");
      var type = searchParams.get("type");
      var category = searchParams.get("category");
      setSearchtypeType(type || "courses")
      setQuery(query.replace(/-/gi, ' '))
      setCategory(category)
      dispatch(fetchSearchCourses({ query, type, category, limit, pageNum}))

  }, [location.search])

  const handlePagination = (page) => {
    setPageNum(page)
    var searchParams = new URLSearchParams(window.location.search)
    var type = searchParams.get("type");
    dispatch(fetchSearchCourses({query, type, category, limit, pageNum: page}))
}
  return (
    isSearchLoaded ?
      <div className="x-courses">
        <Navbar
          history={history}
          auth={auth}
        />

        <div className="x-main">
          <Container fluid>
            <h2> {totalResults} Search Results for "{query}"</h2>
            <br />
            <Searchbox
              history={history}
              type={searchtype}
              query={query}
              category={category}
            />


            {courses.length == 0 && searchInstructors.length == 0 && 
                <div style={{margin: '8em 1em', textAlign: "center"}}>
                  No search results
            </div>}
            <Grid >
            {searchtype == "instructors" && searchInstructors.map((instructor) => (
                    <Grid.Column key={instructor._id} computer="4" mobile="16" >
                      <div className="y-instr-card" key={instructor} onClick={() => history.push(`/instructors/${instructor.username}`)}>
                        <div className="img-holder">
                          <img src={instructor.photoURL} alt="" />
                        </div>
                        <Grid>
                          <Grid.Row columns="two">
                            <Grid.Column computer="13">
                              <h2 className="y-name">
                                {instructor.forename}  {instructor.surname}
                              </h2>
                              {/* <p className="y-desc">
                                {instructor.shortBio || "New member"}
                              </p> */}
                            </Grid.Column>
                            <Grid.Column computer="3">
                              <h2 className="y-rate">
                                {instructor.rating}.0
                          </h2>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                    </Grid.Column>
                  ))
                }
              <GridRow columns={window.innerWidth > 760 ?"3": "1"} >
            
                {searchtype == "courses" && courses.map((course) => (
                  <GridColumn   className="no-xspad">
                    <Coursecard
                      history={history}
                      course={course}
                    />
                  </GridColumn>

                ))}
              </GridRow>
              <GridRow textAlign="center">
                <GridColumn>
                  <Pagination
                    boundaryRange={0}
                    defaultActivePage={pageNum}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={Math.ceil(totalResults/limit)}
                    onPageChange={(e, data) => handlePagination(data.activePage)}
                  />
                </GridColumn>
              </GridRow>
            </Grid>
          </Container>
        </div>
        <Enroll history={history} auth={auth}/>
        <Footer
          auth={auth}
        />
      </div> :
      <Spinner />
  )
}

export default Search