
import React, { Component } from 'react'
import { StripeProvider, Elements } from "react-stripe-elements";
import Checkout from './form'
import { stripeSecretKey } from '../../utils/config';
class Billing extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <StripeProvider
        apiKey={stripeSecretKey}
      >
        <Elements>
          <Checkout
            location={this.props.location}
            history={this.props.history}
          />
        </Elements>
      </StripeProvider>
    )
  }
}

export default Billing