import React, { Component } from 'react'
import { Message, Grid, Icon, Button, Form, Select, Image, Step, Checkbox, Label } from 'semantic-ui-react';

export default class AddFaq extends Component {
  constructor(props) {
    super(props)
    this.state = {
      question: '',
      answer: '',
      error: false
    }
  }

  typing = (e) => {
    var name = e.target.name;
    this.setState({
      [name]: e.target.value
    })
  }

  // onSubmit = () => {
  //   const { question, answer } = this.state
  //   this.setState({
  //     error: false
  //   })
  //   if (!question.trim().length || !answer.trim().length) {
  //     this.setState({
  //       error: true
  //     })
  //   } else {
  //     this.props.addFaq({
  //       question,
  //       answer
  //     })
  //   }
  // }

  onRemove = () => {
    // this.props.closeAddFaq()
    this.props.removeFaq(this.props.index)
  }

  render() {
    const { error } = this.state
    const { faq } = this.props
    return (
      <div className="faq-holder" style={{ marginBottom: '1rem' }}>
        {/* {
          error ? <Message negative><p className="err-msg"><b>Please fill out all fields</b></p> </Message> : null
        } */}
        {
          faq ?
            <>
              <Form.Input fluid type="text"
                id="question" required name="question" onChange={(e) => this.props.faqTyping(e.target.value, 'question', faq)}
                placeholder="Question"
                value={faq.question}
              />
              <Form.TextArea required style={{ minHeight: 80 }} name="answer"
                placeholder="Answer"
                onChange={(e) => this.props.faqTyping(e.target.value, 'answer', this.props.faq)}
                value={faq.answer}
              />
            </>
            :
            <>
              <Form.Input fluid type="text"
                id="question" required name="question" onChange={(e) => this.props.faqTyping(e.target.value, 'question', faq)}
                placeholder="Question"
              />
              <Form.TextArea required style={{ minHeight: 80 }} name="answer"
                placeholder="Answer"
                onChange={(e) => this.props.faqTyping(e.target.value, 'answer', faq)}
              />
            </>
        }
        <div className="action">
          {/* <Button size="mini" type="button" className="add" onClick={this.onSubmit}>
            Ok
      </Button> */}
          <Button size="mini" type="button" className="cancel" onClick={this.onRemove}>
            Remove
      </Button>
        </div>
      </div>
    )
  }
}
