import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

export default class CheckForAuth extends Component {
    render(){
        var token = window.localStorage.getItem("token");

        return (
            token ? <Redirect to="/" />
            : 

            <Route 
                path={this.props.path}
                render={(props) => <this.props.component {...props}/>} 
            />
        )
    }
}
