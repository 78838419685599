import jwt from "jsonwebtoken"
import axios from "axios"
import { secretKey } from "../config";


export const setAuthorizationToken = (token) => {
    if (token) axios.defaults.headers.common["Authorization"] = `${token}`; else
        delete axios.defaults.headers.common["Authorization"]
}
export const setToken = (userData) => {
    var token = jwt.sign(JSON.stringify(userData), secretKey);
    window.localStorage.setItem("token", token);
}

export const clearToken = () => {
    window.localStorage.removeItem('token');
    window.localStorage.clear();
}
