import React, { Component } from 'react'
import { Accordion, Icon, Checkbox, Input } from 'semantic-ui-react'
import classnames from "classnames"
import moment from "moment"

export default class Lecturesaccordion extends Component {
 constructor(props){
   super(props);
   this.state = { 
     activeIndex: 0,
     loadMore: false,
     sections: [],
     activeLectureId: this.props.activeLectureId
    }
 }

  componentDidMount(){
    this.setState({sections: this.props.sections})
    }
    
  loaded = (e) =>{
    var { sections, courseId} = this.props
    var {activeLectureId} = this.state
      this.props.watchedCourses({
        lectureId: activeLectureId,
        courseId: courseId,
        watched: true
      })
      this.props.updateChecked(activeLectureId, true)
      var sect = sections.sort((a, b) => a.position - b.position)
      sect.map(s => {
        var totalLect = s.lectures.length
        var index = s.lectures.findIndex(i => i._id == activeLectureId)
        if((index >= 0 ) && (index < (totalLect-1))){
          this.props.upateActiveLecture(s.lectures[index+1])
        }
      })
  }
  componentWillReceiveProps(nextProps){
    this.setState({sections: nextProps.sections})
    if(nextProps.activeLectureId != this.state.activeLectureId){
    this.setState({activeLectureId: nextProps.activeLectureId, sections: nextProps.sections})
  }
}
  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  checkHours = (num) => {
    let hours = Math.floor(num / 3600);
    return hours
  }

  toggleLoadMore = () => {
    this.setState({
      loadMore: !this.state.loadMore
    })
  }

  check = (value, lectureId) => {
   this.props.watchedCourses({
     lectureId,
     courseId: this.props.courseId,
     watched: value.checked
   })
   if(value.checked == false)
   {
    this.props.updateChecked(lectureId, false)
   }else  this.props.updateChecked(lectureId, true)
  }
  render() {
    var video = document.getElementById("vid")
    if(video) video.onended = this.loaded
    const { activeIndex, loadMore, sections } = this.state
    let end = !loadMore ? 1 : sections.length
    return (
      <div className="x-course-accordion">
        <Accordion>
          {sections.sort((a, b) => a.position - b.position).slice(0, end).map((section, key) => (
            <div>
              <Accordion.Title
                active={activeIndex === key}
                index={key}
                className={classnames(activeIndex === key ? 'active' : null, 'x-title')}
                onClick={this.handleClick}
              >
                <div>
                  <span>{section.title}</span>
                  <div><Icon name="play circle" /> {section.lessons} video | {this.checkHours(section.duration) ? moment.utc(section.duration * 1000).format('hh:mm:ss') + ' hours' : moment.utc(section.duration * 1000).format('mm:ss') + ' mins'}</div>
                </div>
                <Icon size="small" name='chevron down' />
              </Accordion.Title>
              <Accordion.Content className="x-content" active={activeIndex === key}>
                {
                  section.lectures.sort((a, b) => a.position - b.position).map((lecture) => (
                    <div className="x-lecs" key={lecture._id}>
                      <div style={{position:"relative"}}>
                        <Checkbox name={lecture._id} className="x-checkbox" value={lecture._id} id={lecture._id} checked={lecture.watched} onChange={(e)=>this.check(e.target, lecture._id)} />
                      </div>
                      <div className="x-desc x-cursor" onClick={() => this.props.upateActiveLecture(lecture)}>
                        <div>{lecture.title}</div>
                        <div><Icon name="play circle"  />  {this.checkHours(lecture.duration) ? moment.utc(lecture.duration * 1000).format('hh:mm:ss') + ' hours' : moment.utc(lecture.duration * 1000).format('mm:ss') + ' mins'}</div>
                      </div>
                    </div>
                  ))
                }
                {/* <div className="x-lecs">
                  <div>
                    <Checkbox className="x-checkbox" />
                  </div>
                  <div className="x-desc">
                    <div>2. Introduction</div>
                    <p><small><Icon name="play circle" />  {lecture.duration}min</small></p>
                  </div>
                </div>

                <div className="x-lecs">
                  <div>
                    <Checkbox className="x-checkbox" />
                  </div>
                  <div className="x-desc">
                    <div>2. Introduction</div>
                    <p><small><Icon name="play circle" />  20min</small></p>
                  </div>
                </div> */}
              </Accordion.Content>
            </div>
          ))}
        </Accordion>
        {
          this.props.sections.length >= 1 ?
            <div className="x-load-more" onClick={this.toggleLoadMore}>
              {
                loadMore ? 'Show less lesctures' : ' Load more lectures'
              }
            </div>
            : null
        }
        <style>
          {`
          input[type="checkbox"]{
            background: red
          }
            input[type="checkbox"]:before, input[type="checkbox"]:after { 
              background: $primary !important;
              color: $white-color !important;
            }

          `}
        </style>
      </div>
    )
  }
}
