import React from 'react'
import SocialLogin from 'react-social-login'
import { Button, Icon, Divider } from 'semantic-ui-react';
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Socialbutton from './socialbutton';
import { signin } from "../../utils/actions/auth"
import jwt from "jsonwebtoken"
import { secretKey } from '../../utils/config';
class Slogin extends React.Component {
     handleSocialLogin = (user) => {
        const {firstName, lastName, email, profilePicURL} = user._profile
        var token = jwt.sign({
          email,
          forename: firstName,
          surname: lastName,
          photoURL: profilePicURL,
          provider: user._provider
        }, secretKey)
        this.props.signin({token, provider: user._provider})
      }
       
    handleSocialLoginFailure = (err) => {
    console.error(err)
    }


    render() {
        return (
          <div >
          <Divider horizontal style={{marginTop: '25px', marginBottom: "0px", fontSize: "0.9em"}}>Or</Divider>
          <div className="x-socialFlex">
          <div>
          <Socialbutton
            provider='facebook'
            appId='265990508019533'
            type="facebook"
            onLoginSuccess={this.handleSocialLogin}
            onLoginFailure={this.handleSocialLoginFailure}
          >
            Login with Facebook
          </Socialbutton>
          </div>
          <div>
          <Socialbutton
            provider='google'
            type="google"
            appId='342176351621-17lvfss3k894bq34elp9e015r0co9v72.apps.googleusercontent.com'
            onLoginSuccess={this.handleSocialLogin}
            onLoginFailure={this.handleSocialLoginFailure}
          >
            Login with Google
          </Socialbutton>
          </div>
          </div>
        </div>
        );
    }
}

const mapStateToProps = ((state) => {
  return {
    auth: state.auth
  }
})
const matchDispatchToProps = (dispatch) => bindActionCreators({
  signin
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Slogin) 