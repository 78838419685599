import React from 'react'
import SocialLogin from 'react-social-login'
import { Button, Icon } from 'semantic-ui-react';
 
class SocialButton extends React.Component {
    click = (e) =>{
        e.preventDefault();
        this.props.triggerLogin()
    }
    render() {
        return (
            <Button fluid className={`${this.props.type} passport`} onClick={this.click} {...this.props}>
              <Icon name={this.props.type} />{ this.props.children }
            </Button>
        );
    }
}
 
export default SocialLogin(SocialButton);