import React, { Component } from "react"
import { Input, Form, Grid, Label, Button, Checkbox, Radio } from 'semantic-ui-react'
import { Link } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import $ from 'jquery'
import { updateUser, requestResetAuth } from "../../utils/actions/auth"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import Slider from "react-slick";
import jwt from "jsonwebtoken"

class Getstarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programm: '',
      institute: '',
      programCompleted: '',
      error: "",
      certification: [{id: 1, institute: "", title: "", dateCompleted: ""}],
      experience: [{id: 1, title: "", location: ""}],
      isCompleted: true,
      loading: false,
      step: 1,
      tabs: [
        { name: 'Education', active: true, completed: false },
        { name: 'Certification', active: false, completed: false },
        { name: 'Experience', active: false, completed: false },
      ],
    }
    this.instrustorSlider = React.createRef()
  }
  componentWillMount() {
    var token = window.localStorage.getItem("token");
    if(token) {
        var data = jwt.decode(token);
        this.setState({data})
        if(data.isCompleted || !data.instructor) this.props.history.push("/")
    }
  }

  componentWillReceiveProps(nextProps) {
    var { auth } = nextProps
    var { updateSuccess, isUpdatingUser, updateError } = auth
    if (updateError) {
      this.setState({ error: updateError, loading: false })
      this.props.requestResetAuth()
    }
    if (updateSuccess) {
      this.setState({ success: updateSuccess, loading: false })
      this.props.requestResetAuth()
      window.location.assign("/dashboard")
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({loading: true,error: ""})
    var { experience, certification} = this.state

    if( !experience || experience[0].title == "" ){
      return this.setState({error: "Experience is required", loading: false})
    } else   {
      if(certification && certification[0].title == "")
      certification = []
      this.props.updateUser({...this.state, certification})
    }
  }

  typing = (e) => {
    var name = e.target.name;

    this.setState({
      [name]: e.target.value
    })
  }

  editMore= (text, name, pos, target) => {
    var {experience, certification} = this.state;
    if(target == 'experience'){
      experience[pos][name] = text
    }
    if(target == 'certification'){
      certification[pos][name] = text
    }
    this.setState({experience, certification})
  }
  addMore= (e, name) => {
    e.preventDefault();
    var {experience, certification} = this.state;
    if(name == 'experience'){
    experience.push({id: Math.floor((Math.random * 100000) +1), title: "", location: ""}) 
    }
    if(name == 'certification'){
    certification.push({id: Math.floor((Math.random * 100000) +1), institute: "", title: "", dateCompleted: ""}) 
    }
    this.setState({experience, certification})
  }
  next = (e) => {
 
    var { programCompleted, programm, institute} = this.state;
    // if(programCompleted && programCompleted && institute)
    this.instrustorSlider.current.slickNext()
    // else return;
    var { tabs, step } = this.state
    let i = 0
    while (i < step+1) {
      tabs[i].active = true
      tabs[i].completed = true
      i++
    }
    this.setState({
      tabs
    })
  }
  render() {
    var { error, loading, experience, certification, programCompleted, programm, institute,  tabs} = this.state
    const sliderSettings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplaySpeed: 2000,
      cssEase: "linear",
      autoplay: false,
      draggable: false,
    }
    return (
      <div className="x-signin x-getstarted">
        <Navbar history={this.props.history}
          auth={this.props.auth.auth} />
        <div className="x-main">
          <div className="x-body">
            <div className="x-heading">
              <h2>Get Started</h2>
            </div>
            <ul className="progressbar">
                {
                  tabs.map((tab, index) => (
                    <li disabled key={index} onClick={() => this.instrustorSlider.current.slickGoTo(index)} className={`${tab.active ? 'active' : ''} ${tab.completed ? 'completed' : ''}`}>{tab.name}</li>
                  ))
                }
              </ul>
            <div className="x-card">
              {/* <Form onSubmit={e => e.preventDefault()} loading={loading}> */}
              <Slider {...sliderSettings} ref={this.instrustorSlider}>
                  <div className="x-slide">
                    <h3>Education (optional)</h3>
                    {error && <div className="x-error">
                      {error}
                    </div>}

                    <div className="xlabel"> <p>Programm</p> </div>
                    <Form.Input fluid type="text" placeholder="e.g Associate Degree in Costmetology"
                       name="programm" onChange={this.typing}
                    />
                    <div className="xlabel"><p>Institute</p></div>
                    <Form.Input placeholder="Enter your Institute" fluid type="text"
                      onChange={this.typing} name="institute"
                     
                    />
                    
                    <div className="xlabel"> <p> Programme Completed</p> </div>
                    <Form.Input fluid type="date" placeholder="Program Completed"
                       name="programCompleted" onChange={this.typing}
                    />

                    <Button onClick={this.next} size="large" fluid color="blue">Next</Button>
                  </div>

                  <div className="x-slide">
                  {error && <div className="x-error">
                      {error}
                    </div>}
                    <div className="x-header-flex">
                    <h3>Certification (optional)</h3>
                    <Button size="tiny" onClick={(e) => this.addMore(e,'certification')}> Add More </Button>
                    </div>
                    {certification.map((exp, key) => (
                      <div key={key}>
                          <div className="xlabel"> <p>Certification Title</p> </div>
                          <Form.Input fluid type="text" placeholder="e.g Associate's Degree in Cosmetology"
                            name="title"  onChange={(e) => this.editMore(e.target.value, e.target.name, key, 'certification')}
                          />
                          <div className="xlabel"><p>Institue</p></div>
                          <Form.Input placeholder="Enter Institute Name" fluid type="text"
                            onChange={(e) => this.editMore(e.target.value, e.target.name, key, 'certification')} name="institute"
                          />

                          <div className="xlabel"> <p> Completed</p> </div>
                          <Form.Input fluid type="date" placeholder="Program Completed"
                             name="dateCompleted"  onChange={(e) => this.editMore(e.target.value, e.target.name, key, 'certification')}
                          />
                      </div>
                    ))}
                    <Button onClick={this.next} size="large" fluid color="blue">Next</Button>

                  </div>
                  <div className="x-slide">
                    {error && <div className="x-error">
                      {error}
                    </div>}
                    <div className="x-header-flex">
                    <h3>Experience (required)</h3>
                    <Button size="tiny" onClick={(e) => this.addMore(e,'experience')}> Add More </Button>
                    </div>
                    {experience.map((exp, key) => (
                      <div key={key} >
                          <div className="xlabel"> <p>Career Title</p> </div>
                          <Form.Input fluid type="text" placeholder="e.g Make Up Artist"
                            name="title"  onChange={(e) => this.editMore(e.target.value, e.target.name, key, 'experience')}
                          />
                          <div className="xlabel"><p>Location</p></div>
                          <Form.Input placeholder="E.g New York, USA" fluid type="text"
                            onChange={(e) => this.editMore(e.target.value, e.target.name, key, 'experience')} name="location"
                          />
                      </div>
                    ))}
                    <Button loading={loading} onClick={this.onSubmit} size="large" fluid color="blue">Lets Start</Button>
                  </div>
                </Slider>

              {/* </Form> */}
            </div>
          </div>

        </div>
        <Footer {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = ((state) => {
  return {
    auth: state.auth
  }
})
const matchDispatchToProps = (dispatch) => bindActionCreators({
  updateUser,
  requestResetAuth
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Getstarted) 