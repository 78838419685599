import React, { Component } from "react"
import { Input, Form, Grid, Label, Button, Checkbox, Radio } from 'semantic-ui-react'
import { Link } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import $ from 'jquery'
import { signin, requestResetAuth } from "../../utils/actions/auth"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import Slogin from "../../components/ui/sociallogin";
import baseUrl, { secretKey } from "../../utils/config";
import Axios from "axios";
import jwt from "jsonwebtoken"
class Forgotpassword extends Component {
  constructor(props){
    super(props)
    this.state={
      email:"",
      success:"",
      isLoading:false,
      error:"",
    }
    this.typing = this.typing.bind(this)
    this.submit = this.submit.bind(this)
  }
  submit(e) {
    e.preventDefault();
    var {email} = this.state;
    
    var url = 'https://masterclassofbeauty.com/reset-password/'+jwt.sign({email},secretKey)
    this.setState({error:"",success:"",isLoading:true})
    Axios.post(`${baseUrl}/auth/reset`, {url,email}).then((res) => {
        if (res.data.success) {
            this.setState({ success: res.data.success ,isLoading:false,email:""})
        } else {
            this.setState({ error: res.data.error ,isLoading:false})
        }
    });
  }
  typing(e) {
    this.setState({
        [e.target.name]: e.target.value
    })
  }
  render() {
    var { error, loading, instructor} = this.state
    return (
      <div className="x-signin">
        <Navbar {...this.props} />
        <div className="x-main">
          <div className="x-body">
            <div className="x-heading">
              <h2>Forgot password</h2>
            </div>

            <p className="x-bottom">
            Please enter your email to receive a confirmation
                </p>
            {error && <div className="x-error">
              {error}
            </div>}
            <div className="x-card">
              <Form  onSubmit={this.submit} loading={this.state.isLoading} error success>
             
                <div className="xlabel"><p>Email address</p></div>
                <Form.Input placeholder="johndoe@gmail.com" fluid type="email"
                   required onChange={this.typing} error={this.state.error?true:false}
                  name="email"
                  id="email" required

                />

                <div className="x-forgot">
                <div >{this.state.success?this.state.success:null}</div>
                <div>{this.state.error?this.state.error:null}</div>
                </div>
                <Button type="submit" size="large" fluid color="blue">Submit</Button>
              </Form>
            </div>
          </div>

        </div>
        <Footer {...this.props} />
      </div>
    )
  }
}

const mapStateToProps = ((state) => {
  return {
    auth: state.auth
  }
})
const matchDispatchToProps = (dispatch) => bindActionCreators({
  signin,
  requestResetAuth
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Forgotpassword) 