
import React,{Component} from "react"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
 const Spinner = () => {
        return(
            <div className="x-spinner">
                 <Loader
                        type="ThreeDots"
                        color="#9d525d"
                        height={100}
                        width={100}
                        // timeout={3000} //3 secs
                    />
            </div>
        )
    }

export default Spinner

