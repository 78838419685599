import * as CONSTANT from "../constant/auth";

const initialState = {
  auth: {
    user: {},
    isAuthenticated: false,
  },
  isSigningUp: false,
  isLoggingIn: false,
  loginError: null,
  signupError: null,
  loginSuccess: false,
  signupSuccess: false,
  isUpdatingUser: false,
  updateSuccess: null,
  updateError: null,
  updateImageSuccess: null
}
export default function auth(state = initialState, action) {
  switch (action.type) {
    case CONSTANT.PERSIST_USER_DATA:
      return {
        ...state,
        auth: {
          isAuthenticated: true,
          user: action.payload
        }
      }
    case CONSTANT.LOGOUT_REQUEST:
      return {
        ...state,
        auth: {
          user: {},
          isAuthenticated: false
        }
      };
    case CONSTANT.LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: null,
      };
    case CONSTANT.LOGIN_SUCCESS:
      return {
        ...state,
        auth: {
          user: action.payload,
          isAuthenticated: true,
        },
        isLoggingIn: false,
        loginSuccess: true,
      };
    case CONSTANT.LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        loginError: action.payload,
      };
    case CONSTANT.SIGNUP_REQUEST:
      return {
        ...state,
        isSigningUp: true,
        signupError: null,
      };
    case CONSTANT.SIGNUP_SUCCESS:
      return {
        ...state,
        auth: {
          user: action.payload,
          isAuthenticated: true,
        },
        isSigningUp: false,
        signupSuccess: true
      };
    case CONSTANT.SIGNUP_FAILURE:
      return {
        ...state,
        isSigningUp: false,
        signupError: action.payload,
      };
    case CONSTANT.RESET_PRE_AUTH_STATE:
      return {
        ...state,
        isSigningUp: false,
        isLoggingIn: false,
        loginError: null,
        signupError: null,
        loginSuccess: false,
        signupSuccess: false,
        isUpdatingUser: false,
        updateSuccess: null,
        updateError: null,
      }
    case CONSTANT.UPDATE_REQUEST:
      return {
        ...state,
        isUpdatingUser: true,
        updateSuccess: null,
        updateError: null
      };
    case CONSTANT.UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingUser: false,
        updateSuccess: true,
      };
    case CONSTANT.UPDATE_FAILURE:
      return {
        ...state,
        isUpdatingUser: false,
        updateError: action.payload,
      };
    case CONSTANT.UPDATE_IMAGE_SUCCESS:
      return {
        ...state,
        isUpdatingUser: false,
        updateImageSuccess: action.payload,
      };
    default:
      return state;
  }
}