
export const FETCH_INSTRUCTORS = "FETCH_INSTRUCTORS";
export const FETCH_MY_STUDENTS = "FETCH_MY_STUDENTS";
export const FETCH_COURSES = "FETCH_COURSES";
export const FETCH_SEARCH_COURSES = "FETCH_SEARCH_COURSES";
export const FETCH_SEARCH_INSTRUCTORS = "FETCH_SEARCH_INSTRUCTORS";

export const FETCH_COURSE = "FETCH_COURSE";
export const FETCH_MY_COURSES = "FETCH_MY_COURSES";
export const FETCH_UPLOADED_COURSES = "FETCH_UPLOADED_COURSES";
export const COURSE_REQUESTING = "COURSE_REQUESTING";
export const RESET_COURSE_ACTION = "RESET_COURSE_ACTION";
export const CLEAR_COURSE_DATA = "CLEAR_COURSE_DATA";

export const UPLOAD_COURSEIMAGE_REQUEST = 'UPLOAD_COURSEIMAGE_REQUEST'
export const UPLOAD_COURSEIMAGE_SUCCESS = '.UPLOAD_COURSEIMAGE_SUCCESS'
export const UPLOAD_COURSEIMAGE_ERROR = 'UPLOAD_COURSEIMAGE_ERROR'

export const UPLOAD_COURSEVIDEO_REQUEST = 'UPLOAD_COURSEVIDEO_REQUEST'
export const UPLOAD_COURSEVIDEO_SUCCESS = '.UPLOAD_COURSEVIDEO_SUCCESS'
export const UPLOAD_COURSEVIDEO_ERROR = 'UPLOAD_COURSEVIDEO_ERROR'

export const CLEAR_UPLOAD_COURSEIMAGE = 'CLEAR_UPLOAD_COURSEIMAGE'
export const CLEAR_UPLOAD_COURSEVIDEO = 'CLEAR_UPLOAD_COURSEVIDEO'

export const UPDATE_UPLOADED_VIDEOS = 'UPDATE_UPLOADED_VIDEOS'

export const REQUEST_ADDCOURSE = 'REQUEST_ADDCOURSE'
export const ADDCOURSE_SUCCESS = 'ADDCOURSE_SUCCESS'
export const ADDCOURSE_ERROR = 'ADDCOURSE_ERROR'
export const CLEAR_ADDEDCOURSE = 'CLEAR_ADDEDCOURSE'

export const REQUEST_EDITCOURSE = 'REQUEST_EDITCOURSE'
export const EDITCOURSE_SUCCESS = 'EDITCOURSE_SUCCESS'
export const EDITCOURSE_ERROR = 'EDITCOURSE_ERROR'
export const CLEAR_EDITEDCOURSE = 'CLEAR_EDITEDCOURSE'

export const REQUEST_ADDCURRICULUM = 'REQUEST_ADDCURRICULUM'
export const ADDCURRICULUM_SUCCESS = 'ADDCURRICULUM_SUCCESS'
export const ADDCURRICULUM_ERROR = 'ADDCURRICULUM_ERROR'
export const CLEAR_ADDCURRICULUM = 'CLEAR_ADDCURRICULUM'
export const CHARGED_SUCCESSFULLY = 'CHARGED_SUCCESSFULLY'
export const CHARGED_FAILED = 'CHARGED_FAILED'
export const RESET_PAYMENT = 'RESET_PAYMENT'

export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS'

export const GETINSTRUCTOR_REQUEST = 'GETINSTRUCTOR_REQUEST'
export const GETINSTRUCTOR_SUCCESS = 'GETINSTRUCTOR_SUCCESS'
export const GETINSTRUCTOR_ERROR = 'GETINSTRUCTOR_ERROR'
export const CLEAR_GETINSTRUCTOR = 'CLEAR_GETINSTRUCTOR'

export const SENDCONTACT_REQUEST = 'SENDCONTACT_REQUEST'
export const SENDCONTACT_SUCCESS = 'SENDCONTACT_SUCCESS'
export const SENDCONTACT_ERROR = 'SENDCONTACT_ERROR'
export const CLEAR_SENDCONTACT = 'CLEAR_SENDCONTACT'

export const GETUSERS_REQUEST = 'GETUSERS_REQUEST'
export const GETUSERS_SUCCESS = 'GETUSERS_SUCCESS'
export const GETUSERS_ERROR = 'GETUSERS_ERROR'
export const CLEAR_GETUSERS = 'CLEAR_GETUSERS'


export const GETCHATS_REQUEST = 'GETCHATS_REQUEST'
export const GETCHATS_SUCCESS = 'GETCHATS_SUCCESS'
export const GETCHATS_ERROR = 'GETCHATS_ERROR'
export const CLEAR_GETCHATS =  'CLEAR_GETCHATS'
export const GETCHATS_UPDATE = 'GETCHATS_UPDATE'


export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST'
export const UPLOAD_FILE_SUCCESS = '.UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR'
export const CLEAR_FILE_UPLOAD = 'CLEAR_FILE_UPLOAD'
export const UPDATE_UPLOADED_FILES = 'UPDATE_UPLOADED_FILES'

export const UPDATE_APPSETTINGS = 'UPDATE_APPSETTINGS'

export const REQUEST_DELETE_COURSE = 'REQUEST_DELETE_COURSE'
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS'
export const DELETE_COURSE_ERROR = 'DELETE_COURSE_ERROR'
export const CLEAR_DELETE_COURSE= 'CLEAR_DELETE_COURSE'

export const TRIGGER_NEWMESSAGE= 'TRIGGER_NEWMESSAGE'
export const TRIGGER_NEWMESSAGE_OFF= 'TRIGGER_NEWMESSAGE_OFF'

export const RESETAFTERUPLOAD_COURSEVIDEO = "RESETAFTERUPLOAD_COURSEVIDEO"