import React, { Component } from 'react'
import { Message, Grid, Icon, Button, Form, Select, Image, Step, Checkbox, Label, Loader, Dropdown, Header, Modal, Dimmer } from 'semantic-ui-react';
import { bindActionCreators } from "redux"
import { connect } from 'react-redux'
import moment from 'moment'
import { uploadCourseImage, uploadCourseVideo, clearUploadImage, clearUploadVideo, getUploadedVideos, cancelCourseVideo, requestClearUploadVideo } from "../../utils/actions/course";
import ReactDropzone from 'react-dropzone';
import $ from 'jquery'
import S3 from 'react-aws-s3';
const config = {
  bucketName: 'masterclassofbeauty',
  dirName: 'videos', /* optional */
  region: 'ca-central-1',
  accessKeyId: "AKIAI3MCFDALOLHY44YA",
  secretAccessKey: "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",
  s3Url: 'https://masterclassofbeauty.s3.amazonaws.com', /* optional */
}
const ReactS3Client = new S3(config);
class VideoModal extends Component {
  constructor(props) {
    super(props)
    this.uploadRefVid = React.createRef()
    this.state = {
      files: [],
      videourlLoader: false,
      openModal: false,
      success: null,
      error: null,
      loader: false
    }
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview))
  }

  componentDidMount() {
    // this.props.getUploadedVideos()
  }

  componentWillReceiveProps(nextProps) {
    const { openModal, course } = nextProps
    const { isUploadCourseVideoLoading, uploadCourseVideoSuccess, uploadCourseVideoError, uploadedVideos } = course
    this.setState({
      openModal,
      videourlLoader: isUploadCourseVideoLoading
    })
    if (uploadCourseVideoError) {
      this.setState({
        error: 'Something went wrong'
      })
      // this.uploadRefVid.current.value = ''
    }
    if (uploadCourseVideoSuccess) {
      // this.props.getUploadedVideos()
      this.setState({
        success: 'Video uploaded successfully'
      })
      // this.uploadRefVid.current.value = ''
      this.props.clearUploadVideo()
    }
    if (uploadedVideos.length === 1) {
      // let vid = uploadedVideos[0]
      // this.props.setVideo(vid.videoUrl, vid.duration)
      // this.closeModal()
    }

  }

  componentDidUpdate(prevProps) {
    if (prevProps.course.uploadCourseVideo !== this.props.course.uploadCourseVideo) {
      if (this.props.course.uploadCourseVideo !== null) {
        let vid = this.props.course.uploadCourseVideo
        this.props.setVideo(vid.videoUrl.videoUrl, vid.videoUrl.duration)
        this.closeModal()
        this.props.requestClearUploadVideo()
      }
    }
  }

  dismissError = () => {
    this.setState({
      error: null
    })
  }

  dismissSuccess = () => {
    this.setState({
      success: null
    })
  }

  triggerUploadVid = () => {
    this.uploadRefVid.current.click()
  }

  onChangeHandlerVid = (e) => {
    // console.log(e.target.files[0])
    this.setState({
      videourlLoader: true,
      error: null
    })
    var file = e.target.files[0];
    var size = (file.size / (1024 * 1024)).toFixed(2);
    if (size > 1536) {
      this.setState({
        error: 'Maximun video size is 1.5gb'
      })
    } else {
      var reader = new FileReader();
      reader.onload = (e) => {
        var videoElement = document.createElement('video');
        videoElement.src = e.target.result;
        var timer = setInterval(() => {
          if (videoElement.readyState === 4) {
            if (videoElement.duration) {
              // console.log(videoElement.duration)
              this.props.uploadCourseVideo(file, videoElement.duration)
            }
            clearInterval(timer);
          }
        }, 500)
      }
      reader.readAsDataURL(file);
    }
  }

  closeModal = () => {
    this.setState({
      openModal: false,
      success: null,
      error: null
    })
    this.props.closeModalHandler()
  }

  stopUpload = () => {
    // this.uploadRefVid.current.value = ''
    this.props.cancelCourseVideo()
    this.props.clearUploadVideo()
  }

  onDrop = (files) => {
    // POST to a test endpoint for demo purposes
    this.setState({loader: true})
    files.forEach(file => {
      var reader = new FileReader();
      reader.onload = (e) => {
        var videoElement = document.createElement('video');
        videoElement.src = e.target.result;
        videoElement.load();
        $(videoElement).on("loadedmetadata", () => {
         
           
          ReactS3Client
          .uploadFile(file)
          .then(data => {
            this.setState({loader: false})
            this.props.uploadCourseVideo(data.location, videoElement.duration)
          })
          .catch(err =>  {
            this.setState({
              error: 'Something went wrong'
            })
            this.setState({loader: false})
          })
          // this.props.uploadCourseVideo(file, videoElement.duration)

        })
        // var timer = setInterval(() => {
        //   console.log(videoElement.readyState )
        //   if (videoElement.readyState === 4) {
        //     if (videoElement.duration) {
        //       console.log(videoElement.duration)
        //       this.props.uploadCourseVideo(file, videoElement.duration)
        //     }
        //     clearInterval(timer);
        //   }
        // }, 500)
      }
      reader.readAsDataURL(file);
      // this.props.uploadCourseVideo(file)
    })
  }

  render() {
    const { openModal, videourlLoader, error, success } = this.state
    const { uploadCourseVideo } = this.props.course
    return (
      <div className="">
        <Modal open={openModal} onClose={this.closeModal} className="y-video-modal" centered={false}>
          <Modal.Header className="y-modal-header">
            <div className="modal-header">
              {/* <form ref={this.formRef}>
                <input type="file" name="file" className="video-uploadfile" accept="video/*" ref={this.uploadRefVid} onChange={this.onChangeHandlerVid} />
              </form>
              <Button type="button" onClick={this.triggerUploadVid} disabled={videourlLoader}
                className="primary-btn"
              >
                Upload Video
                </Button> */}
              <ReactDropzone
                multiple={false}
                accept="video/mp4"
                onDrop={this.onDrop}
                name="image"
              >
                {({ getRootProps, getInputProps }) => (
                  <span {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button type="button"
                    loading={this.state.loader}
                      // onClick={this.triggerUploadVid} disabled={videourlLoader}
                      className="primary-btn"
                    >
                      Upload Video
                </Button>
                  </span>
                )}
              </ReactDropzone>
            </div>
          </Modal.Header>
          <Modal.Content>
            <div className="y-video-holder">
              {
                videourlLoader ? <Dimmer active inverted>
                  <Loader inverted>
                    Uploading
                    <br />
                    <Button className="primary-btn" onClick={() => this.stopUpload()}>Stop</Button>
                  </Loader>
                </Dimmer> : null
              }
              {
                error ? <Message negative className="y-feedback"
                  onDismiss={this.dismissError}
                >
                  <p>{error}</p>
                </Message> : null
              }
              {
                success ? <Message positive className="y-feedback"
                  onDismiss={this.dismissSuccess}
                >
                  <p>{success}</p>
                </Message> : null
              }
              <Grid>
                <Grid.Row columns="3">
                  {/* {
                    uploadedVideos.map((vid) => ( */}
                  {uploadCourseVideo &&
                    <Grid.Column mobile="16" key={uploadCourseVideo._id} onClick={() => this.props.setVideo(uploadCourseVideo.videoUrl, uploadCourseVideo.duration)}>
                      <div className="vidoe-item">
                        <Icon name="video play" />
                        <div className="video-details">
                          <p>{uploadCourseVideo.title}</p>
                          <p>{moment(uploadCourseVideo.dateCreated).fromNow()}</p>
                        </div>
                      </div>
                    </Grid.Column>
                  }
                  {/* <Grid.Column  mobile="16"  key={uploadCourseVideo._id} onClick={() => this.props.setVideo(uploadCourseVideo.videoUrl, uploadCourseVideo.duration)}>
                        <div className="vidoe-item">
                          <Icon name="video play" />
                          <div className="video-details">
                            <p>{uploadCourseVideo.title}</p>
                            <p>{moment(uploadCourseVideo.dateCreated).fromNow()}</p>
                          </div>
                        </div>
                      </Grid.Column> */}
                  {/* ))
                  } */}
                </Grid.Row>
              </Grid>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  course: state.course
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  uploadCourseImage, uploadCourseVideo,
  clearUploadVideo, clearUploadImage, getUploadedVideos, cancelCourseVideo,
  requestClearUploadVideo
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(VideoModal)