import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import { Message, Grid, Icon, Button, Form, Select, Image, Popup } from 'semantic-ui-react';
import Spinner from "../../components/ui/spinner";
import moment from 'moment'
import { updateUser, updateDp } from "../../utils/actions/auth"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { domain } from '../../utils/config'

export class Profile extends Component {
  constructor(props) {
    super(props)
    this.uploadRef = React.createRef()
    this.state = {
      genders: [
        { key: 'm', value: 'male', text: 'Male' },
        { key: 'fm', value: 'female', text: 'Female' },
      ],
      forename: '',
      surname: '',
      email: '',
      phone: '',
      dob: '',
      gender: '',
      loading: false,
      error: null,
      photoURL: '',
      success: null,
      instagramLink: '',
      twitterLink: '',
      shortBio: '',
      description: '',
      instructor: false,
      copied: false
    }
  }

  componentWillMount() {
    const { forename, surname, email, phone, dob, gender, photoURL, instagramLink, twitterLink, shortBio, description, instructor, username } = this.props.auth.auth.user
    this.setState({
      forename,
      surname,
      email,
      phone,
      dob,
      gender,
      photoURL,
      instagramLink,
      twitterLink,
      shortBio,
      description,
      instructor,
      username
    })
  }

  componentWillReceiveProps(nextProps) {
    const { auth } = nextProps
    const { isUpdatingUser, updateSuccess, updateError, updateImageSuccess } = auth
    this.setState({
      loading: isUpdatingUser
    })
    if (updateSuccess) {
      this.setState({
        success: 'User profile update was successfull'
      })
      window.location.reload();
    }
    if (updateError) {
      this.setState({
        error: updateError
      })
    }
    if (updateImageSuccess) {
      this.setState({
        photoURL: updateImageSuccess
      })
    }
  }

  typing = (e) => {
    var name = e.target.name;
    this.setState({
      [name]: e.target.value
    })
  }

  handleChange = (e, { value }) => {
    this.setState({ gender: value })
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      error: null,
      success: null
    })
    const { user } = this.props.auth.auth
    const { forename, surname, email, phone, dob, gender, photoURL, description, shortBio, instagramLink, twitterLink, instructor } = this.state
    if (!gender) {
      this.setState({
        error: 'Gender field is required'
      })
    } else {
      let info = {
        uid: user.uid,
        forename: forename || user.forename,
        surname: surname || user.surname,
        email: email || user.email,
        phone: phone || user.phone,
        dob: dob || user.dob,
        gender: gender || user.gender,
        photoURL: photoURL || user.photoURL,
        description: description || user.description,
        shortBio: shortBio || user.shortBio,
        instagramLink: instagramLink || user.instagramLink,
        twitterLink: twitterLink || user.twitterLink
      }
      this.props.updateUser(info)
    }
  }

  triggerUpload = () => {
    this.uploadRef.current.click()
  }

  onChangeHandler = (e) => {
    this.props.updateDp(e.target.files[0])
  }

  dismissError = () => {
    this.setState({
      error: null
    })
  }

  dismissSuccess = () => {
    this.setState({
      success: null
    })
  }

  render() {
    const { loading, genders, forename, surname, email, phone, dob, gender, photoURL, error, success, description, shortBio, instagramLink, twitterLink, instructor, username, copied } = this.state
    const { history, auth } = this.props

    return (
      <div className="y-profile">
        <Navbar history={history}
          auth={auth.auth} />
        <div className="y-main">
          <h1 className="y-top-title">Profile</h1>
          <div className="y-form-holder">
            <Grid>
              <Grid.Row textAlign="center">
                <Grid.Column computer="16">
                  <div className="img-holder" style={{ backgroundImage: `url(${photoURL})` }}>
                    {/* <img src={photoURL} alt="" /> */}
                  </div>
                  <input type="file" name="file" className="uploadfile" ref={this.uploadRef} onChange={this.onChangeHandler} accept="image/*" />
                  {
                    loading ? null : <p className="y-action" onClick={this.triggerUpload}>Edit Photo</p>
                  }
                  {
                    instructor ?
                    <div>
                      <CopyToClipboard text={`${domain}/instructors/${username}`}
                        onCopy={() => this.setState({ copied: true })}>
                        <span>
                          <Popup content='Cick to copy link' position="bottom center" trigger={<span>{`${domain}/instructors/${username}`} </span>} />
                        </span>
                      </CopyToClipboard>
                      </div>
                      : null
                  }
                  {
                    copied ?
                      <p className="y-action">Copied</p>
                      : null
                  }
                  {/* <p className="y-action">{`${domain}/instructors/${username}`}</p> */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {
              error ? <Message negative className="y-feedback"
                onDismiss={this.dismissError}
              >
                <p>{error}</p>
              </Message> : null
            }
            {
              success ? <Message positive className="y-feedback"
                onDismiss={this.dismissSuccess}
              >
                <p>{success}</p>
              </Message> : null
            }
            <Form className="y-form-main" onSubmit={this.onSubmit} loading={loading}>
              <Grid>
                <Grid.Row>
                  <Grid.Column computer="8" mobile="16">
                    <div className="y-input-holder">
                      <p>First Name</p>
                      <Form.Input fluid type="text"
                        id="forename" required name="forename" onChange={this.typing}
                        value={forename}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column computer="8" mobile="16">
                    <div className="y-input-holder">
                      <p>Last Name</p>
                      <Form.Input fluid type="text"
                        id="surname" required name="surname" onChange={this.typing}
                        value={surname}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column computer="8" mobile="16">
                    <div className="y-input-holder">
                      <p>Your Email</p>
                      <Form.Input fluid type="text"
                        id="email" required name="email" onChange={this.typing}
                        value={email}
                        disabled
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column computer="8" mobile="16">
                    <div className="y-input-holder">
                      <p>Phone Number</p>
                      <Form.Input fluid type="text"
                        id="phone" required name="phone" onChange={this.typing}
                        value={phone}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                  <Grid.Column computer="8" mobile="16">
                    <div className="y-input-holder">
                      <p>Date of Birth</p>
                      <Form.Input fluid type="date"
                        id="dob" required name="dob" onChange={this.typing}
                        value={moment(dob).format('YYYY-MM-DD')}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column computer="8" mobile="16">
                    <div className="y-input-holder">
                      <p>Gender</p>
                      <Select name="gender" options={genders} onChange={this.handleChange} defaultValue={gender}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
                {
                  // instructor &&
                  <>
                    <Grid.Row>
                      {/* <Grid.Column computer="8" mobile="16">
                        <div className="y-input-holder">
                          <p>instagram Link</p>
                          <Form.Input fluid type="text"
                            id="instagramLink" name="instagramLink" onChange={this.typing}
                            value={instagramLink}
                          />
                        </div>
                      </Grid.Column> */}
                      <Grid.Column computer="8" mobile="16">
                        <div className="y-input-holder">
                          <p>Twitter handle</p>
                          <Form.Input fluid type="text"
                            placeholder="e.g __masterclass"
                            id="twitterLink" name="twitterLink" onChange={this.typing}
                            value={twitterLink}
                          />
                        </div>
                      </Grid.Column>
                      <Grid.Column computer="8" mobile="16">
                        <div className="y-input-holder">
                          <p>Instagram handle</p>
                          <Form.Input fluid type="text" placeholder="e.g __masterclass"
                            id="twitterLink" name="instagramLink" onChange={this.typing}
                            value={instagramLink}
                          />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column computer="16" mobile="16">
                        <div className="y-input-holder">
                          <p>Short Bio</p>
                          <Form.TextArea style={{ minHeight: 70 }} name="shortBio" onChange={this.typing} value={shortBio} maxlength="100" />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column computer="16" mobile="16">
                        <div className="y-input-holder">
                          <p> Bio</p>
                          <Form.TextArea style={{ minHeight: 70 }} name="description" onChange={this.typing} value={description} />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                }

              </Grid>
              <div className="btn-holder">
                <Button size="large" type="submit">Save</Button>
              </div>
            </Form>
          </div>
        </div>
        <Footer
          auth={auth} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  updateUser, updateDp
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(Profile)
