import React, { Component } from 'react'
import { Accordion, Icon, Checkbox } from 'semantic-ui-react'
import classnames from "classnames"

export default class Faq extends Component {
  state = { activeIndex: 0 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    return (
      <div className="x-course-faq">
        <Accordion>
          {this.props.faqs.map((faq, key) => (
            <div key={faq._id}>
              <Accordion.Title
                active={activeIndex === key}
                index={key}
                className='x-title'
                onClick={this.handleClick}
              >

                <div>
                  {faq.question}
                </div>
                <Icon size="small" name='chevron down' />
              </Accordion.Title>
              <Accordion.Content className="x-content" active={activeIndex === key}>
                <div className="x-desc">
                  <div>
                    {
                      faq.answer
                    }
                  </div>
                </div>
              </Accordion.Content>
            </div>
          ))}
        </Accordion>
      </div>
    )
  }
}
