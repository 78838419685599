import React, { Component } from 'react'
import { Container, Grid, Icon, Form, Select, Label, Input, Button, Dropdown, Header, Item } from 'semantic-ui-react';
import moment from 'moment'


export default class ChatHead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      display: {},
      conversations: []
    }
  }

  componentWillMount() {
    const { chatObj, currentId, activeChatId } = this.props
    chatObj.members.map((member) => {
      if (member.userId._id !== currentId) {
        this.setState({
          display: member.userId
        })
      }
    })
    this.setState({
      conversations: chatObj.conversations.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
    })
  }

  componentWillReceiveProps(nextProps) {
    const { chatObj, currentId } = nextProps
    // console.log(chatObj)
    chatObj.members.map((member) => {
      if (member.userId._id !== currentId) {
        this.setState({
          display: member.userId
        })
      }
    })
    this.setState({
      conversations: chatObj.conversations.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
    })
  }

  render() {
    const { display, conversations } = this.state

    return (
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column computer="2" mobile="3">
            <div className="img-holder">
              <img src={display.photoURL} alt="" />
            </div>
          </Grid.Column>
          <Grid.Column computer="10" mobile="13">
            <div className="name-msg">
              <div className="namewithactive">
                <span>{`${display.forename} ${display.surname}`}</span>
                {
                  display.online && <div className="online"></div>
                }
              </div>
              {
                conversations.length > 0 ?
                  <p>
                    {
                    conversations[0].messageType === 'Text' ? 
                    conversations[0].body 
                    :
                    <>
                      <Icon name="file"></Icon>  {conversations[0].messageType}
                    </>
                    }
                    </p>
                  : null
              }
            </div>
          </Grid.Column>
          <Grid.Column computer="4" mobile="16">
            <div className="no-time">
              {/* {
                conversations.length > 0 ?
                  <Label>
                    5
              </Label>
                  : null
              } */}
              {
                conversations.length > 0 ?
                  <span>
                    {moment(conversations[0].dateCreated).fromNow(true) === 'a few seconds' ? 'just now' : moment(conversations[0].dateCreated).fromNow(true)}
                  </span>
                  : null
              }
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}
