import React, { Component } from 'react'
import { Message, Grid, Icon, Button, Form, Select, Image, Step, Checkbox, Label, Loader, Dropdown, Header, Modal } from 'semantic-ui-react';
import VideoModal from './videomodal'

export default class CourseCurriculum extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      openModal: false,
      errors: [],
      currentLectureId: '',
      currentSectionid: '',
      sections: [{
        id: Math.round(Math.random() * 1000),
        title: '',
        position: 0,
        duration: 0,
        lessons: 0,
        lectures: [
          {
            id: Math.round(Math.random() * 1000),
            position: 0,
            title: '',
            videourl: '',
            duration: 0
          }
        ]
      }]
    }
  }

  componentWillReceiveProps(nextProps) {
    const { course } = nextProps
    const { addedCurriculumError, isAddingCurriculum } = course
    this.setState({
      loading: isAddingCurriculum
    })
    if(addedCurriculumError) {
      let errors = []
      errors.push(addedCurriculumError)
      this.setState({
        errors
      })
    }
  }

  addSection = () => {
    var { sections } = this.state
    sections.push({
      id: Math.round(Math.random() * 1000),
      title: '',
      position: 0,
      duration: 0,
      lectures: [
        {
          id: Math.round(Math.random() * 1000),
          position: 0,
          title: '',
          videourl: '',
          duration: 0
        }
      ]
    })
    this.setState({
      sections
    })
  }

  addLecture = (id) => {
    var { sections } = this.state
    const sectionIndex = sections.findIndex(section => section.id == id)
    sections[sectionIndex].lectures.push({
      id: Math.round(Math.random() * 1000),
      position: 0,
      title: '',
      videourl: '',
      duration: 0
    })
    this.setState({
      sections
    })
  }

  removeVideo = (id, lecid, duration) => {
    var { sections } = this.state
    const sectionIndex = sections.findIndex(section => section.id == id)
    let newDuration = sections[sectionIndex].duration === 0 ? 0 : sections[sectionIndex].duration - duration
    sections[sectionIndex].duration = newDuration
    const lectureIndex = sections[sectionIndex].lectures.findIndex(lecture => lecture.id == lecid)
    sections[sectionIndex].lectures[lectureIndex].videourl = ''
    sections[sectionIndex].lectures[lectureIndex].duration = 0
    this.setState({
      sections
    })
  }

  setVideo = (videourl, duration) => {
    var { sections, currentSectionid, currentLectureId } = this.state
    const sectionIndex = sections.findIndex(section => section.id == currentSectionid)
    let newDuration = sections[sectionIndex].duration + duration
    sections[sectionIndex].duration = newDuration
    const lectureIndex = sections[sectionIndex].lectures.findIndex(lecture => lecture.id == currentLectureId)
    sections[sectionIndex].lectures[lectureIndex].videourl = videourl
    sections[sectionIndex].lectures[lectureIndex].duration = duration
    this.setState({
      openModal: false,
      sections
    })
  }


  selectVideo = (currentSectionid, currentLectureId) => {
    this.setState({
      openModal: true,
      currentSectionid,
      currentLectureId
    })
  }


  typing = (text, type, id, lecid) => {
    var { sections } = this.state
    if (type === 'section') {
      const sectionIndex = sections.findIndex(section => section.id == id)
      sections[sectionIndex].title = text.trim()
    }
    if (type === 'lecture') {
      const sectionIndex = sections.findIndex(section => section.id == id)
      const lectureIndex = sections[sectionIndex].lectures.findIndex(lecture => lecture.id == lecid)
      sections[sectionIndex].lectures[lectureIndex].title = text.trim()
    }
    this.setState({
      sections
    })
  }

  removeSection = (id) => {
    var { sections } = this.state
    const sectionIndex = sections.findIndex(section => section.id == id)
    sections.splice(sectionIndex, 1)
    this.setState({
      sections
    })
  }

  removeLecture = (id, lecid, duration) => {
    var { sections } = this.state
    const sectionIndex = sections.findIndex(section => section.id == id)
    let newDuration = sections[sectionIndex].duration === 0 ? 0 : sections[sectionIndex].duration - duration
    sections[sectionIndex].duration = newDuration
    const lectureIndex = sections[sectionIndex].lectures.findIndex(lecture => lecture.id == lecid)
    sections[sectionIndex].lectures.splice(lectureIndex, 1)
    this.setState({
      sections
    })
  }

  dismissError = () => {
    this.setState({
      errors: []
    })
  }

  saveCurriculum = () => {
    var { sections } = this.state
    const { courseId } = this.props
    this.setState({
      errors: []
    })
    let errors = []
    let checkAnyLecture
    let checkAnySection = sections.some((section) => section.title === '')
    sections.map((section) => {
      checkAnyLecture = section.lectures.some((lecture) => lecture.title === '' || lecture.videourl === '')
    })
    if (checkAnySection) {
      errors.push('No section should have an empty title');
    }
    if (checkAnyLecture) {
      errors.push('No lecture should have an empty title or an empty video');
    }
    if (errors.length) {
      this.setState({
        errors
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      sections.map((section, index) => {
        section.position = index
        section.lessons = section.lectures.length
        section.lectures.map((lecture, lecindex) => (
          lecture.position = lecindex
        ))
      })
      this.props.addCurriculum(sections, courseId)
    }
  }

  closeModalHandler = () => {
    this.setState({
      openModal: false
    })
  }

  render() {
    const { loading, errors, openModal, sections } = this.state
    return (
      <div className="y-coursecurriculum">
        {
          errors.length ? <Message negative
            className="y-feedback"
            onDismiss={this.dismissError}>
            {
              errors.map((error, index) => (
                <p key={index}>{error}
                </p>
              ))
            }
          </Message> : null
        }
        <Form className="y-form-main course-sections" loading={loading}>
          <Grid>
            <Grid.Row>
              <Grid.Column computer="16">
                <div className="sections-main">
                  <div className="addsection">
                    <Button type="button" onClick={this.addSection}>
                      Add Section
                          </Button>
                  </div>
                  <div className="section-holder">
                    {
                      sections.sort((a, b) => a - b).map((section, index) => (

                        <div className="y-section y-input-holder" key={section.id}>
                          <div className="input-withr-remove">
                            <p>Section Title
                            </p>
                            {
                              index === 0 ? null :
                                <Icon name="times" className="y-removelecorsec" onClick={() =>
                                  this.removeSection(section.id)
                                } />
                            }
                          </div>
                          <Form.Input fluid type="text"
                            id="sectiontitle" required name="sectiontitle" onChange={(e) => this.typing(e.target.value, 'section', section.id)}
                          />
                          <div className="addsection" type="button">
                            <Button type="button" onClick={() => this.addLecture(section.id)}>
                              Add Lecture
                            </Button>
                          </div>
                          <div className="lecture-holder">
                            {
                              section.lectures.sort((a, b) => a - b).map((lecture, lecindex) => (
                                <div key={lecture.id} className="y-lecture">
                                  <div className="y-input-holder">
                                    <div className="input-withr-remove">
                                      <p>Lecture Title
                            </p>
                                      {
                                        lecindex === 0 ? null :
                                          <Icon name="times" className="y-removelecorsec" onClick={() =>
                                            this.removeLecture(section.id, lecture.id, lecture.duration)
                                          } />
                                      }
                                    </div>
                                    <Form.Input fluid type="text"
                                      id="lecturetitle" required name="lecturetitle" onChange={(e) => this.typing(e.target.value, 'lecture', section.id, lecture.id)}
                                    />
                                  </div>
                                  <div className="y-input-holder">
                                    <p>Lecture Video</p>
                                    {
                                      lecture.videourl ? <div className="course-vid">
                                        <video controls>
                                          <source src={lecture.videourl} type={`video/${lecture.videourl.split('.')[lecture.videourl.split('.').length - 1]}`} />
                                                  Your browser does not support the video tag.
                                                </video>
                                        <Icon name="times" onClick={() =>
                                          this.removeVideo(section.id, lecture.id, lecture.duration)
                                        } />
                                      </div> : null
                                    }
                                    <Button type="button" onClick={() => this.selectVideo(section.id, lecture.id)}>
                                      Upload Video
                                            </Button>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  <div className="addsection">
                    <Button type="button" onClick={this.saveCurriculum}>
                      Save
                          </Button>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        <VideoModal openModal={openModal} 
        closeModalHandler={this.closeModalHandler} 
        setVideo={this.setVideo} />
      </div>
    )
  }
}
