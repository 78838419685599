import React from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

const Connectstripsuc = () => (
  <Modal open={true} basic size='small'>
    <Header icon='archive' content='New Message' />
    <Modal.Content>
      <p>
        Congratulations, you have successfully connected your stripe account.
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button basic color='green' inverted onClick={() => window.location.assign(window.location.origin)}>
        <Icon name='checkmark' /> Continue
      </Button>
    </Modal.Actions>
  </Modal>
)

export default Connectstripsuc
