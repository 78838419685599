import React, { Component } from 'react'
import { Message, Grid, Icon, Button, Form, Select, Image, Step, Checkbox, Label, Loader, Dropdown, Header, Modal } from 'semantic-ui-react';
import AddFaq from './faq'
import VideoModal from './videomodal'

export default class CourseForm extends Component {
  constructor(props) {
    super(props)
    this.uploadRefImg = React.createRef()
    this.state = {
      loading: false,
      runningPromo: false,
      showAddFaq: false,
      title: '',
      price: '',
      promoprice: "",
      overview: '',
      faqs: [],
      imgurl: '',
      videourl: '',
      imgurlLoader: false,
      errors: [],
      openModal: false,
      paidCourse: false
    }
  }

  componentWillReceiveProps(nextProps) {
    const { course } = nextProps
    const { isUploadCourseImageLoading, uploadCourseImageSuccess, uploadCourseImage, uploadCourseImageError, isAddingCourse, addedCourseError } = course
    this.setState({
      imgurlLoader: isUploadCourseImageLoading,
      loading: isAddingCourse
    })
    if (uploadCourseImageSuccess) {
      this.setState({
        imgurl: uploadCourseImage
      })
      this.uploadRefImg.current.value = ''
      this.props.clearUploadImage()
    }
    if (uploadCourseImageError) {
      let errors = []
      errors.push('Course cover photo not uploaded')
      this.setState({
        errors
      })
      this.uploadRefImg.current.value = ''
    }
    if(addedCourseError) {
      let errors = []
      errors.push(addedCourseError)
      this.setState({
        errors
      })
    }
  }

  triggerUploadImg = () => {
    this.uploadRefImg.current.click()
  }

  onChangeHandlerImg = (e) => {
    this.setState({
      imgurl: ''
    })
    this.props.uploadCourseImage(e.target.files[0])
  }

  setVideo = (videourl) => {
    this.setState({
      videourl,
      openModal: false
    })
  }

  removeImage = () => {
    this.setState({
      imgurl: ''
    })
    this.props.clearUploadImage()
  }

 removeVideo = () => {
   this.setState({
    videourl: ''
   })
 }

  typing = (e) => {
    var name = e.target.name;
    this.setState({
      [name]: e.target.value
    })
  }

  onChangeCheckbox = (evt, data) => {
    this.setState({
      runningPromo: data.checked
    })
  }

  onChangeCheckboxPaid = (evt, data) => {
    this.setState({
      paidCourse: data.checked
    })
  }

  showAddFaq = () => {
    this.setState({
      showAddFaq: true
    })
  }

  closeAddFaq = () => {
    this.setState({
      showAddFaq: false
    })
  }

  addFaq = (detail) => {
    const { faqs } = this.state
    let newFaqs = faqs
    newFaqs.push(detail)
    this.setState({
      faqs: newFaqs
    })
    this.closeAddFaq()
  }

  removeFaq = (index) => {
    const { faqs } = this.state
    let newFaqs = faqs
    newFaqs.splice(index, 1)
    this.setState({
      faqs: newFaqs
    })
  }

  dismissError = () => {
    this.setState({
      errors: []
    })
  }

  selectVideo = () => {
    this.setState({
      openModal: true
    })
  }

  closeModalHandler = () => {
    this.setState({
      openModal: false
    })
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { title, price, promoprice, overview, faqs, imgurl, videourl, runningPromo, paidCourse } = this.state
    this.setState({
      loading: true,
      errors: []
    })
    let errors = []
    let checkFaq = faqs.some((faq) => faq.question.trim() === '' || faq.answer.trim() === '')
    if (!faqs.length || checkFaq) {
      errors.push('Add least one Course FAQ with')
    }
    if (!imgurl.length) {
      errors.push('Upload Course Image')
    }
    if (errors.length) {
      this.setState({
        errors,
        loading: false
      })
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      var detail = {
        title,
        description: overview,
        videoUrl: videourl,
        coverPhoto: imgurl,
        promoprice: runningPromo ? promoprice : 0,
        price,
        faqs,
        paidCourse
      }
      this.props.addCourse(detail)
    }
  }


  render() {
    const { faqs, showAddFaq, runningPromo, imgurl, videourl, imgurlLoader, videourlLoader, loading, errors, openModal, paidCourse } = this.state
    return (
      <div className="y-courseform">
        {
          errors.length ? <Message negative
            className="y-feedback"
            onDismiss={this.dismissError}>
            {
              errors.map((error, index) => (
                <p key={index}>{error}
                </p>
              ))
            }
          </Message> : null
        }
        <Form className="y-form-main" onSubmit={this.onSubmit} loading={loading}>
          <div className="y-input-holder">
            <p>Course Title</p>
            <Form.Input fluid type="text"
              id="title" required name="title" onChange={this.typing}
            />
          </div>
          <div className="y-input-holder promo">
            <Checkbox toggle label='Paid Course?'
              onClick={(evt, data) => this.onChangeCheckboxPaid(evt, data)}
            />
          </div>
          {
            paidCourse ?
              <>
                <div className="y-input-holder promo">
                  <Checkbox toggle label='Running discounted price?'
                    onClick={(evt, data) => this.onChangeCheckbox(evt, data)}
                  />
                </div>
                <Grid>
                  <Grid.Row columns="equal">
                    <Grid.Column computer="8">
                      <div className="y-input-holder">
                        <p> Price</p>
                        <Form.Input fluid type="number"
                          id="price" required name="price" onChange={this.typing}
                        />
                      </div>
                    </Grid.Column>
                    {
                      runningPromo ? <Grid.Column computer="8">
                        <div className="y-input-holder">
                          <p>Promo Price</p>
                          <Form.Input fluid type="number"
                            id="promoprice" name="promoprice" onChange={this.typing}
                          />
                        </div>
                      </Grid.Column> : null
                    }

                  </Grid.Row>
                </Grid>
              </>
              : null
          }
          <div className={`y-input-holder ${imgurlLoader ? 'image' : ''}`}>
            <p>Course Image</p>
            {
              imgurlLoader ? <Loader active /> : null
            }
            {
              imgurl ? <div className="course-image">
                <div className="img-holder" style={{ backgroundImage: `url(${imgurl})` }}>
                </div>
                <Icon name="times" onClick={
                  this.removeImage
                } />
              </div> : null
            }
            <input type="file" name="file" className="course-uploadfile" accept="image/*" ref={this.uploadRefImg} onChange={this.onChangeHandlerImg} />
            <Button type="button" onClick={this.triggerUploadImg} disabled={imgurlLoader} >
              Upload Image
                </Button>
          </div>
          {/* <div className="y-input-holder">
                  <p>Course Video</p>
                  <Button type="button" onClick={this.selectVideo}>
                    Upload Video
                </Button>
                </div> */}
          <div className="y-input-holder">
            <p>Course Video</p>
            {/* {
                    videourlLoader ? <Loader active /> : null
                  } */}
            {
              videourl ? <div className="course-vid">
                <video controls>
                  <source src={videourl} type={`video/${videourl.split('.')[videourl.split('.').length - 1]}`} />
                      Your browser does not support the video tag.
                    </video>
                <Icon name="times" onClick={
                  this.removeVideo
                } />
              </div> : null
            }
            {/* <input type="file" name="file" className="course-uploadfile" accept="video/*" ref={this.uploadRefVid} onChange={this.onChangeHandlerVid} /> */}
            <Button type="button" onClick={this.selectVideo}>
              Upload Video
                </Button>
          </div>
          <div className="y-input-holder">
            <p>Course Overview</p>
            <Form.TextArea required style={{ minHeight: 150 }} name="overview" required onChange={this.typing} />
          </div>
          <div className="y-input-holder">
            <div className="custom-label">
              <p>Course FAQ</p>
              <Button size="mini" type="button" onClick={this.showAddFaq}>
                Add
                  </Button>
            </div>
            {
              faqs.map((faq, index) => (
                <Message key={index} onDismiss={() => this.removeFaq(index)}>
                  <p>
                    <b>Question:</b> {faq.question}
                  </p>
                  <p>
                    <b>Answer:</b> {faq.answer}
                  </p>
                </Message>
              ))
            }
            {
              showAddFaq ? <AddFaq closeAddFaq={this.closeAddFaq} addFaq={this.addFaq} /> : null
            }
          </div>
          <div className="btn-holder">
            <Button size="large" type="submit" disabled={videourlLoader || imgurlLoader}>Create Course</Button>
          </div>
        </Form>
        <VideoModal openModal={openModal} closeModalHandler={this.closeModalHandler} setVideo={this.setVideo} />
       </div>
    )
  }
}
