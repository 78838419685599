import React, { useEffect, useState } from 'react'
import Navbar from "../../components/navbar";
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Icon, GridRow, GridColumn, Container, Button, Message } from 'semantic-ui-react';
import { resetCourseAction, fetchUploadedCourses, deleteCourseById, clearDeleteCourseDashboard } from '../../utils/actions/course';
import Spinner from '../../components/ui/spinner';
import Coursecard from '../../components/courses/coursecard';
import { fetchMyStudents, addChat, getChats } from '../../utils/actions/user';
import baseUrl, { stripePayoutClientId, domain, secretKey } from '../../utils/config';
import jwt from "jsonwebtoken"
const Dashboard = ({
  history
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const uploadedCourses = useSelector(s => s.course.uploadedCourses);
  const myStudents = useSelector(s => s.users.myStudents);
  const totalCourses = useSelector(s => s.course.totalCourses);
  const notifications = useSelector(s => s.users.notifications);
  const auth = useSelector(s => s.auth.auth);
  const chats = useSelector(s => s.users.chats);
  const isDeleteCourse = useSelector(s => s.course.isDeleteCourse);
  const deleteCourseSuccess = useSelector(s => s.course.deleteCourseSuccess);
  const deleteCourseError = useSelector(s => s.course.deleteCourseError);


  useEffect(() => {
    dispatch(fetchUploadedCourses())
    dispatch(fetchMyStudents())
    dispatch(getChats())
  }, [])
  useEffect(() => {
    setLoading(false)
    // setQuickInfo()
    dispatch(resetCourseAction())
  }, [uploadedCourses.isCoursesLoaded])

  const connect = () => {
    var token = jwt.sign({ ...auth.user }, secretKey)
    window.location.assign(`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripePayoutClientId}&scope=read_write`)
    // window.location.assign(`https://connect.stripe.com/express/oauth/authorize?redirect_uri=https://connect.stripe.com/connect/default/oauth/test&client_id=${stripePayoutClientId}&state=${token}&stripe_user[email]=${auth.user.email}`)

  }
  // const [quickinfo, setQuickInfo] = useState()
  const quickinfo = [
    {
      id: 1,
      name: 'My Courses',
      main: totalCourses,
      icon: 'tablet alternate',
      color: 'purple'
    },
    {
      id: 2,
      name: 'Total Earning',
      main: "$" + auth.user.earnings,
      icon: 'save outline',
      color: 'green'
    },
    {
      id: 3,
      name: 'Total Enrollment',
      main: auth.user.totalEnrollment,
      icon: 'time',
      color: 'yellow'
    }, {
      id: 4,
      name: 'Instructor Rating',
      main: auth.user.rating ,
      icon: 'bookmark outline',
      color: 'primary'
    }
  ]

  const openChat = (id) => {
    let chatLead = []
    let toChat
    chats.map((chat) => {
      chat.members.map((member) => {
        if (member.userId._id !== auth.user.uid) {
          chatLead.push({ memberId: member.userId._id, chat: chat })
        }
      })
    })
    toChat = chatLead.filter((item) => item.memberId === id)
    if (toChat.length === 0) {
      dispatch(addChat(id))
      history.push('/messages')
    } else {
      history.push({
        pathname: '/messages',
        state: {
          chat: toChat[0].chat
        }
      })
    }
  }

  const deleteCourse = (id) => {
    dispatch(deleteCourseById(id))
  }

  const dismissDeleteFeedBack = () => {
    dispatch(clearDeleteCourseDashboard())
  }

  return (
    !loading ?
      <div className="x-dashboard">
        <Navbar history={history}
          auth={auth} />
        <div className="x-main">
          <div className="x-header-flex">
            <h2 >Dashboard</h2>
            <div><Button onClick={connect} content={auth.user.stripe_user_id ? "Connected" : "Connect"} icon="stripe" labelPosition='left' size="small" disabled={auth.user.stripe_user_id ? true : false} /></div>
          </div>
          <div className="x-quickinfo">
            <Grid>
              <Grid.Row>
                {
                  quickinfo.map((info) => (
                    <Grid.Column computer="4" mobile="16" tablet="4" key={info.id}>
                      <div className="x-quick-card">
                        <div className="info-detail">
                          <p className="top-info">{info.name}</p>
                          <h2 className="main-imfo">{info.main}</h2>
                        </div>
                        <div className={`icon-holder ${info.color}`}>
                          <Icon name={info.icon} />
                        </div>
                      </div>
                    </Grid.Column>
                  ))
                }
              </Grid.Row>
              <Grid.Row>

              </Grid.Row>
            </Grid>
          </div>
          <Container fluid>
            <div className="x-header-flex">
              <h2>My Courses</h2>
              <div><Button size="small" onClick={(e) => history.push("/upload-course")}> Upload Course </Button></div>
            </div>
            {
              deleteCourseError ? <Message negative className="y-feedback"
                onDismiss={dismissDeleteFeedBack}
              >
                <p>{deleteCourseError}</p>
              </Message> : null
            }
            {
              deleteCourseSuccess ? <Message positive className="y-feedback"
                onDismiss={dismissDeleteFeedBack}
              >
                <p>{deleteCourseSuccess}</p>
              </Message> : null
            }
            <Grid>
              {
                uploadedCourses.filter((item) => item.published).length > 0 && <h3>Published Courses</h3>
              }
              <GridRow columns="3">
                {uploadedCourses.filter((item) => item.published).map((item) => (
                  <GridColumn mobile="16" computer="4" tablet="4" key={item._id}>
                    <Coursecard
                      history={history}
                      course={item}
                      showDeleteButton={true}
                      deleteCourse={deleteCourse}
                    />
                  </GridColumn>

                ))}
              </GridRow>
              {
                uploadedCourses.filter((item) => !item.published).length > 0 && <h3>Saved Courses</h3>
              }
              <GridRow columns="3">
                {uploadedCourses.filter((item) => !item.published).map((item) => (
                  <GridColumn mobile="16" computer="4" tablet="4" key={item._id}>
                    <Coursecard
                      history={history}
                      course={item}
                      showDeleteButton={true}
                      deleteCourse={deleteCourse}
                    />
                  </GridColumn>

                ))}
              </GridRow>
              <GridRow textAlign="center">
                <GridColumn>

                  {uploadedCourses.length == 0 &&
                    <div className="x-empty" style={{ marginBottom: '2rem' }}>
                      You havent uploaded any classes yet
                    </div>
                  }
                </GridColumn>
              </GridRow>
            </Grid>
            <Grid>
              <GridRow columns="3">
                <GridColumn mobile="16" tablet="8" computer="8">
                  <div className="x-card">
                    <div className="x-header-flex">
                      <h4>New Students</h4>
                      <span>
                        <Icon name="ellipsis horizontal" />
                      </span>
                    </div>
                    <div className="x-content">
                      {myStudents.map((student) => (
                        <div className="x-not-flex students" key={student._id}>
                          <div className="student-info">
                            <div>
                              <Button className="" icon="user" circular size="small" />
                              {/* <div  className="x-not-icon"><Icon name="payment" /></div> */}
                            </div>
                            <div> {student.studentId.forename} {student.studentId.surname} </div>
                          </div>

                          <Button className="message-btn" onClick={() => openChat(student.studentId._id)}>
                            Message
                          </Button>
                        </div>
                      ))}
                    </div>
                    <div className="x-more">
                      See more
                            </div>
                  </div>
                </GridColumn>
                <GridColumn mobile="16" tablet="8" computer="8">
                  <div className="x-card">
                    <div className="x-header-flex">
                      <h4>Notifications</h4>
                    </div>
                    <div className="x-content">
                      {notifications.map((not) => (
                        <div className="x-not-flex" key={not._id}>
                          <div>
                            <Button className="" icon="alarm" circular size="small" />
                            {/* <div  className="x-not-icon"><Icon name="payment" /></div> */}
                          </div>
                          <div> {not.description} </div>
                        </div>
                      ))}
                    </div>
                    <div className="x-more">
                      See more
                            </div>
                  </div>
                </GridColumn>
              </GridRow>
            </Grid>
          </Container>
        </div>
      </div> :
      <Spinner />
  )
}


export default Dashboard