import React, { Component, useEffect, useState } from "react"
import Navbar from "../../components/navbar";
import { Button, Icon, Container, Grid, GridColumn, GridRow, Pagination } from "semantic-ui-react";
import Searchbox from "../../components/courses/searchbox";
import Coursecard from "../../components/courses/coursecard";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses, resetCourseAction, fetchMyCourses, clearCourse } from "../../utils/actions/course";
import Footer from "../../components/footer/footer";
import Spinner from "../../components/ui/spinner";
import Enroll from "../../components/footer/enroll";

const Mycourses = ({
  history
}) => {
  const dispatch = useDispatch()
  const coursesObj = useSelector(s => s.course);
  const totalResults = useSelector(s => s.course.totalResults);
  const auth = useSelector(s => s.auth.auth);
  const [limit, setLimit] = useState(9)
  const [pageNum, setPageNum] = useState(1)
  useEffect(() => {
      dispatch(fetchMyCourses({limit, pageNum}))
  }, [])
  useEffect(() => {
    dispatch(resetCourseAction())
  }, [coursesObj.isCoursesLoaded])

  const handlePagination = (page) => {
    setPageNum(page)
    dispatch(clearCourse())
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    dispatch(fetchMyCourses({limit, pageNum: page}))
}


  return (
    coursesObj.isCoursesLoaded ?
      <div className="x-my-courses">
        <Navbar
          history={history}
          auth={auth}
        />

        <div className="x-main">
          <Container>
            <h2>My Courses </h2>
            <br />
            <Grid>
              <GridRow columns={window.innerWidth > 760 ?"3": "1"}>
                {coursesObj.myCourses.map((item) => (
                  <GridColumn >
                    <Coursecard
                      history={history}
                      course={item.courseId}
                      duration={item.duration}
                      completed={item.completed}
                      percentWatched={item.percentWatched}
                      myCourses={true}
                    />
                  </GridColumn>

                ))}
              </GridRow>
              <GridRow textAlign="center">
                <GridColumn>
                  
                {coursesObj.myCourses.length == 0 &&
                <div className="x-empty">
                    You havent taken any classes yet
                </div>
                }
                </GridColumn>
              </GridRow>
            </Grid>
            <Grid>
            <GridRow textAlign="center">
                <GridColumn>
                <Pagination
                    boundaryRange={0}
                    defaultActivePage={pageNum}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={Math.ceil(totalResults/limit)}
                    onPageChange={(e, data) => handlePagination(data.activePage)}
                  />
                </GridColumn>
              </GridRow>
            </Grid>
          </Container>
        </div>
        {/* <Enroll history={history} auth={auth}/> */}
        <Footer
          auth={auth}
        />
      </div> :
      <Spinner />
  )
}

export default Mycourses