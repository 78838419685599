import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import jwt from 'jsonwebtoken'

export default class CheckInstructorAuth extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: {},
            token: ''
        }
    }

    componentWillMount(){
        var token = window.localStorage.getItem("token");
        if(token) {
            var data = jwt.decode(token);
            this.setState({data, token})
        }
    }
    
    render(){
        var { data, token } = this.state
        return (
            !token ? <Redirect to="/getstarted" /> :
            !data.isCompleted ? <Redirect to="/getstarted" />
            : 
            <Route 
                path={this.props.path}
                render={(props) => <this.props.component {...props}/>} 
            />
        )
    }
}
