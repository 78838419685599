import React, { Component } from 'react'
import { Container, Grid, Icon, Form, Select, Label, Input, Button, Dropdown, Header } from 'semantic-ui-react';
import moment from 'moment'

export default class ChatBody extends Component {
  constructor(props) {
    super(props)
    this.state = {
      display: {},
      conversations: [],
      message: '',
      search: false,
      searchText: '',
      searchResult: [],
      favourite: null,
      chatmemberId: ''
    }
  }

  componentDidMount() {
    let myDiv = document.getElementById("myDiv");
    myDiv.scrollTop = myDiv.scrollHeight;
  }

  componentWillMount() {
    const { currentChat, currentId } = this.props
    currentChat.members.map((member) => {
      if (member.userId._id === currentId) {
        this.setState({
          favourite: member.favourite,
          chatmemberId: member._id
        })
      }
      if (member.userId._id !== currentId) {
        this.setState({
          display: member.userId
        })
      }
    })
    this.setState({
      conversations: currentChat.conversations.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
    })
  }

  componentWillReceiveProps(nextProps) {
    const { currentChat, currentId, messageSentSuccess } = nextProps
    currentChat.members.map((member) => {
      if (member.userId._id === currentId) {
        this.setState({
          favourite: member.favourite,
          chatmemberId: member._id
        })
      }
      if (member.userId._id !== currentId) {
        this.setState({
          display: member.userId
        })
      }
    })
    this.setState({
      conversations: currentChat.conversations.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)),
    }, () => {
      let myDiv = document.getElementById("myDiv");
      myDiv.scrollTop = myDiv.scrollHeight;
    })
    if (messageSentSuccess) {
      this.setState({
        message: ''
      }, () => {
        this.props.resetMessageSent()
      })
    }
  }

  clearSearch = () => {
    this.setState({
      searchResult: [],
      search: false,
      searchText: ''
    })
  }

  typing = (e) => {
    const { conversations } = this.state
    var name = e.target.name;
    this.setState({
      [name]: e.target.value
    }, () => {
      if (name === 'searchText') {
        this.setState({
          search: true,
          searchResult: []
        })
        if (conversations.length > 0 && this.state.searchText.trim().length > 0) {
          let results = conversations.filter((item) => item.body.toLowerCase().includes(this.state.searchText.trim().toLowerCase()))
          this.setState({
            searchResult: results
          })
        } else {
          this.setState({
            search: false,
            searchResult: []
          })
          let myDiv = document.getElementById("myDiv");
          myDiv.scrollTop = myDiv.scrollHeight;
        }
      }
    })
  }

  send = (e) => {
    e.preventDefault()
    const { message, display } = this.state
    const { currentChat } = this.props
    const details = {
      body: message,
      chatId: currentChat._id,
      creatorId: this.props.currentId,
      dateCreated: Date.now(),
      recieverId: display._id
    }
    this.props.recieveMessageDetails(details)
  }




  render() {
    const { display, conversations, search, searchText, searchResult, favourite, chatmemberId } = this.state
    const { currentId } = this.props
    return (
      <div className="y-message-desk">
        <div className="desk-top">
          <Grid>
            <Grid.Row verticalAlign="middle">
              <Grid.Column computer="8" mobile="16">
                <div className="deskname">
                  <div className="img-holder">
                    <img src={display.photoURL} alt="" />
                  </div>
                  <div className="namewithactive">
                    <h4>{`${display.forename} ${display.surname}`}</h4>
                    {
                      display.online && <div className="online"></div>
                    }
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column computer="8" textAlign={window.innerWidth > 760 ? "right" : " "} mobile="16">
                <div className="deskaction">
                  <div className="search-holder">
                    <Input
                      icon={search ? <Icon
                        onClick={() => this.clearSearch()}
                        name="times"></Icon> : <Icon name="search"></Icon>}
                      placeholder='Search chat...' name="searchText"
                      onChange={this.typing}
                      value={searchText}
                    //  disabled={!conversations.length > 0 ? false : true }
                    />
                  </div>
                  <Icon name="star" className={`${favourite ? 'active' : ''}`} onClick={() => this.props.toggleFav(chatmemberId, favourite)}></Icon>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div className="desk-main" id="myDiv">
          {
            search ?
              searchResult.length > 0 ?
                searchResult.map((item) => (
                  <div key={item._id} className={`${item.creatorId === currentId ? 'send' : 'recieve'}`}>
                    <div className="message-content">
                      {
                        item.body
                      }
                    </div>
                    <div className={`${item.creatorId === currentId ? 'message-timestamp-left' : 'message-timestamp-right'}`}>
                      {moment(item.dateCreated).fromNow() === 'a few seconds ago' ? 'just now' : moment(item.dateCreated).fromNow()}
                    </div>
                  </div>
                ))
                :
                <p style={{ textAlign: 'center' }}>No result found.</p>
              :
              !conversations.length > 0 ?
                <p style={{ textAlign: 'center' }}>Send a message to start the conversation.</p>
                :
                conversations.map((item) => (
                  <div key={item._id} className={`${item.creatorId === currentId ? 'send' : 'recieve'}`}>
                    <div className="message-content">
                      {
                        item.messageType === 'Text' ?
                          item.body :
                          <>
                            <Icon name="file"></Icon>
                            <span>{item.body}</span>
                            <Label as='a' tag className="filetag">
                              {item.messageType}
                            </Label>
                            <a href={item.url} download={item.body} target="_black">Open</a>
                          </>
                      }
                    </div>
                    <div className={`${item.creatorId === currentId ? 'message-timestamp-left' : 'message-timestamp-right'}`}>
                      {moment(item.dateCreated).fromNow() === 'a few seconds ago' ? 'just now' : moment(item.dateCreated).fromNow()}
                    </div>
                  </div>
                ))
          }
        </div>
        <div className="desk-footer">
          <Form onSubmit={this.send} >
            <Grid>
              <Grid.Row>
                <Grid.Column computer="1" mobile="2">
                  <Icon name="attach" onClick={() => this.props.openFileModal()}></Icon>
                </Grid.Column>
                <Grid.Column computer="13" mobile="14">
                  <Form.Input fluid type="text"
                    id="'message'" required name="message"
                    placeholder="Type your message..."
                    onChange={this.typing}
                    value={this.state.message}
                  />
                </Grid.Column>
                <Grid.Column computer="2" mobile="16">
                  <Button>
                    Send
          </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>

        </div>
      </div>
    )
  }
}
