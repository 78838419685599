import React, { Component } from 'react'
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import { Form, Grid, Button, Message } from 'semantic-ui-react';
import { sendContact } from '../../utils/actions/user'

class Contact extends Component {
  constructor(props) {
    super(props)
    this.formRef = React.createRef()
    this.state = {
      fullname: '',
      email: '',
      subject: '',
      message: '',
      loading: false,
      error: null,
      success: null
    }
  }

  componentWillReceiveProps(nextProps) {
    const { users } = nextProps
    const { isSendingContact, sendContactSuccess, sendContactError} = users
    this.setState({
      loading: isSendingContact
    })
    if (sendContactSuccess) {
      this.setState({
        success: sendContactSuccess
      })
     this.setState({
       fullname: '',
       subject: '',
       message: '',
       email: ''
     })
    }
    if (sendContactError) {
      this.setState({
        error: sendContactError
      })
    }
  }

  typing = (e) => {
    var name = e.target.name;
    this.setState({
      [name]: e.target.value
    })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { fullname, email, subject, message } = this.state
    this.setState({
      error: null,
      success: null
    })
    let info = {
      fullname,
      email,
      subject,
      message
    }
    this.props.sendContact(info)
  }

  dismissError = () => {
    this.setState({
      error: null
    })
  }

  dismissSuccess = () => {
    this.setState({
      success: null
    })
  }

  render() {
    const { history, auth } = this.props
    const { loading, error, success } = this.state

    return (
      <div>
        <Navbar history={history}
          auth={auth} />
        <div className="y-contact">
          <div className="y-main">
            <h1 className="top-heading">Get In Touch</h1>
            <p className="below-heading">You can write to us directly. Our response time is less than 24 hours.</p>
            {
              error ? <Message negative className="y-feedback"
                onDismiss={this.dismissError}
              >
                <p>{error}</p>
              </Message> : null
            }
            {
              success ? <Message positive className="y-feedback"
                onDismiss={this.dismissSuccess}
              >
                <p>{success}</p>
              </Message> : null
            }
            <Form className="contact-form" onSubmit={this.onSubmit} loading={loading} ref={this.formRef}>
              <Grid>
                <Grid.Row>
                  <Grid.Column computer="8" mobile="16">
                    <div className="y-input-holder">
                      <p>Fullname*</p>
                      <Form.Input fluid type="text"
                        id="fullname" required name="fullname" onChange={this.typing}
                        value={this.state.fullname}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column computer="8" mobile="16">
                    <div className="y-input-holder">
                      <p>Email*</p>
                      <Form.Input fluid type="email"
                        id="email" required name="email" onChange={this.typing}
                        value={this.state.email}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className="y-input-holder">
                <p>Subject*</p>
                <Form.Input fluid type="text"
                  id="subject" required name="subject" onChange={this.typing}
                  value={this.state.subject}
                />
              </div>
              <div className="y-input-holder">
                <p>Message*</p>
                <Form.TextArea required style={{ minHeight: 70 }} name="message" required onChange={this.typing}
                 value={this.state.message}
                />
              </div>
              <div className="btn-holder">
                <Button size="large" type="submit">Send</Button>
              </div>
            </Form>
          </div>
        </div>
        <Footer
          auth={auth} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth.auth,
  users: state.users
});

const mapDispatchToProps = dispatch => bindActionCreators({
  sendContact
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);