export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const RECOVER_PASSWORD_REQUEST = "RECOVER_PASSWORD_REQUEST";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_FAILURE = "RECOVER_PASSWORD_FAILURE";


export const RESET_PRE_AUTH_STATE = "RESET_PRE_AUTH_STATE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const PERSIST_USER_DATA = "PERSIST_USER_DATA";


export const UPDATE_REQUEST = 'UPDATE_REQUEST'
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_FAILURE = "UPDATE_FAILURE";
export const UPDATE_IMAGE_SUCCESS = "UPDATE_IMAGE_SUCCESS"
