import * as CONSTANT from "../constant/course";

const initialState = {
  instructors: [],
  totalResults: 0,
  myStudents: [],
  notifications: [],
  chargedSuccessfully: false,
  chargeFailed: '',
  instructor: {},
  isLoadingInstructor: false,
  getInstructorSuccess: false,
  getInstructorError: false,
  isSendingContact: false,
  sendContactSuccess: null,
  sendContactError: null,
  isLoadingUsers: false,
  getUsersSuccess: null,
  getUsersError: null,
  users: [],
  isLoadingChats: false,
  getChatsSuccess: null,
  getChatsError: null,
  chats: [],
  files: [],
  isuploadingFile: false,
  uploadFileSuccess: null,
  uploadFileError: null,

}
export default function user(state = initialState, action) {
  switch (action.type) {
    case CONSTANT.FETCH_MY_STUDENTS:
      return {
        ...state,
        myStudents: action.payload
      };
    case CONSTANT.FETCH_INSTRUCTORS:
      return {
        ...state,
        instructors: action.payload.users,
        totalResults: action.payload.totalResults,
      };
    case CONSTANT.CHARGED_SUCCESSFULLY:
      return {
        ...state,
        chargedSuccessfully: true
      };
    case CONSTANT.CHARGED_FAILED:
      return {
        ...state,
        chargeFailed: action.payload
      };
    case CONSTANT.RESET_PAYMENT:
      return {
        ...state,
        chargeFailed: '',
        chargedSuccessfully: false
      };
    case CONSTANT.RECEIVE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };
    case CONSTANT.GETINSTRUCTOR_REQUEST:
      return {
        ...state,
        isLoadingInstructor: true,
        getInstructorSuccess: false,
        getInstructorError: null,
        instructor: {}
      };
    case CONSTANT.GETINSTRUCTOR_SUCCESS:
      return {
        ...state,
        isLoadingInstructor: false,
        getInstructorSuccess: true,
        instructor: action.payload
      };
    case CONSTANT.GETINSTRUCTOR_ERROR:
      return {
        ...state,
        isLoadingInstructor: false,
        getInstructorError: true,
        instructor: {}
      };
    case CONSTANT.CLEAR_GETINSTRUCTOR:
      return {
        ...state,
        isLoadingInstructor: false,
        getInstructorSuccess: false,
        getInstructorError: null,
        instructor: {}
      };
    case CONSTANT.SENDCONTACT_REQUEST:
      return {
        ...state,
        isSendingContact: true,
        sendContactSuccess: null,
        sendContactError: null,
      };
    case CONSTANT.SENDCONTACT_SUCCESS:
      return {
        ...state,
        isSendingContact: false,
        sendContactError: null,
        sendContactSuccess: action.payload
      };
    case CONSTANT.SENDCONTACT_ERROR:
      return {
        ...state,
        isSendingContact: false,
        sendContactError: action.payload,
        sendContactSuccess: null
      };
    case CONSTANT.CLEAR_SENDCONTACT:
      return {
        ...state,
        isSendingContact: false,
        sendContactSuccess: null,
        sendContactError: null,
      };
    case CONSTANT.GETUSERS_REQUEST:
      return {
        ...state,
        isLoadingUsers: true,
        getUsersSuccess: null,
        getUsersError: null,
        users: []
      };
    case CONSTANT.GETUSERS_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        getUsersSuccess: true,
        getUsersError: null,
        users: action.payload
      };
    case CONSTANT.GETUSERS_ERROR:
      return {
        ...state,
        isLoadingUsers: false,
        getUsersSuccess: null,
        getUsersError: action.payload,
        users: []
      };
    case CONSTANT.CLEAR_GETUSERS:
      return {
        ...state,
        isLoadingUsers: false,
        getUsersSuccess: null,
        getUsersError: null
      };
    case CONSTANT.GETCHATS_REQUEST:
      return {
        ...state,
        isLoadingChats: true,
        getChatsSuccess: null,
        getChatsError: null,
        chats: []
      };
    case CONSTANT.GETCHATS_SUCCESS:
      return {
        ...state,
        isLoadingChats: false,
        getChatsSuccess: true,
        getChatsError: null,
        chats: action.payload
      };
    case CONSTANT.GETCHATS_ERROR:
      return {
        ...state,
        isLoadingUsers: false,
        getChatsSuccess: null,
        getChatsError: action.payload,
        chats: []
      };
    case CONSTANT.CLEAR_GETCHATS:
      return {
        ...state,
        isLoadingUsers: false,
        getChatsSuccess: null,
        getChatsError: null
      };
    case CONSTANT.GETCHATS_UPDATE:
      return {
        ...state,
        isLoadingChats: false,
        getChatsSuccess: false,
        getChatsError: null,
        chats: action.payload
      };
    case CONSTANT.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        isuploadingFile: true,
        uploadFileSuccess: null,
        uploadFileError: null,
      };
    case CONSTANT.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        isuploadingFile: false,
        uploadFileSuccess: true,
        uploadFileError: null,
      };
    case CONSTANT.UPLOAD_FILE_ERROR:
      return {
        ...state,
        isuploadingFile: false,
        uploadFileSuccess: false,
        uploadFileError: action.payload,
      };
    case CONSTANT.CLEAR_FILE_UPLOAD:
      return {
        ...state,
        isuploadingFile: false,
        uploadFileSuccess: null,
        uploadFileError: null,
      };
    case CONSTANT.UPDATE_UPLOADED_FILES:
      return {
        ...state,
        files: action.payload
      };
    default:
      return state;
  }
}