import React, { useState } from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import Axios from "axios";
import baseUrl from "../../utils/config";

const Liveclassmodal = ({courseId, live}) => {
  const [goLiveModal, setGoLiveModal] = useState(false);
  const [liveLoader, setLiveLoader] = useState(false);
  const [meetingPassword, setMeetingPassword] = useState(false);
  const [meetingNumber, setMeetingNumber] = useState(false);

  const turnOnLiveClass = () => {
      setLiveLoader(true)
      Axios.post(baseUrl + '/courses/startLiveClass', {
        meetingNumber, meetingPassword, courseId
      }).then((res) =>{
        if(res.data.success) {
            setLiveLoader(false)
            closeModal()
            window.location.reload()
        }
      })
  };
  const turnOffClass = () => {
    Axios.post(baseUrl + '/courses/stopLiveClass', {
        courseId
    }).then((res) =>{
      if(res.data.success) {
          window.location.reload()
      }
    })
};
  const closeModal = () => setGoLiveModal(false);
  return (
    <div>
      <Modal
        size={"mini"}
        open={goLiveModal}
        onClose={closeModal}
      >
        <Modal.Header>Turn on live class </Modal.Header>
        <Modal.Content>
          <Form onSubmit={turnOnLiveClass}>
            <Form.Input
              type="number"
              required
              onChange={(e)=> setMeetingNumber(e.target.value)}
              placeholder="Zoom Meeting ID/ Meeting Number"
            />
            <Form.Input 
              onChange={(e)=> setMeetingPassword(e.target.value)}
              type="text" placeholder="Meeting Password" />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Turn on"
            onClick={turnOnLiveClass}
            loading={liveLoader}
            type="submit"
          />
        </Modal.Actions>
      </Modal>
      {!live ? 
      <Button
        className="editbtn"
        size="small"
        onClick={setGoLiveModal}
      >
        Go Live
      </Button>
      :
      <Button
      className="editbtn"
      size="small"
      onClick={() => turnOffClass()}
    >
      Turn off live class
    </Button>
    }
    </div>
  );
};

export default Liveclassmodal