import React, { Component } from 'react'
import { Message, Grid, Icon, Button, Form, Select, Image, Step, Checkbox, Label, Loader, Dropdown, Header, Modal, Dimmer } from 'semantic-ui-react';
import { bindActionCreators } from "redux"
import { connect } from 'react-redux'
import moment from 'moment'
import { uploadFile, getUploadedFiles, clearUploadFile, cancelFileUpload } from "../../utils/actions/user";

class FileModal extends Component {
  constructor(props) {
    super(props)
    this.uploadFileRef = React.createRef()
    this.state = {
      loading: false,
      openModal: false,
      success: null,
      error: null
    }
  }

  componentDidMount() {
    this.props.getUploadedFiles()
  }

  componentWillReceiveProps(nextProps) {
    const { openModal, users } = nextProps
    const { isuploadingFile, uploadFileSuccess, uploadFileError } = users
    this.setState({
      openModal,
      loading: isuploadingFile
    })
    if (uploadFileError) {
      this.setState({
        error: 'Something went wrong'
      })
      this.uploadFileRef.current.value = ''
    }
    if (uploadFileSuccess) {
      this.props.getUploadedFiles()
      this.setState({
        success: 'File uploaded successfully'
      })
      this.uploadFileRef.current.value = ''
      this.props.clearUploadFile()
    }
  }

  dismissError = () => {
    this.setState({
      error: null
    })
  }

  dismissSuccess = () => {
    this.setState({
      success: null
    })
  }

  triggerUploadVid = () => {
    this.uploadFileRef.current.click()
  }

  onChangeHandlerFile = (e) => {
    // console.log(e.target.files[0])
    this.setState({
      loading: true,
      error: null
    })
    var file = e.target.files[0];
    var size = (file.size / (1024 * 1024)).toFixed(2);
    if (size > 55) {
      this.setState({
        error: 'Maximun video size is 55MB'
      })
    } else {
      this.props.uploadFile(file)
    }
  }

  closeModal = () => {
    this.setState({
      openModal: false
    })
    this.props.closeModalHandler()
  }

  stopUpload = () => {
    this.uploadFileRef.current.value = ''
    this.props.cancelFileUpload()
    this.props.clearUploadFile()
  }

  render() {
    const { openModal, loading, error, success } = this.state
    const { files } = this.props.users
    return (
      <div className="">
        <Modal open={openModal} onClose={this.closeModal} className="y-file-modal" centered={false}>
          <Modal.Header className="y-modal-header">
            <div className="modal-header">
              <form>
                <input type="file" name="file" className="video-uploadfile" ref={this.uploadFileRef} onChange={this.onChangeHandlerFile} />
              </form>
              <Button type="button" onClick={this.triggerUploadVid} disabled={loading} className="primary-btn">
                Upload File
                </Button>
            </div>
          </Modal.Header>
          <Modal.Content>
            <div className="y-video-holder">
              {
                loading ? <Dimmer active inverted>
                  <Loader inverted>
                    Uploading
                    <br />
                    <Button className="primary-btn" onClick={() => this.stopUpload()}>Stop</Button>
                  </Loader>
                </Dimmer> : null
              }
              {
                error ? <Message negative className="y-feedback"
                  onDismiss={this.dismissError}
                >
                  <p>{error}</p>
                </Message> : null
              }
              {
                success ? <Message positive className="y-feedback"
                  onDismiss={this.dismissSuccess}
                >
                  <p>{success}</p>
                </Message> : null
              }
              <Grid>
                <Grid.Row columns="3">
                  {
                    files && files.map((item) => (
                      <Grid.Column key={item._id}
                      mobile="16"
                        onClick={() => this.props.selectFile(item)}
                      >
                        <div className="file-item">
                          {/* <Icon name="video play" /> */}
                          <div className="file-details">
                            <Label as='a' tag>
                              {
                                item.fileType
                              }
                            </Label>
                            <p>{item.name}</p>
                            <p>{moment(item.dateCreated).fromNow()}</p>
                          </div>
                        </div>
                      </Grid.Column>
                    ))
                  }
                </Grid.Row>
              </Grid>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users
})

const matchDispatchToProps = (dispatch) => bindActionCreators({
  getUploadedFiles,
  clearUploadFile,
  cancelFileUpload,
  uploadFile
}, dispatch)

export default connect(mapStateToProps, matchDispatchToProps)(FileModal)