import React, { Component, useEffect, useState } from "react"
import Navbar from "../../components/navbar";
import { Button, Icon, Container, Grid, GridColumn, GridRow, Pagination } from "semantic-ui-react";
import Searchbox from "../../components/courses/searchbox";
import Coursecard from "../../components/courses/coursecard";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses, resetCourseAction } from "../../utils/actions/course";
import Footer from "../../components/footer/footer";
import Spinner from "../../components/ui/spinner";
import Enroll from "../../components/footer/enroll";

const Courses = ({
  history
}) => {
  const dispatch = useDispatch()
  const coursesObj = useSelector(s => s.course);
  const totalResults = useSelector(s => s.course.totalResults);
  const auth = useSelector(s => s.auth.auth);
  const [limit, setLimit] = useState(9)
  const [pageNum, setPageNum] = useState(1)
  useEffect(() => {
    // setTimeout(() => {
      dispatch(fetchCourses({limit, pageNum}))
    // }, 500);
  }, [])
  useEffect(() => {
    dispatch(resetCourseAction())
  }, [coursesObj.isCoursesLoaded])
  const handlePagination = (page) => {
    setPageNum(page)
    dispatch(fetchCourses({limit, pageNum: page}))
}
  return (
    coursesObj.isCoursesLoaded ?
      <div className="x-courses">
        <Navbar
          history={history}
          auth={auth}
        />

        <div className="x-main">
          <Container fluid>
            <h1> Courses </h1>
            <Searchbox
                history={history}
                type={"courses"}
                category={"all"}
            />
            <Grid>
              <GridRow columns={window.innerWidth > 760 ?"3": "1"}>
                {coursesObj.courses.map((course) => (
                  <GridColumn key={course.id} key={course._id}>
                    <Coursecard
                      history={history}
                      course={course}
                    />
                  </GridColumn>

                ))}
              </GridRow>
              <GridRow textAlign="center">
                <GridColumn>
                <Pagination
                    boundaryRange={0}
                    defaultActivePage={pageNum}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={Math.ceil(totalResults/limit)}
                    onPageChange={(e, data) => handlePagination(data.activePage)}
                  />
                </GridColumn>
              </GridRow>
            </Grid>
          </Container>
        </div>
        <Enroll history={history} auth={auth}/>
        <Footer
          auth={auth}
        />
      </div> :
      <Spinner />
  )
}

export default Courses