import React, { Component } from "react"
import { Icon, Image, Input, Button, Dropdown } from "semantic-ui-react";
import $ from 'jquery'
import { Link } from "react-router-dom"
import { fetchNotifications, turnOffStatus, getAppSettings } from "../../utils/actions/user";
import { offtriggerNewMessage } from "../../utils/actions/course";
import { bindActionCreators } from "redux";
import { connect } from "react-redux"
import { NotificationContainer, NotificationManager } from 'react-notifications';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNav: false,
      notifications: [],
      showTopPromo: {
        display: false,
        text: ''
      }
    }
  }

  componentWillMount() {
    this.props.getAppSettings()
    this.props.fetchNotifications()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.notifications) {
      this.setState({ notifications: nextProps.notifications })
    }
    if (nextProps.appsettings) {
      this.setState({
        showTopPromo: nextProps.appsettings.showTopPromo
      }, () => {
      })
    }
    if (nextProps.newMessage) {
      // console.log('new message')
      NotificationManager.info('New message recieved')
      process.nextTick(() => {
        this.props.offtriggerNewMessage()
      })
    }
  }

  componentDidMount() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  toggleMobileNav = () => {
    const { mobileNav } = this.state
    this.setState({
      mobileNav: !mobileNav
    })
  }

  handleLogout = (e) => {
    e.preventDefault();
    this.props.turnOffStatus()
    window.localStorage.clear();
    window.location.assign('/')
  }


  render() {
    const { auth } = this.props
    const { mobileNav, notifications, showTopPromo } = this.state
    let iconclass = mobileNav ? 'change' : ''

    return (
      <div className="x-navbar">

        {
          showTopPromo.display ?
            <div className="flash">
              {
                showTopPromo.text
              }
            </div>
            :
            null
        }
        <nav>
          {!auth.isAuthenticated ? <ul className={`nav ${!showTopPromo.display ? 'noflash' : ''}`}>
            <li>
              <Link to="/"><img src="/images/logo.png" alt="Logo" /></Link>
            </li>
            <div className={`nav-mobile ${iconclass}`} onClick={this.toggleMobileNav}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
            <li>
              <Link to="/"> Home </Link>
            </li>
            <li>
              <Link to="/courses">Courses</Link>
            </li>
            {/* <li>
              <Link to="/signin"> About </Link>
            </li> */}
            <li>
              <Link to="/instructors"> Teachers </Link>
            </li>
            {/* 
            <li style={{ marginRight: '2em' }}>
              <Link to="/contact-us"> Contact </Link>
            </li> */}
            <li>
              <Button size="large" onClick={() => this.props.history.push("/signin")}>Login / Signup</Button>
            </li>
          </ul>
            :
            <ul className={`nav ${!showTopPromo.display ? 'noflash' : ''}`}>
              <li>
                <Link to="/"><img src="/images/logo.png" alt="Logo" /></Link>
              </li>
              <div className={`nav-mobile ${iconclass}`} onClick={this.toggleMobileNav}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
              </div>
              <li>
                <Link to="/instructors"> Teachers </Link>
              </li>
              <li>
                <Link to="/messages"> Messages </Link>
              </li>
              {auth.user.instructor &&
                <li>
                  <Link to="/dashboard"> Dashboard </Link>
                </li>}
              {/* {!auth.user.instructor && */}
              <li>
                {/* <a href="/my_courses">Playlist</a> */}
                  <Link to="/my_courses">Playlist</Link>
              </li>

              <li>
                <Link to="/courses"> Courses </Link>
              </li>
              {/* <li>
                <Link to="/messages"> Messages </Link>
              </li> */}
              {/* {
                auth.user.instructor ? <li>
                  <Link to="/performance-"> Performance </Link>
                </li>
                  : null
              } */}

              <li>
                <Dropdown pointing direction="left" text={
                  <Link to="#"> <Icon name="bell outline" /> </Link>
                } icon={null}>
                  <Dropdown.Menu>
                    <Dropdown.Header icon={null} >
                      {notifications.length} Notifications
                    </Dropdown.Header>
                    {notifications.map((option, key) => (
                      <Dropdown.Item key={key} text={option.description} />
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                <Dropdown className="x-profile-dropdown" direction="left" text={
                  <div className="x-avatar"><Image src={auth.user.photoURL} avatar rounded /> {auth.user.forename} </div>
                } icon={null}>
                  <Dropdown.Menu>
                    {/* <Dropdown.Header icon={null} content='Notification' /> */}
                    <Dropdown.Item text="My profile" onClick={() => this.props.history.push(auth.user.instructor ? "/instructors/" + auth.user.username : "/profile")} />
                    <Dropdown.Item text="Settings" onClick={() => this.props.history.push("/settings")} />
                    <Dropdown.Item text=" Logout" onClick={this.handleLogout} />
                  </Dropdown.Menu>
                </Dropdown>

              </li>
            </ul>}
        </nav>
        <NotificationContainer />
        {
          this.state.mobileNav ? <div className={`sidebar animated slideInLeft  ${!showTopPromo.display ? 'noflash' : ''}`}>
            {!auth.isAuthenticated ? <ul className="">

              <li>
                <Link to="/"> Home </Link>
              </li>
              <li>
                <Link to="/courses">Courses</Link>
              </li>
              {/* <li>
                <Link to="/about"> About </Link>
              </li> */}
              <li>
                <Link to="/instructors"> Teachers </Link>
              </li>

              {/* <li style={{ marginRight: '2em' }}>
                <Link to="/contact-us"> Contact </Link>
              </li> */}
              <li>
                <Button size="large" onClick={() => this.props.history.push("/signin")}>Login / Signup</Button>
              </li>
            </ul>
              :
              <ul className="">
                <li>
                  <Link to="/messages"> Messages </Link>
                </li>
                {auth.user.instructor &&
                  <li>
                    <Link to="/dashboard"> Dashboard </Link>
                  </li>}
                {/* {!auth.user.instructor && */}
                <li>
                {/* <a href="/my_courses">Playlist</a> */}
                  <Link to="/my_courses">Playlist</Link>
                </li>

                <li>
                  <Link to="/courses"> Courses </Link>
                </li>
                {/* <li>
                <Link to="/messages"> Messages </Link>
              </li> */}
                {/* {
                  auth.user.instructor ? <li>
                    <Link to="/performance-"> Performance </Link>
                  </li>
                    : null
                } */}

                {/* <li>
                  <Dropdown pointing direction="left" text={
                    <Link to="#">{notifications.length} Notification </Link>
                  } icon={null}>
                    <Dropdown.Menu>
                      <Dropdown.Header icon={null} content='Notification' />
                        {notifications.map((option) => (
                          <Dropdown.Item key={option.value} text={option.description} />
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </li> */}
                <li>
                  <Link to={auth.user.instructor ? "/instructors/" + auth.user.username : "/profile"}> Profile </Link>
                </li>
                <li>
                  <Link to="/settings"> Settings </Link>
                </li>
                <li>
                  <Link to="/instructors"> Teachers </Link>
                </li>
                <li>
                  <Link to="" onClick={this.handleLogout}> Logout </Link>
                </li>
              </ul>}
          </div> : null
        }
      </div>
    )
  }
}

const matchDispatchToProps = dispatch => bindActionCreators({
  fetchNotifications,
  turnOffStatus,
  getAppSettings,
  offtriggerNewMessage
}, dispatch)
const mapStateToProps = state => ({
  notifications: state.users.notifications,
  appsettings: state.course.appsettings,
  newMessage: state.course.newMessage
})
export default connect(mapStateToProps, matchDispatchToProps)(Navbar)