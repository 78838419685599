import React, { Component } from "react";
import axios from "axios";
import {
  Grid,
  Button,
  Image,
  Icon,
  Input,
  Search,
  Segment,
  Select,
  Responsive,
  GridRow,
  GridColumn,
  Dropdown,
  Menu
} from "semantic-ui-react";
import baseUrl from "../../utils/config";
import { categories } from "../../assets/course";
import 'semantic-ui-css/semantic.min.css'

export default class Searchbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      online: false,
      results: [],
      query: "",
      loadedContent: false,
      type: "",
      category: "all"
    };
  }
  componentWillMount() {
    var { type, query, category } = this.props;
    this.setState({ type: type, query, category: category || 'all' });
  }
  typing = (e, data) => {
    this.setState({ [data.name]: data.value });
  };
  cuttext(text, maxlength) {
    if (text.length > maxlength) {
      var newtext = text.slice(0, maxlength);
      newtext += "...";
      return newtext;
    } else return text;
  }
  submit = e => {
    e.preventDefault();
    if(query == '') return false;
    var { query, type, category } = this.state;
    query = query.replace(/[" "]/gi, "-");
    var url = `/search?query=${query}&type=${type}`;
    if (category && category != "all") url += `&category=` + category;
    this.props.history.push(url);
  };
  select = (e, props) => {
    if (props.result.status)
      return this.props.history.push(`/profile/${props.result.username}`);
    else
      return this.props.history.push(
        `/section/${props.result.section.replace(/[" "]/gi, "-")}/${
          props.result.slug
        }`
      );
  };
  resultRenderer = props => {
    if (props.status)
      return (
        <Grid columns="equal">
          <Grid.Column width="3" textAlign="center">
            <Image src={props.dpUrl} circular avatar />
          </Grid.Column>
          <Grid.Column>
            <p>
              <b className="xtransform"> {props.username}</b>
              <br />
              <span style={{ color: "grey" }}>{props.status} </span>
            </p>
          </Grid.Column>
        </Grid>
      );
    else
      return (
        <Grid columns="equal">
          <Grid.Column
            width="5"
            className="bg-grey xtransform"
            style={{ color: "grey", fontWeight: "bolder" }}
          >
            {" "}
            <small>{props.section}</small>
          </Grid.Column>
          <Grid.Column>
            <b className="xtransform">{props.query} </b> <br />
            <p style={{ color: "grey" }}>See more..</p>
          </Grid.Column>
        </Grid>
      );
  };

  render() {
    const options = [
      {
        key: "thread",
        text: "Courses",
        value: "courses",
        icon: "keyboard outline"
      },
      {
        key: "people",
        text: "Instructors",
        value: "instructors",
        icon: "user outline"
      }
    ];
    const subcat = [];
    categories.map(cat => cat.subcategories.map(sub => subcat.push(sub)));
    const filter = [
      {
        key: "thread",
        text: "All categories",
        value: "all",
        icon: "keyboard outline"
      },
      ...subcat
      // { key: 'people', text: 'People', value: 'people', icon: "user outline" },
    ];
    var { results, isLoading, query, type, category } = this.state;
    return (
      <div className="x-searchbox">
        <Grid>
          <GridRow>
            <GridColumn computer="10" mobile="16">
              <form onSubmit={this.submit}>
                <Input
                  required
                  size="massive"
                  type="text"
                  className="inputWrapper"
                  action
                >
                  <Input
                    placeholder="Search by Keyword"
                    name="query"
                    defaultValue={query}
                    onChange={this.typing}
                    icon={null}
                    style={{ width: "100%" }}
                    loading={isLoading}
                    results={results}
                    resultRenderer={this.resultRenderer}
                    onSearchChange={this.search}
                    onResultSelect={this.select}
                    className={`${this.props.home ? "from-home" : ""}`}
                  />
                  <Select
                    options={options}
                    onChange={this.typing}
                    defaultValue={type}
                    name="type"
                    className={`${this.props.home ? "from-home" : ""}`}
                  />
                 {window.innerWidth > 780 && <Button
                    type="submit"
                    className={`${this.props.home ? "from-home" : ""}`}
                  >
                    Search
                  </Button>}
                  
                </Input>
              </form>
            </GridColumn>
            {this.props.home ? null : (
              <GridColumn
                computer="6"
                textAlign="right"
                className="x-right"
                mobile="16"
              >
                {/* <Input required size="big" name="query" onChange={this.typing} type='text' className="inputWrapper" action>
                    <Select options={filter} defaultValue={category} onChange={this.typing}  name="category" />
                    <Button color="grey" className="x-hidden-mobile"> <Icon name="filter" /></Button>
                  </Input> */}

               <div className="x-flex">
               <Menu>
                  <Dropdown text={this.state.category == 'all' ? 'All Categories' : this.state.category} pointing className="link item">
                    <Dropdown.Menu>
                      <Dropdown.Header>Filter</Dropdown.Header>
                      {categories.map(cat => (
                        <Dropdown.Item className="x-search-item" style={{padding: '0 !important'}}>
                          <Dropdown direction={window.innerWidth > 780 ? null : 'left'} className="x-search-dd" pointing={null} text={cat.text} >
                            <Dropdown.Menu>
                              <Dropdown.Header>Categories</Dropdown.Header>
                              {cat.subcategories.map(sub => (
                                <Dropdown.Item onClick={(e)=> this.typing(e, {name: "category", value: sub.text})}>{sub.text}</Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu>
                    <Button color="grey" > <Icon name="filter" /></Button>
               </div>
              </GridColumn>
            )}

          {window.innerWidth < 780 && <GridColumn width="16">
            <br />
            <Button
                    type="submit"
                    fluid
                    className={`${this.props.home ? "from-home" : ""}`}
                  >
                    Search
                  </Button>
          </GridColumn>}
          </GridRow>
        </Grid>
      </div>
    );
  }
}
