import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import Navbar from "../../components/navbar";
import Footer from "../../components/footer/footer";
import { Message, Grid, Icon, Button, Form, Select, Image, GridColumn, Pagination } from 'semantic-ui-react';
import Spinner from "../../components/ui/spinner";
import moment from 'moment'
import { getInstructor } from '../../utils/actions/user'
import Coursecard from "../../components/courses/coursecard";

class InstructorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      instructor: {
        courses: []
      }
    }
  }

  componentWillMount() {
    if (this.props.match.params.slug) {
      this.props.getInstructor(this.props.match.params.slug)
    }
  }

  componentWillReceiveProps(nextProps) {
    var { instructor, isLoadingInstructor, getInstructorSuccess, getInstructorError } = nextProps.users
    this.setState({
      loading: isLoadingInstructor
    })
    if (getInstructorSuccess) {
      this.setState({
        instructor
      })
    }
    if (this.props.match.params.slug != nextProps.match.params.slug) {
      this.props.getInstructor(nextProps.match.params.slug)

    }
  }

  render() {
    const { history, auth } = this.props
    const { loading, instructor } = this.state
    const { photoURL, forename, surname, courses, lessons, description, shortBio, twitterLink, instagramLink, username, certification, experience, programm, institute, programCompleted } = instructor
    return (
      loading ?
        <Spinner /> :
        <div className="y-instructor">
          <Navbar
            history={history}
            auth={auth}
          />
          <div className="y-main">
            <Grid>
              <Grid.Row>
                <GridColumn computer="10" mobile="16">
                  <div className="img-holder showononlysmall" style={{ backgroundImage: `url(${photoURL})` }}>
                    {/* <img src={photoURL} alt="" /> */}
                  </div>
                  {auth.user.username == username && <center className="x-hidden-desktop x-hidden-tablet"> <Button onClick={() => this.props.history.push("/profile")} style={{ marginBottom: '3em' }}>Edit Profile</Button> </center>}

                  <div className="details">
                    <h3 className="top-most-heading">Instructor</h3>
                    <h1 className="y-name">{`${forename} ${surname}`} </h1>
                    <p>{shortBio}</p>
                    <div className="students">
                      <div className="tally">
                        <h4 className="top-most-heading">Total Courses</h4>
                        <h1>{`${courses.length} `}
                          Courses
                        </h1>
                      </div>
                      <div className="tally">
                        <h4 className="top-most-heading">Total Lessons</h4>
                        <h1>{`${lessons} `}
                          Lessons
                        </h1>
                      </div>
                    </div>
                    {description && <div className="y-aboutme">
                      <h3 className="top-most-heading">About Me</h3>
                      <div>
                        {description}
                      </div>
                    </div>}

                    <Grid>
                      {certification && certification.length ?
                        <GridColumn computer="8" mobile="16">
                          <div className="y-aboutme" style={{ marginTop: '2em' }}>
                            <h3 className="top-most-heading">Certification</h3>
                            <div>
                              <ul>
                                {certification.map(cert => <li>
                                  <p style={{ marginTop: "1em" }}>Institute : {cert.institute}</p>
                                  <p>Title : {cert.title}</p>
                                  <p>Date Completed : {cert.dateCompleted}</p>
                                </li>)}
                              </ul>
                            </div>
                            <br />
                          </div>
                        </GridColumn> : null}
                      <GridColumn computer="8" mobile="16">
                        {experience && <div className="y-aboutme" style={{ marginTop: '2em' }}>
                          <h3 className="top-most-heading">Experience</h3>
                          <div>
                            <ul>
                              {experience.map(exp => <li>
                                <p style={{ marginTop: "1em" }}>Title : {exp.title}</p>
                                <p>Location : {exp.location}</p>
                              </li>)}
                            </ul>
                          </div>
                        </div>}
                      </GridColumn>
                      {programm && <GridColumn computer="16" mobile="16">
                        <div className="y-aboutme" style={{ marginTop: '0.5em' }}>
                          <h3 className="top-most-heading">Education</h3>
                          <div>
                            <ul>
                              <li>
                                <p>Programme : {programm}</p>
                                <p>Institute : {institute}</p>
                                <p>Date completed : {moment(programCompleted).fromNow()} </p>
                              </li>
                            </ul>
                          </div>
                          <br />
                        </div>
                      </GridColumn>}
                    </Grid>
                  </div>

                  {/* <div className="details">
                    <h3 className="top-most-heading">Instructor</h3>
                    <h1 className="y-name">{`${forename} ${surname}`} </h1>
                    <p>{shortBio}</p>
                    <div className="students">
                      <div className="tally">
                        <h4 className="top-most-heading">Total Courses</h4>
                        <h1>{`${courses.length} `}
                        Courses
                      </h1>
                      </div>
                      <div className="tally">
                        <h4 className="top-most-heading">Total Lessons</h4>
                        <h1>{`${lessons} `}
                        Lessons
                      </h1>
                      </div>
                    </div>
                    {description && <div className="y-aboutme">
                      <h3 className="top-most-heading">About Me</h3>
                      <div>
                        {description}
                      </div>
                    </div>}
                    
                    <Grid>
                    {certification}
                {certification && certification.length ? 
                    <GridColumn computer="8" mobile="16">
                <div className="y-aboutme" style={{marginTop: '2em'}}>
                      <h3 className="top-most-heading">Certification</h3>
                      <div>
                        <ul>
                        {certification.map(cert => <li>
                            <p  style={{marginTop: "1em"}}>Institute : {cert.institute}</p>
                            <p>Title : {cert.title}</p>
                            <p>Date Completed : {cert.dateCompleted}</p>
                          </li>)}
                        </ul>
                      </div>
                      <br />
                    </div>
                    </GridColumn>: null}
                    <GridColumn computer="8" mobile="16">
                    {experience && <div className="y-aboutme" style={{marginTop: '2em'}}>
                      <h3 className="top-most-heading">Experience</h3>
                      <div>
                        <ul>
                        {experience.map(exp => <li>
                            <p style={{marginTop: "1em"}}>Title : {exp.title}</p>
                            <p>Location : {exp.location}</p>
                          </li>)}
                        </ul>
                      </div>
                    </div>}
                </GridColumn>
                {programm &&   <GridColumn computer="16" mobile="16">
                    <div className="y-aboutme" style={{marginTop: '0.5em'}}>
                      <h3 className="top-most-heading">Education</h3>
                      <div>
                        <ul>
                         <li>
                            <p>Programme : {programm}</p>
                            <p>Institute : {institute}</p>
                            <p>Date completed : {moment(programCompleted).fromNow()} </p>
                          </li>
                        </ul>
                      </div>
                      <br />
                    </div>
                </GridColumn>}
                    </Grid>
                  </div> */}
                </GridColumn>

                <GridColumn computer="6" mobile="16">
                  <div className="img-holder showononlylarge" style={{ backgroundImage: `url(${photoURL})` }}>
                    {/* <img src={photoURL} alt="" /> */}
                  </div>

                  {auth.user.username == username && <center>  <br /> <Button onClick={() => this.props.history.push("/profile")}>Edit Profile</Button></center>}

                  <div className="social-links">
                    <Button size="big" circular icon="twitter" onClick={() => window.open("https://twitter.com/" + twitterLink || "masterclass", '_blank')} />
                    <Button size="big" circular icon="instagram" onClick={() => window.open("https://instagram.com/" + instagramLink || "masterclass", '_blank')} />
                  </div>
                  {/* <div className="seeall">
                    <Button onClick={() => this.props.history.push('/instructors')}>
                      Sell All Instructors
                   </Button>
                  </div> */}
                </GridColumn>
              </Grid.Row>
            </Grid>
            <div className="y-mycourses">
              <h3 className="top-most-heading">Courses</h3>
              <Grid>
                <Grid.Row columns={window.innerWidth > 760 ? "3" : "1"}>
                  {courses.map((course) => (
                    <Grid.Column key={course._id} >
                      <Coursecard
                        history={history}
                        course={course}
                      />
                    </Grid.Column>
                  ))}
                </Grid.Row>
                <Grid.Row textAlign="center">
                  <Grid.Column>
                    {/* <Pagination
                      boundaryRange={0}
                      defaultActivePage={1}
                      ellipsisItem={null}
                      firstItem={null}
                      lastItem={null}
                      siblingRange={1}
                      totalPages={10}
                    /> */}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
          <Footer
            auth={auth} />
        </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth.auth,
  users: state.users
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getInstructor
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstructorProfile);

