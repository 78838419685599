import * as CONSTANT from "../constant/course";
import Axios from "axios";
import baseUrl from "../config";
const CancelToken = Axios.CancelToken;
let cancel;

export const recieveCourse = payload => ({ type: CONSTANT.FETCH_COURSE, payload });
export const recieveSearchCourses = payload => ({ type: CONSTANT.FETCH_SEARCH_COURSES, payload });
export const receiveSearchInstructors = payload => ({ type: CONSTANT.FETCH_SEARCH_INSTRUCTORS, payload });
export const recieveCourses = payload => ({ type: CONSTANT.FETCH_COURSES, payload });
export const recieveMyCourses = payload => ({ type: CONSTANT.FETCH_MY_COURSES, payload });
export const recieveUploadedCourses = payload => ({ type: CONSTANT.FETCH_UPLOADED_COURSES, payload });
export const loadingCourse = () => ({ type: CONSTANT.COURSE_REQUESTING });
export const resetCourseAction = () => ({ type: CONSTANT.RESET_COURSE_ACTION });
export const clearCourse = () => ({ type: CONSTANT.CLEAR_COURSE_DATA });

export const requestUploadImage = () => ({ type: CONSTANT.UPLOAD_COURSEIMAGE_REQUEST });
export const uploadImageSuccess = payload => ({ type: CONSTANT.UPLOAD_COURSEIMAGE_SUCCESS, payload });
export const uploadImageError = payload => ({ type: CONSTANT.UPLOAD_COURSEIMAGE_ERROR, payload });

export const requestUploadVideo = () => ({ type: CONSTANT.UPLOAD_COURSEVIDEO_REQUEST });
export const uploadVideoSuccess = (payload) => ({ type: CONSTANT.UPLOAD_COURSEVIDEO_SUCCESS, payload });
export const uploadVideoError = payload => ({ type: CONSTANT.UPLOAD_COURSEVIDEO_ERROR, payload });

export const resetAfterUploadVideo = payload => ({ type: CONSTANT.RESETAFTERUPLOAD_COURSEVIDEO, payload });

export const requestClearUploadImage = () => ({ type: CONSTANT.CLEAR_UPLOAD_COURSEIMAGE });
export const requestClearUploadVideo = () => ({ type: CONSTANT.CLEAR_UPLOAD_COURSEVIDEO });

export const updateUploadedVideos = (payload) => ({
  type: CONSTANT.UPDATE_UPLOADED_VIDEOS,
  payload
})

export const requestEditCourse = () => ({
  type: CONSTANT.REQUEST_EDITCOURSE,
})
export const editCourseSuccess = () => ({
  type: CONSTANT.EDITCOURSE_SUCCESS
})
export const editCourseError = (payload) => ({
  type: CONSTANT.EDITCOURSE_ERROR,
  payload
})
export const clearEditCourse = () => ({
  type: CONSTANT.CLEAR_EDITEDCOURSE,
})


export const requestAddCourse = () => ({
  type: CONSTANT.REQUEST_ADDCOURSE,
})
export const addCourseSuccess = (payload) => ({
  type: CONSTANT.ADDCOURSE_SUCCESS,
  payload
})
export const addCourseError = (payload) => ({
  type: CONSTANT.ADDCOURSE_ERROR,
  payload
})
export const requestClearaddedCourseId = () => ({
  type: CONSTANT.CLEAR_ADDEDCOURSE
})

export const requestAddCurriculum = () => ({
  type: CONSTANT.REQUEST_ADDCURRICULUM,
})
export const addCurriculumSuccess = () => ({
  type: CONSTANT.ADDCURRICULUM_SUCCESS
})
export const addCurriculumError = (payload) => ({
  type: CONSTANT.ADDCURRICULUM_ERROR,
  payload
})
export const requestClearaddCurriculum = () => ({
  type: CONSTANT.CLEAR_ADDCURRICULUM
})
export const paymentSuccess = () => ({
  type: CONSTANT.CHARGED_SUCCESSFULLY
})
export const paymentError = (payload) => ({
  type: CONSTANT.CHARGED_FAILED, payload
})
export const resetPayment = () => ({
  type: CONSTANT.RESET_PAYMENT
})

// delete course
export const requestDeleteCourse = () => ({
  type: CONSTANT.REQUEST_DELETE_COURSE,
})
export const deleteCourseSuccess = (payload) => ({
  type: CONSTANT.DELETE_COURSE_SUCCESS,
  payload
})
export const deleteCourseError = (payload) => ({
  type: CONSTANT.DELETE_COURSE_ERROR,
  payload
})
export const clearDeleteCourse = () => ({
  type: CONSTANT.CLEAR_DELETE_COURSE,
})

export const triggerNewMessageHandler = () => ({
  type: CONSTANT.TRIGGER_NEWMESSAGE,
})

export const triggerNewMessageHandlerOff = () => ({
  type: CONSTANT.TRIGGER_NEWMESSAGE_OFF,
})

// --- FETCH COURSES
export const fetchCourses = ({ limit, pageNum }) => dispatch => {
  dispatch(loadingCourse());

  Axios.get(baseUrl + '/courses/getCourses?limit=' + limit + '&pageNum=' + pageNum)
    .then((response) => {
      var { courses, totalResults } = response.data;
      dispatch(recieveCourses({ courses, totalResults }))
    }).catch((err) => {
      console.log(err)
    })
};

// --- FETCH SEARCH COURSES
export const fetchSearchCourses = ({ query, category, type, pageNum, limit, }) => dispatch => {
  dispatch(loadingCourse());

  Axios.post(baseUrl + '/courses/search?limit=' + limit, { query, category, type, pageNum, limit })
    .then((response) => {
      var { courses, instructors, totalSearchResults } = response.data;
      if (instructors) dispatch(receiveSearchInstructors({ instructors, totalSearchResults }));
      else dispatch(recieveSearchCourses({ courses, totalSearchResults }))
    }).catch((err) => {
      console.log(err)
    })
};

// --- FETCH COURSES
export const fetchMyCourses = ({ pageNum, limit }) => dispatch => {
  dispatch(loadingCourse());

  Axios.get(baseUrl + '/courses/getMyCourses?limit=' + limit + "&pageNum=" + pageNum)
    .then((response) => {
      var { courses, totalResults } = response.data;
      dispatch(recieveMyCourses({ courses, totalResults }))
    }).catch((err) => {
      console.log(err)
    })
};
// --- FETCH  UPLOADED COURSES
export const fetchUploadedCourses = () => dispatch => {
  dispatch(loadingCourse());
  Axios.get(baseUrl + '/courses/getUploadedCourses')
    .then((response) => {
      var { courses, totalCourses } = response.data;
      dispatch(recieveUploadedCourses({ courses, totalCourses }))
    }).catch((err) => {
      console.log(err)
    })
};

// --- FETCH COURSE
export const fetchCourse = (slug) => dispatch => {
  dispatch(loadingCourse());

  Axios.get(baseUrl + '/courses/getCourse?slug=' + slug)
    .then((response) => {
      var { course, lectures, sections, watched, reviews} = response.data;
      course.lectures = lectures
      course.sections = sections
      course.reviews = reviews
      lectures.map(lecture => {
        watched.map(watched => {
          if (lecture._id == watched.lectureId) {
            lecture.watched = true
          }
        })
      })
      dispatch(recieveCourse(course))
    }).catch((err) => {
      console.log(err)

    })
};


// --- FETCH COURSE
export const saveMyCourse = ({ courseId, trainingTime, duration, lectureId, creatorId, percentWatched  }) => dispatch => {

  Axios.post(baseUrl + '/courses/addMyCourse', { courseId, duration, lectureId, creatorId, trainingTime, percentWatched })
    .then((response) => {
      var { course, lectures } = response.data;
      course.lectures = lectures
    }).catch((err) => {
      console.log(err)

    })
};

// --- add to watched COURSEs
export const watchedCourses = ({ courseId, lectureId, watched }) => dispatch => {

  Axios.post(baseUrl + '/courses/watched', { courseId, lectureId, watched })
    .then((response) => {
      var { success } = response.data;
      console.log(success)
    }).catch((err) => {
      console.log(err)
    })
};
// --- submit rating
export const rateCourse = ({ courseId, creatorId, description, rating }) => dispatch => {

  Axios.post(baseUrl + '/courses/rateCourse', { courseId, creatorId, description, rating })
    .then((response) => {
      var { success } = response.data;
      window.location.reload()
    }).catch((err) => {
      console.log(err)
    })
};
// --- FETCH COURSE
export const payForCourse = (payload) => dispatch => {

  Axios.post(baseUrl + '/courses/charge', { ...payload })
    .then((response) => {
      var { success } = response.data;
      console.log(success)
      dispatch(paymentSuccess())
    })

    .catch((err) => {
      let message
      if (err.response) {
        message = err.response.data.message || err.response.data.error
      }
      dispatch(paymentError(message || 'Something went wrong'))
    })
};
// -- UPLOAD COURSE IMAGE
export const uploadCourseImage = (data) => dispatch => {
  dispatch(requestUploadImage());
  let formData = new FormData()
  formData.append('image', data)
  Axios.post(baseUrl + '/courses/uploadcourseimage', formData)
    .then((response) => {
      dispatch(uploadImageSuccess(response.data.photoURL))
    }).catch((error) => {
      dispatch(uploadImageError('Something went wrong'));
    })
}

// -- UPLOAD COURSE IMAGE
export const uploadCourseVideo = (data, duration) => dispatch => {
  dispatch(requestUploadVideo());
  let formData = new FormData()
  formData.append('video', data)
  formData.append('duration', duration)
  Axios.post(baseUrl + '/courses/addVideo', formData, {
    // specify a cancel token
    cancelToken: new CancelToken(c => {
      // this function will receive a cancel function as a parameter
      cancel = c;
    })
  })
    .then((response) => {
      // console.log(response.data.video.videoUrl)
      dispatch(uploadVideoSuccess({videoUrl: response.data.vid}))
      setTimeout(() => {
        dispatch(requestClearUploadVideo())
      }, 10)
    }).catch((thrown) => {
      if (Axios.isCancel(thrown)) {
        console.log(thrown.message);

      } else {
        dispatch(uploadVideoError('Something went wrong'));
      }
    })
}

export const cancelCourseVideo = () => dispatch => {
  console.log('reach here!')
  cancel('Request canceled.');
}

export const getUploadedVideos = () => dispatch => {
  Axios.get(baseUrl + '/courses/getVideos')
    .then((response) => {
      var { videos } = response.data;
      dispatch(updateUploadedVideos(videos))
    }).catch((err) => {
      console.log(err)
    })
}

export const clearUploadImage = () => dispatch => {
  dispatch(requestClearUploadImage());
}

export const clearUploadVideo = () => dispatch => {
  dispatch(requestClearUploadVideo());
}


export const addCourse = (details) => dispatch => {
  dispatch(requestAddCourse());
  Axios.post(baseUrl + '/courses/addCourse', details)
    .then((response) => {
      // dispatch(addCourseSuccess(response.data.course._id))
      dispatch(addCourseSuccess())
    })
    .catch((err) => {
      let message
      if (err.response) {
        message = err.response.data.message
      }
      dispatch(addCourseError(message || 'Something went wrong'))
    })
}

export const editCourse = (details) => dispatch => {
  dispatch(requestEditCourse());
  Axios.post(baseUrl + '/courses/editCourse', details)
    .then((response) => {
      // dispatch(addCourseSuccess(response.data.course._id))
      dispatch(editCourseSuccess())
    })
    .catch((err) => {
      let message
      if (err.response) {
        message = err.response.data.message
      }
      // console.log(message)
      dispatch(editCourseError(message || 'Something went wrong'))
    })
}

export const clearaddedCourse = () => dispatch => {
  dispatch(requestClearaddedCourseId());
}


export const addCurriculum = (sections, courseId) => dispatch => {
  dispatch(requestAddCurriculum());
  Axios.post(baseUrl + '/courses/addCurriculum', {
    sections,
    courseId
  })
    .then((response) => {
      dispatch(addCurriculumSuccess())
    })
    .catch((err) => {
      let message
      if (err.response) {
        message = err.response.data.message
      }
      dispatch(addCurriculumError(message || 'Something went wrong'))
    })
}

export const clearaddCurriculum = () => dispatch => {
  dispatch(requestClearaddCurriculum());
}

export const clearDeleteCourseDashboard = () => dispatch => {
  dispatch(clearDeleteCourse())
}

export const deleteCourseById = (id) => dispatch => {
  dispatch(requestDeleteCourse())
  Axios.post(baseUrl + '/courses/deletecourse', {
    id
  })
    .then((response) => {
      var { courses, totalCourses } = response.data;
      console.log(response)
      dispatch(loadingCourse());
      dispatch(recieveUploadedCourses({ courses, totalCourses }))
      dispatch(deleteCourseSuccess('Course deleted successfully!'))
      // dispatch(clearDeleteCourse())
    })
    .catch((error) => {
      var message
      if (error.response) {
        message = error.response.data.message;
      }
      dispatch(deleteCourseError( message || 'Something went wrong!'))
      // dispatch(clearDeleteCourse())
    })
}
 
export const triggerNewMessage = () => dispatch => {
  dispatch(triggerNewMessageHandler())
}

export const offtriggerNewMessage = () => dispatch => {
  dispatch(triggerNewMessageHandlerOff())
}