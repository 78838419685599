import React, { Component } from "react"
import { Link } from "react-router-dom"
import { GridRow, GridColumn, Grid, Container, Button, Icon } from "semantic-ui-react";
const Footer = ({ auth }) => {

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  const handleLogout = (e) => {
    e.preventDefault();
    window.localStorage.clear();
    window.location.assign('/')
  }
  return (
    <footer className="x-footer">
      <Container fluid>
        <Grid>
          <GridRow>
            <GridColumn computer="4" tablet="4" mobile="16">
              <Link to="/"><img src="/images/logo.png" alt="Logo" /></Link>
            </GridColumn>
            <GridColumn computer="3" tablet="3" mobile="16">
              <p>Community</p>
              {!auth.isAuthenticated && <p><Link to="/signin"> Signin</Link></p>}
              {!auth.isAuthenticated && <p><Link to="/signup">Create an Account</Link></p>}
              {auth.isAuthenticated && <p><Link to="/my_courses"> My courses</Link></p>}
              <p><a href="https://www.masterclassofbeauty.info/blog">Our Blog</a></p>
              <p><a href="https://www.masterclassofbeauty.info">Info Hub </a></p>
              {/* <p><Link to="/">Get Coupon Code</Link></p> */}
              {auth.isAuthenticated && <p><Link to="" onClick={handleLogout}> Logout </Link></p>}
            </GridColumn>
            <GridColumn computer="3" tablet="3" mobile="16">
              <p>Support</p>
              <p><Link to="/terms-and-conditions">Terms and Condition</Link></p>
              <p><Link to="/privacy-and-policy">Privacy & Policy</Link></p>
              <p><Link to="/contact-us">Contact us</Link></p>
              {/* <p><Link to="/">Get Help</Link></p> */}
            </GridColumn>

            <GridColumn computer="3" tablet="3" mobile="16">
              <p>Join Us</p>
              {/* <p><Link to="/">Become Teacher</Link></p> */}
              {!auth.isAuthenticated && <p><Link to="/signin">Become a student</Link></p>}
              {/* <p><Link to="/">Become Both</Link></p> */}
              {/* <p><Link to="/">Partnership</Link></p> */}
            </GridColumn>

            <GridColumn computer="3" tablet="3" mobile="16">
              <p>Download App</p>
              <div><Button fluid><Icon name="apple" /> Apple IOs </Button></div>
              <br />
              <div><Button fluid><Icon name="google play" /> Google Play</Button></div>
            </GridColumn>
          </GridRow>
        </Grid>
        <br />
        <div className="footnote">
          <div>
            Copyright © 2020
                  </div>
          <div className="x-goto" onClick={() => scrollToTop()}>
            Go to top <Icon name="arrow circle up" />
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer